import { memo } from 'react';
import { useHistory } from 'react-router-dom';

import LogoWithTextUrl from '~/assets/images/logo/logo-with-text.png';
import { ButtonCustom } from '~/components/form';
import { routesAuth } from '~/configs';
import { translate } from '~/helpers/utils';

export const ChangePasswordFailureScreen = memo(
  ({ isShowChangePasswordFailureScreen }) => {
    const history = useHistory();

    return (
      <div
        className={`change-password-failure-screen--wrapper${isShowChangePasswordFailureScreen ? ' show' : ''}`}
      >
        <div className="change-password-failure-screen--inner">
          <div className="change-password-failure-screen--header">
            <img src={LogoWithTextUrl} alt="Meko Trading" />
          </div>
          <div className="change-password-failure-screen--main mk-text-center">
            <h2 className="mk-fz-20 mk-fw-700 mk-text-uppercase mk-text-brand-blue">
              {translate('forgot-password-page.send-otp-failure.title')}
            </h2>
            <p
              className="mk-fz-16 mk-fw-500 mk-text-left"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: translate(
                  'forgot-password-page.send-otp-failure.description',
                ).replace(
                  '$x',
                  '<a href="tel:0988434937" class="mk-text-link mk-text-brand-blue mk-text-hover-secondary-brand-blue mk-fw-600">0988.434.937 - Lâm</a>',
                ),
              }}
            />
            <p className="mk-fz-16 mk-fw-500">
              {translate('forgot-password-page.send-otp-failure.sub-title')}
            </p>
            <ButtonCustom
              variant="line"
              type="button"
              className="w-100"
              onClick={() => history.push(routesAuth.login)}
            >
              {translate('button.create-account.title')}
            </ButtonCustom>
          </div>
          <div className="change-password-failure-screen--footer">
            <p className="mk-fz-12 mk-fw-600 mb-0 mk-text-uppercase">
              {translate('forgot-password-page.footer.name')}
            </p>
            <p className="mk-fz-12 mk-fw-400 mb-0">
              {translate('forgot-password-page.footer.hotline')}{' '}
              <a
                href="tel:0939010193"
                className="mk-text-link mk-text-hover-brand"
              >
                093 901 01 93
              </a>
            </p>
            <p className="mk-fz-12 mk-fw-400 mb-0">
              {translate('forgot-password-page.footer.address')}
            </p>
          </div>
        </div>
      </div>
    );
  },
);
