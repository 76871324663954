/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ContactButton,
  FooterInfo,
  HeaderChildWithoutCoin,
} from '~/components/common';
import { HelmetCustom } from '~/components/helmetCustom';
import { CSPlusCircleNavigational } from '~/components/iconography/Navigational';
import { routesUser } from '~/configs';
import { translate } from '~/helpers/utils';
import {
  resetCreateAddressState,
  resetDeleteAddressState,
  resetUpdateAddressState,
} from '~/redux/address/actions';
import { ScreenActionAddress } from './ScreenActionAddress';

export const UserAddressesManager = () => {
  const { configResponse } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  const addressesList = configResponse?.data?.profile?.addresses?.data;

  const [isShowScreenActionAddress, setIsShowScreenActionAddress] = useState(false);
  const [addressActive, setAddressActive] = useState();

  useEffect(
    () => () => {
      dispatch(resetCreateAddressState());
      dispatch(resetUpdateAddressState());
      dispatch(resetDeleteAddressState());
    },
    [],
  );

  return (
    <HelmetCustom title={translate('helmet.title.addresses-manager')}>
      <div
        id="addresses-manager-page"
        className="addresses-manager-page--wrapper h-screen pt-4 d-flex flex-column"
      >
        {isShowScreenActionAddress ? (
          <ScreenActionAddress
            isShow={isShowScreenActionAddress}
            data={addressActive}
            handleBack={() => {
              setIsShowScreenActionAddress(false);
              setAddressActive();
              dispatch(resetCreateAddressState());
              dispatch(resetUpdateAddressState());
              dispatch(resetDeleteAddressState());
            }}
          />
        ) : (
          <>
            <HeaderChildWithoutCoin
              title={translate('addresses-manager-page.title')}
            />
            <div className="addresses-manager-page--inner flex-grow-1 d-flex flex-column scroll-y">
              <div className="mb-2 flex-grow-1">
                <div className="addresses-list--wrapper">
                  {addressesList?.map((item, index) => (
                    <div
                      className="address-item--wrapper py-3 cursor-pointer"
                      key={index}
                      onClick={() => {
                        setIsShowScreenActionAddress(true);
                        setAddressActive(item);
                      }}
                    >
                      <p className="mb-0 mk-fz-14 mk-fw-400 mk-text-subtle-black">
                        <span className="mk-fw-500 mk-text-black">
                          {item.name}
                        </span>{' '}
                        | {item.phone}
                      </p>
                      <p className="mb-0 mk-fz-14 mk-fw-400 mk-text-subtle-black">
                        {item.address}
                      </p>
                      <p className="mb-0 mk-fz-14 mk-fw-400 mk-text-subtle-black">
                        {translate('input.address-custom-4.placeholder')}{' '}
                        {item.ward.name},{' '}
                        {translate('input.address-custom-3.placeholder')}{' '}
                        {item.district.name},{' '}
                        {translate('input.address-custom-2.placeholder')}{' '}
                        {item.province.name}.
                      </p>
                      {item.apply && (
                        <span className="status-apply px-1 mt-1 d-inline-block mk-fz-12 mk-fw-400 mk-text-brand-orange">
                          {translate('addresses-manager-page.apply.title')}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
                <p
                  className="m-auto w-fit py-3 mk-fz-14 mk-text-brand-orange mk-fw-400 cursor-pointer mk-text-capitalize mk-text-hover-orange hover-stroke-orange"
                  onClick={() => setIsShowScreenActionAddress(true)}
                >
                  <CSPlusCircleNavigational className="stroke-brand-orange" />{' '}
                  {translate('addresses-manager-page.btn-add-new-address')}
                </p>
              </div>
              <div className="mb-4" />
              <ContactButton />
              <div className="mb-4" />
              <FooterInfo />
            </div>
          </>
        )}
      </div>
    </HelmetCustom>
  );
};
