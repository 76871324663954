import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { HeaderChildWithoutCoin } from '~/components/common';
import {
  ButtonCustom,
  InputCheckbox,
  InputText,
  InputTextArea,
} from '~/components/form';
import { ToastError } from '~/helpers/funcs';
import { translate } from '~/helpers/utils';
import { createAddressRequest, deleteAddressRequest, updateAddressRequest } from '~/redux/address/actions';
import { updateAddressesConfig, updateAddressesProfile } from '~/redux/user/actions';
import { AddressCustom } from './AddressCustom';

export const ScreenActionAddress = ({ handleBack, data }) => {
  const { isGetRegionsRequest } = useSelector((store) => store.region);
  const {
    isCreateAddressRequest, isCreateAddressSuccess, isCreateAddressFailure, createAddressResponse,
    isUpdateAddressRequest,
    isUpdateAddressSuccess,
    isUpdateAddressFailure,
    updateAddressResponse,
    isDeleteAddressRequest,
    isDeleteAddressSuccess,
    isDeleteAddressFailure,
  } = useSelector((store) => store.address);
  const dispatch = useDispatch();

  const [addressCustom, setAddressCustom] = useState({
    province: '',
    district: '',
    ward: '',
  });
  const [errMsgAddressCustom, setErrMsgAddressCustom] = useState({
    province: {
      error: translate('input.province.error.required'),
      touch: false,
    },
    district: {
      error: translate('input.district.error.required'),
      touch: false,
    },
    ward: {
      error: translate('input.ward.error.required'),
      touch: false,
    },
  });
  const [isEditFirstRender, setIsEditFirstRender] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      phone: data?.phone || '',
      address: data?.address || '',
      apply: data?.apply || false,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required(translate('input.full-name.error.required')),
      phone: Yup.string()
        .trim()
        .required(translate('input.phone-number.error.required')),
      address: Yup.string()
        .trim()
        .required(translate('input.address.error.required')),
    }),
    onSubmit: (values) => {
      const payload = {
        name: values.name,
        phone: values.phone,
        address: values.address,
        province: parseInt(addressCustom.province),
        district: parseInt(addressCustom.district),
        ward: parseInt(addressCustom.ward),
        apply: values.apply,
      };
      if (data) {
        if (payload.apply === false) {
          delete payload.apply;
        }
        dispatch(updateAddressRequest({ id: data.id, data: payload }));
      } else {
        dispatch(createAddressRequest(payload));
      }
    },
  });

  useEffect(() => {
    if (data) {
      setAddressCustom({
        province: data.province.id,
        district: data.district.id,
        ward: data.ward.id,
      });
      setIsEditFirstRender(true);
    }
  }, [data]);

  useEffect(() => {
    if (isCreateAddressSuccess) {
      handleBack();
      dispatch(updateAddressesConfig({
        type: 'create',
        data: createAddressResponse.data,
      }));
      if (createAddressResponse.data.apply) {
        dispatch(updateAddressesProfile(createAddressResponse.data));
      }
    }
    if (isUpdateAddressSuccess) {
      handleBack();
      dispatch(updateAddressesConfig({
        type: 'update',
        data: updateAddressResponse.data,
      }));
      if (updateAddressResponse.data.apply) {
        dispatch(updateAddressesProfile(updateAddressResponse.data));
      }
    }
    if (isDeleteAddressSuccess) {
      dispatch(updateAddressesConfig({
        type: 'delete',
        id: data.id,
      }));
      handleBack();
    }
  }, [isCreateAddressSuccess, isUpdateAddressSuccess, isDeleteAddressSuccess]);

  const onDeleteAddress = () => {
    dispatch(deleteAddressRequest({ id: data.id }));
  };

  return (
    <>
      <div className="screen-action-address--wrapper">
        <HeaderChildWithoutCoin
          title={translate(data ? 'addresses-manager-page.title-2' : 'addresses-manager-page.title-1')}
          onBack={handleBack}
        />
        <div className="screen-action-address--inner flex-grow-1 d-flex flex-column scroll-y">
          {isGetRegionsRequest ? (
            <div>
              <Skeleton
                count={1}
                width="100px"
                height="18px"
                style={{ lineHeight: 'unset', marginTop: '5px' }}
              />
              <Skeleton
                count={1}
                width="100%"
                height="20px"
                style={{ lineHeight: 'unset', marginTop: '5px' }}
              />
              <Skeleton
                count={1}
                width="100%"
                height="20px"
                style={{ lineHeight: 'unset', marginTop: '5px' }}
              />
              <Skeleton
                count={1}
                width="100px"
                height="18px"
                style={{ lineHeight: 'unset', marginTop: '25px' }}
              />
              <Skeleton
                count={1}
                width="100%"
                height="50px"
                style={{ lineHeight: 'unset', marginTop: '5px' }}
              />
              <Skeleton
                count={1}
                width="100%"
                height="20px"
                style={{ lineHeight: 'unset', marginTop: '5px' }}
              />
              <div className="flex-center-center mt-4">
                <Skeleton
                  count={1}
                  width="80px"
                  height="20px"
                  style={{ lineHeight: 'unset' }}
                />
              </div>
            </div>
          ) : (
            <Form noValidate onSubmit={formik.handleSubmit}>
              <div className="information-wrapper">
                <Form.Label className="mk-fz-14 mk-fw-600">
                  {translate('addresses-manager-page.form-group-label-1')}{' '}
                  <span className="mk-text-red">*</span>
                  {((formik.touched.name && formik.errors.name) || (formik.touched.phone && formik.errors.phone)) && <div className="mk-text-red mk-fz-12 mk-fw-400">{formik.errors.name || formik.errors.phone}</div>}
                </Form.Label>
                <InputText
                  placeholder={translate('input.full-name.placeholder')}
                  id="name"
                  name="name"
                  isRequired
                  disabled={isCreateAddressRequest || isUpdateAddressRequest || isDeleteAddressRequest}
                  {...formik.getFieldProps('name')}
                />
                <InputText
                  placeholder={translate('input.phone-number.placeholder')}
                  id="phone"
                  name="phone"
                  isRequired
                  disabled={isCreateAddressRequest || isUpdateAddressRequest || isDeleteAddressRequest}
                  {...formik.getFieldProps('phone')}
                />
                <Form.Label className="mt-3 mk-fz-14 mk-fw-600">
                  {translate('addresses-manager-page.form-group-label-2')}{' '}
                  <span className="mk-text-red">*</span>
                  {((formik.touched.address && formik.errors.address) || (errMsgAddressCustom.province.touch && addressCustom.province === '') || (errMsgAddressCustom.district.touch && addressCustom.district === '') || (errMsgAddressCustom.ward.touch && addressCustom.ward === '')) && <div className="mk-text-red mk-fz-12 mk-fw-400">{formik.errors.address || (addressCustom.province === '' ? errMsgAddressCustom.province.error : addressCustom.district === '' ? errMsgAddressCustom.district.error : addressCustom.ward === '' ? errMsgAddressCustom.ward.error : '')}</div>}
                </Form.Label>
                <InputTextArea
                  placeholder={translate('input.address-custom-1.placeholder')}
                  id="address"
                  name="address"
                  isRequired
                  disabled={isCreateAddressRequest || isUpdateAddressRequest || isDeleteAddressRequest}
                  fieldLength={formik?.values?.address?.length || 0}
                  {...formik.getFieldProps('address')}
                />
                <AddressCustom
                  addressCustom={addressCustom}
                  setAddressCustom={setAddressCustom}
                  setErrMsgAddressCustom={setErrMsgAddressCustom}
                  isDisabled={isCreateAddressRequest || isUpdateAddressRequest || isDeleteAddressRequest}
                  isEditFirstRender={isEditFirstRender}
                  setIsEditFirstRender={setIsEditFirstRender}
                />
                <Form.Label className="mt-3 mk-fz-14 mk-fw-600">
                  {translate('addresses-manager-page.form-group-label-3')}
                </Form.Label>
                <InputCheckbox
                  labelFor="apply"
                  labelContent={translate('addresses-manager-page.apply.label')}
                  id="apply"
                  name="apply"
                  disabled={isCreateAddressRequest || data?.apply || isUpdateAddressRequest || isDeleteAddressRequest}
                  {...formik.getFieldProps('apply')}
                  defaultChecked={formik.values.apply}
                />
                <div className="flex-center-center gap-3">
                  <ButtonCustom
                    disabled={isGetRegionsRequest || addressCustom.district === '' || addressCustom.province === '' || addressCustom.ward === '' || isCreateAddressRequest || isUpdateAddressRequest || isDeleteAddressRequest}
                    isLoading={isCreateAddressRequest || isUpdateAddressRequest}
                    className="mt-4 mx-auto w-100"
                    style={{ width: '180px' }}
                  >
                    {translate(data ? 'addresses-manager-page.btn-update-address' : 'addresses-manager-page.btn-create-new-address')}
                  </ButtonCustom>
                  {
                    data?.apply === false && (
                      <ButtonCustom
                        disabled={isUpdateAddressRequest || isDeleteAddressRequest}
                        isLoading={isDeleteAddressRequest}
                        className="mt-4 mx-auto w-100"
                        color="red"
                        variant="line"
                        style={{ width: '180px' }}
                        onClick={onDeleteAddress}
                      >
                        {translate('addresses-manager-page.btn-delete-address')}
                      </ButtonCustom>
                    )
                  }
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>
      {isCreateAddressFailure && (
        <ToastError
          show
          content={translate('addresses-manager-page.create-address-failure')}
        />
      )}
      {isUpdateAddressFailure && (
        <ToastError
          show
          content={translate('addresses-manager-page.update-address-failure')}
        />
      )}
      {isDeleteAddressFailure && (
        <ToastError
          show
          content={translate('addresses-manager-page.delete-address-failure')}
        />
      )}
    </>
  );
};
