/* eslint-disable react/no-danger */
import moment from 'moment';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Banner, FooterInfo } from '~/components/common';
import { HeaderChildWithCoin } from '~/components/common/header/HeaderChildWithCoin';
import { HelmetCustom } from '~/components/helmetCustom';
import { routesUser } from '~/configs';
import { translate } from '~/helpers/utils';
import { BlogsList } from '../UserBlogsList/components';

export const UserBlogDetail = () => {
  const { isGetAllBlogsSuccess, getAllBlogsResponse } = useSelector(
    (store) => store.blog,
  );

  const history = useHistory();
  const { blogId } = useParams();

  const [isCheckBlogExist, setIsCheckBlogExist] = useState(false);
  const [blogData, setBlogData] = useState(null);

  useEffect(() => {
    if (isGetAllBlogsSuccess) {
      if (
        getAllBlogsResponse.data.findIndex(
          (item) => item.id === parseInt(blogId),
        ) === -1
      ) {
        history.push(routesUser.home);
      } else {
        setIsCheckBlogExist(true);
        setBlogData(
          getAllBlogsResponse.data.find((item) => item.id === parseInt(blogId)),
        );
      }
    }
  }, [isGetAllBlogsSuccess, blogId]);

  return (
    <HelmetCustom>
      <div
        id="blog-detail-page"
        className="blog-detail-page--wrapper h-screen pt-4 d-flex flex-column"
      >
        <HeaderChildWithCoin
          title={translate('blog-detail-page.title')}
        />
        <div className="blog-detail-page--inner flex-grow-1 d-flex flex-column scroll-y">
          <div className="flex-grow-1">
            {!isCheckBlogExist || blogData === null ? (
              <>
                <Skeleton
                  count={1}
                  width="100%"
                  style={{ lineHeight: 'unset', aspectRatio: '1/1' }}
                />
                <Skeleton
                  count={1}
                  width="100%"
                  height="20px"
                  style={{ lineHeight: 'unset', marginTop: '15px' }}
                />
                <Skeleton
                  count={1}
                  width="100%"
                  height="20px"
                  style={{ lineHeight: 'unset', marginTop: '15px' }}
                />
                <Skeleton
                  count={1}
                  width="100%"
                  height="20px"
                  style={{ lineHeight: 'unset', marginTop: '15px' }}
                />
                <Skeleton
                  count={1}
                  width="100%"
                  height="100px"
                  style={{ lineHeight: 'unset', marginTop: '15px' }}
                />
              </>
            ) : (
              <>
                <Banner src={blogData.image} alt={blogData.title} classNameInner="mb-4" />
                <div className="box-content mb-5">
                  <h1 className="post-name mk-text-brand-blue mk-fz-16 mk-fw-600">
                    {blogData.title}
                  </h1>
                  <h4 className="post-date mk-text-subtle-black mk-fz-12 mk-fw-500">
                    {translate('blog-detail-page.detail-title-1')} {moment(blogData.updated_at).format('DD/MM/YYYY')}
                  </h4>
                  <div
                    className="post-detail mk-fz-14 mk-fw-400"
                    dangerouslySetInnerHTML={{
                      __html: blogData.content || '',
                    }}
                  />
                </div>
                <BlogsList filter={[blogData.id]} />
              </>
            )}
          </div>
          <FooterInfo />
        </div>
      </div>
    </HelmetCustom>
  );
};
