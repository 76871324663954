const vnGiftDetailPageLangLib = {
  'gift-detail-page.title': 'Đổi quà tặng',
  'gift-detail-page.btn-unqualified': 'Bạn chưa đủ số trứng tích lũy',
  'gift-detail-page.btn-exchange': 'Đổi quà',
  'gift-detail-page.success-screen.title': 'Chúc mừng bạn đổi quà thành công',
  'gift-detail-page.success-screen.description': 'Quà tặng của bạn sẽ được gửi kèm trong đơn hàng gần nhất.',
  'gift-detail-page.success-screen.btn-again': 'Tiếp tục đổi quà',
  'gift-detail-page.success-screen.btn-cancel': 'Trang chủ ($x)',
  'gift-detail-page.exchange-error-msg': 'Đổi quà tặng không thành công! Vui lòng thử lại sau.',
};

export default vnGiftDetailPageLangLib;
