/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { HeaderChildWithoutCoin } from '~/components/common';
import { HelmetCustom } from '~/components/helmetCustom';
import { routesUser } from '~/configs';
import { STORAGE_KEY } from '~/constants';
import { swalWarning } from '~/helpers/funcs';
import { translate } from '~/helpers/utils';
import { resetAddProductToCartRequest } from '~/redux/modal/actions';
import {
  getProductsListPricesRequest,
  resetGetProductsListPricesState,
} from '~/redux/product/actions';
import { Footer, ProductsList } from './components';

export const UserProductType = () => {
  const {
    isGetConfigRequest, isGetConfigSuccess, configResponse, profileResponse,
  } = useSelector((store) => store.user);
  const {
    isGetProductsListPricesRequest,
    isGetProductsListPricesSuccess,
    productsListPricesResponse,
  } = useSelector((store) => store.product);
  const { isAddProductToCart } = useSelector((store) => store.modal);
  const dispatch = useDispatch();

  const { productTypeId } = useParams();
  const history = useHistory();

  const [cartState, setCartState] = useState(
    localStorage.getItem(STORAGE_KEY.CART_STATE_TMP)
      ? JSON.parse(localStorage.getItem(STORAGE_KEY.CART_STATE_TMP))
      : [],
  );
  const [productTypeActive, setProductTypeActive] = useState();
  const [productsListData, setProductsListData] = useState([]);
  const [productPricesList, setProductPricesList] = useState([]);
  const [total, setTotal] = useState(0);

  // Check product type & product is exist
  useEffect(() => {
    if (productTypeId && isGetConfigSuccess) {
      const productTypeCheck = configResponse.data.types.find(
        (item) => item.id === parseInt(productTypeId),
      );
      if (!productTypeCheck) {
        history.push(routesUser.home);
      } else {
        setProductsListData(
          configResponse.data.products
            .filter((item) => item.type_id === parseInt(productTypeId))
            .filter((item) => !item.name.includes('Mẫu')),
        );
        setProductTypeActive(productTypeCheck);
      }
    }
  }, [productTypeId, isGetConfigSuccess]);
  useEffect(() => {
    if (
      cartState.length > 0
      && configResponse?.data?.products?.length > 0
      && !isGetProductsListPricesSuccess
      && profileResponse?.data
    ) {
      const cartStateClone = [];
      cartState.map((item1) => {
        const checked = configResponse?.data?.products?.filter(
          (item2) => item2.id === parseInt(item1.id),
        );
        if (checked.length > 0) {
          cartStateClone.push({
            ...item1,
            quantityInBag: checked[0].bag,
          });
        }
      });
      if (cartStateClone.length > 0) {
        const payload = {
          ids: cartStateClone.map((item) => parseInt(item.id)).join(),
          numbers: cartStateClone
            .map(
              (item) => item.type === 'pack' ? parseInt(item.packNumber) * parseInt(item.quantityInBag) : parseInt(item.packNumber),
            )
            .join(),
        };
        dispatch(getProductsListPricesRequest(payload));
      }
    } else {
      setProductPricesList([]);
      setTotal(0);
    }
  }, [cartState, configResponse, profileResponse]);

  useEffect(() => {
    if (isGetProductsListPricesSuccess) {
      setProductPricesList(productsListPricesResponse?.data?.products || []);
      setTotal(productsListPricesResponse?.data?.total || 0);
    }
  }, [isGetProductsListPricesSuccess]);

  useEffect(() => {
    if (isAddProductToCart) {
      setCartState(
        localStorage.getItem(STORAGE_KEY.CART_STATE_TMP)
          ? JSON.parse(localStorage.getItem(STORAGE_KEY.CART_STATE_TMP))
          : null,
      );
      dispatch(resetAddProductToCartRequest());
    }
  }, [isAddProductToCart]);

  // Logic handle back
  useEffect(
    () => () => {
      if (
        localStorage.getItem(STORAGE_KEY.CART_STATE)
        !== localStorage.getItem(STORAGE_KEY.CART_STATE_TMP)
      ) {
        history.goBack();
        handleOnBack();
      } else {
        dispatch(resetGetProductsListPricesState());
      }
    },
    [],
  );

  const handlePreConfirm = () => { };
  const handleWhenClose = () => {
    const cartStateTmp = localStorage.getItem(STORAGE_KEY.CART_STATE)
      ? JSON.parse(localStorage.getItem(STORAGE_KEY.CART_STATE))
      : [];
    localStorage.setItem(
      STORAGE_KEY.CART_STATE_TMP,
      JSON.stringify(cartStateTmp),
    );
    history.push(routesUser.home);
  };

  const handleOnBack = () => {
    if (
      localStorage.getItem(STORAGE_KEY.CART_STATE)
      !== localStorage.getItem(STORAGE_KEY.CART_STATE_TMP)
    ) {
      swalWarning(
        translate('product-type-page.modal-confirm-back.title'),
        <object
          dangerouslySetInnerHTML={{
            __html: translate('product-type-page.modal-confirm-back.content'),
          }}
        />,
        translate('product-type-page.modal-confirm-back.btn-1'),
        translate('product-type-page.modal-confirm-back.btn-2'),
        handlePreConfirm,
        handleWhenClose,
      );
    } else {
      history.push(routesUser.home);
    }
  };

  return (
    <HelmetCustom title={productTypeActive?.name}>
      <div
        id="user-product-type-page"
        className="user-product-type-page--wrapper pt-4 h-screen"
      >
        <HeaderChildWithoutCoin
          title={translate('product-type-page.title')}
          parentSlug={routesUser.home}
          onBack={handleOnBack}
        />
        <div className="product-type--img mb-2">
          {isGetConfigRequest ? (
            <Skeleton
              count={1}
              width="100%"
              height="30vh"
              style={{ lineHeight: 'unset' }}
            />
          ) : (
            <img
              src={productTypeActive?.image}
              alt={productTypeActive?.name}
              draggable={false}
            />
          )}
        </div>
        <h3 className="mb-0 mk-fz-16 mk-text-uppercase mk-text-brand-blue mk-fw-600">
          {isGetConfigRequest ? (
            <Skeleton
              count={1}
              width="50%"
              height="26px"
              style={{ lineHeight: 'unset' }}
            />
          ) : (
            productTypeActive?.name
          )}
        </h3>
        <ProductsList
          isLoading={isGetConfigRequest}
          isLoadingPrice={isGetProductsListPricesRequest}
          productsListData={productsListData}
          productPricesList={productPricesList}
        />
        {
          profileResponse?.data && (
            <Footer
              isLoading={
                isGetConfigRequest
                || isGetProductsListPricesRequest
              }
              total={total}
            // setIsDirect={setIsDirect}
            />
          )
        }
      </div>
    </HelmetCustom>
  );
};
