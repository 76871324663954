import { useFormik } from 'formik';
import { memo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { ButtonCustom, InputPassword, InputText } from '~/components/form';
import { translate } from '~/helpers/utils';
import { updateUserRequest } from '~/redux/auth/actions';

export const ForgotPasswordSecondScreen = memo(
  ({ isLoadingCheckOtp, setIsLoadingCheckOtp }) => {
    const { checkUserExistsResponse, isUpdateUserRequest } = useSelector(
      (store) => store.auth,
    );
    const dispatch = useDispatch();

    const [isInvalidOtpMsg, setIsInvalidOtpMsg] = useState(false);

    const formik = useFormik({
      initialValues: {
        otp: '',
        newPassword: '',
        reNewPassword: '',
      },
      validationSchema: Yup.object({
        otp: Yup.string()
          .trim()
          .required(translate('input.otp.error.required')),
        newPassword: Yup.string()
          .trim()
          .required(translate('input.new-password.error.required'))
          .min(5, translate('input.password.error.min'))
          .max(20, translate('input.password.error.max')),
        reNewPassword: Yup.string()
          .required(translate('input.re-new-password.error.required'))
          .oneOf(
            [Yup.ref('newPassword')],
            translate('input.re-new-password.error.not-match'),
          ),
      }),
      onSubmit: () => {
        onOTPVerify();
      },
    });

    const onOTPVerify = () => {
      setIsInvalidOtpMsg(false);
      setIsLoadingCheckOtp(true);
      window.confirmationResult
        .confirm(formik.values.otp)
        .then(async () => {
          setIsLoadingCheckOtp(false);
          const payload = {
            type: 'forgot-password',
            data: {
              id: checkUserExistsResponse.data.id,
              password: formik.values.newPassword,
            },
          };
          dispatch(updateUserRequest(payload));
        })
        .catch((error) => {
          console.error('error: ', error);
          setIsLoadingCheckOtp(false);
          setIsInvalidOtpMsg(true);
        });
    };

    return (
      <Form noValidate onSubmit={formik.handleSubmit}>
        <div className="forgot-password-page--second-screen">
          <h3 className="mb-3 mk-fz-16 mk-fw-500">
            {translate('forgot-password-page.second-screen.title')}
          </h3>
          <InputText
            id="otp"
            name="otp"
            placeholder={translate('input.otp.placeholder')}
            isRequired
            {...formik.getFieldProps('otp')}
            isValid={formik.touched.otp && !formik.errors.otp}
            isInvalid={formik.touched.otp && formik.errors.otp}
            isError={(formik.touched.otp && formik.errors.otp) || isInvalidOtpMsg}
            msgError={isInvalidOtpMsg ? translate('input.otp.error.invalid') : formik.errors.otp}
            disabled={isLoadingCheckOtp || isUpdateUserRequest}
          />
          <div className="mb-3" />
          <InputPassword
            id="newPassword"
            name="newPassword"
            placeholder={translate('input.new-password.placeholder')}
            {...formik.getFieldProps('newPassword')}
            isValid={formik.touched.newPassword && !formik.errors.newPassword}
            isInvalid={formik.touched.newPassword && formik.errors.newPassword}
            isError={formik.touched.newPassword && formik.errors.newPassword}
            msgError={formik.errors.newPassword}
            disabled={isLoadingCheckOtp || isUpdateUserRequest}
          />
          <div className="mb-3" />
          <InputPassword
            id="reNewPassword"
            name="reNewPassword"
            placeholder={translate('input.re-new-password.placeholder')}
            isRequired
            {...formik.getFieldProps('reNewPassword')}
            isValid={
              formik.touched.reNewPassword && !formik.errors.reNewPassword
            }
            isInvalid={
              formik.touched.reNewPassword && formik.errors.reNewPassword
            }
            isError={
              formik.touched.reNewPassword && formik.errors.reNewPassword
            }
            msgError={formik.errors.reNewPassword}
            disabled={isLoadingCheckOtp || isUpdateUserRequest}
          />
          <div className="mb-5" />
          <ButtonCustom
            disabled={isLoadingCheckOtp || isUpdateUserRequest}
            isLoading={isLoadingCheckOtp || isUpdateUserRequest}
            type="submit"
            className="w-100"
          >
            {translate('button.update-new-password.title')}
          </ButtonCustom>
        </div>
      </Form>
    );
  },
);
