import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ButtonCustom } from '~/components/form';
import Logo from '~/assets/images/logo/logo-192x192.png';
// import Skeleton from 'react-loading-skeleton';
import { formattedPhoneNumber, translate } from '~/helpers/utils';
import { CSCallOutline } from '../iconography/Outline';

export const ContactButton = memo(({ children }) => {
  const { isGetProfileSuccess, profileResponse, configResponse } = useSelector((store) => store.user);
  const [staffData, setStaffData] = useState();
  // eslint-disable-next-line no-unused-vars
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('IS_LOGIN'));

  useEffect(() => {
    if (isGetProfileSuccess || isLoggedIn) {
      if (profileResponse?.data) {
        if (profileResponse?.data?.staff?.data) {
          setStaffData(profileResponse?.data?.staff?.data);
        } else {
          setStaffData(configResponse?.data?.staff);
        }
      } else {
        setStaffData(configResponse?.data?.staff);
      }
    }
  }, [isGetProfileSuccess, isLoggedIn, configResponse]);

  return (
    <section className="contact-button--wrapper">
      {children}

      <a href={`tel:${staffData?.phone}`}>
        <div className="contact-button--inner flex-center-center">
          <img src={staffData?.avatar || Logo} alt={staffData?.name || 'Meko'} />
          <div className="d-flex flex-column flex-grow-1 px-2">
            <h4 className="mb-0 mk-fz-16 mk-fw-500 mk-text-brand-blue">
              {staffData?.name || 'Hotline'}: {formattedPhoneNumber(staffData?.telephone, true) || formattedPhoneNumber(staffData?.phone, true) || '093 901 01 93'}
            </h4>
            <p className="mb-0 mk-fz-12 mk-fw-400 mk-text-subtle-black mk-fs-i">
              {translate('home-page.button-contact.sub-title')}
            </p>
            <p className="mb-0 mk-fz-12 mk-fw-400 mk-text-black">
              {translate('home-page.button-contact.description')}
            </p>
          </div>

          <ButtonCustom>
            <CSCallOutline className="fill-white" />
          </ButtonCustom>
        </div>
      </a>
    </section>
  );
});
