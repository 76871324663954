/* eslint-disable react/no-danger */
import { memo, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';

import EggCoinUrl from '~/assets/images/common/egg-coin.png';
import { ButtonCustom } from '~/components/form';
import { CSPlusNavigational } from '~/components/iconography/Navigational';
import { ToastError } from '~/helpers/funcs';
import { translate } from '~/helpers/utils';
import {
  exchangeMyGiftAndVoucherRequest,
  resetExchangeMyGiftAndVoucherState,
} from '~/redux/giftVoucher/actions';
import { getAllPointsRequest } from '~/redux/point/actions';
import { changePointRequest } from '~/redux/user/actions';

export const ModalExchangeGift = memo(({ handleClose }) => {
  const { profileResponse } = useSelector((store) => store.user);
  const {
    isGetAllGiftsRequest,
    isGetAllGiftsSuccess,
    getAllGiftsResponse,
    isExchangeMyGiftAndVoucherRequest,
    isExchangeMyGiftAndVoucherSuccess,
    isExchangeMyGiftAndVoucherFailure,
  } = useSelector((store) => store.giftVoucher);
  const dispatch = useDispatch();

  const [giftsList, setGiftsList] = useState([]);
  const [giftSelected, setGiftSelected] = useState();

  useEffect(() => {
    if (isGetAllGiftsSuccess) {
      let giftsListTmp = [];
      if (getAllGiftsResponse?.data.length > 0) {
        giftsListTmp = [...getAllGiftsResponse.data];
      }
      setGiftsList(giftsListTmp);
    }
  }, [isGetAllGiftsSuccess, getAllGiftsResponse]);

  const handleExchangeGift = () => {
    const payload = {
      type: 'gift',
      gift_id: giftSelected.id,
    };
    dispatch(exchangeMyGiftAndVoucherRequest(payload));
  };

  useEffect(() => {
    if (isExchangeMyGiftAndVoucherSuccess) {
      dispatch(changePointRequest({ point: giftSelected.score }));
      dispatch(getAllPointsRequest({ limit: 0 }));
      dispatch(resetExchangeMyGiftAndVoucherState());
      handleClose();
    }
  }, [isExchangeMyGiftAndVoucherSuccess]);

  return (
    <>
      <Modal
        show
        onHide={handleClose}
        className="modal-cart-page modal-exchange-voucher--wrapper"
      >
        <Modal.Body>
          <div className="modal-exchange-voucher--inner">
            <div className="modal-exchange-voucher--header flex-center-space">
              <p className="mb-0 mk-fz-16 mk-text-secondary-brand-blue mk-text-uppercase mk-fw-500">
                {translate('cart-page.modal.exchange-gift.title')}
              </p>
              <span className="flex-center-end mk-fz-16 mk-fw-500 mk-text-brand-orange">
                <img
                  src={EggCoinUrl}
                  alt="egg-coin"
                  style={{ width: '19px', height: '19px', marginRight: '3px' }}
                />{' '}
                {profileResponse?.data.point}
              </span>
            </div>
            <div className="modal-exchange-voucher--main mt-3">
              <div className="vouchers-list--wrapper d-flex flex-wrap">
                {isGetAllGiftsRequest
                  ? [...new Array(4)].map((_, index) => (
                    <div
                      key={index}
                      className="voucher-item--wrapper w-50 mb-4"
                    >
                      <Skeleton
                        count={1}
                        width="100%"
                        style={{ lineHeight: 'unset', aspectRatio: '1/1' }}
                      />
                      <Skeleton
                        count={1}
                        width="100%"
                        height="20px"
                        style={{ lineHeight: 'unset', marginTop: '8px' }}
                      />
                    </div>
                  ))
                  : giftsList.map((item, index) => (
                    <div key={index} className="voucher-item--wrapper w-50">
                      <div className="voucher-item--inner">
                        <div className="voucher-item--img mb-2">
                          <img
                            src={item.image}
                            alt={item.title}
                            className="img-square"
                          />
                        </div>
                        <p className="voucher-item--name mk-fz-14 mk-fw-500 mb-0 mk-truncate-2">
                          {item.title}
                        </p>
                        <div className="flex-center-space mt-1 mb-4">
                          <p className="flex-center-start mk-fz-14 mk-fw-500 mk-text-brand-orange mk-fs-i mb-0">
                            {item.score}{' '}
                            <img
                              src={EggCoinUrl}
                              alt="egg-coin"
                              style={{
                                width: '15px',
                                height: '15px',
                                marginLeft: '3px',
                              }}
                            />
                          </p>
                          {profileResponse?.data.point >= item.score && giftSelected?.id !== item.id && (
                            <ButtonCustom
                              disabled={isExchangeMyGiftAndVoucherRequest}
                              color="brand-orange"
                              variant="line"
                              style={{
                                width: '20px',
                                height: '20px',
                                padding: 0,
                              }}
                              onClick={() => setGiftSelected(item)}
                            >
                              <CSPlusNavigational
                                className="stroke-brand-orange"
                                style={{ width: '13px', height: '13px' }}
                              />
                            </ButtonCustom>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="modal-exchange-voucher--control mt-4 mb-2">
              <ButtonCustom
                disabled={!giftSelected || isExchangeMyGiftAndVoucherRequest}
                isLoading={isExchangeMyGiftAndVoucherRequest}
                className="w-100"
                color="brand-orange"
                onClick={handleExchangeGift}
              >
                <span
                  className="flex-center-center gap-1"
                  dangerouslySetInnerHTML={{
                    __html: translate(
                      'cart-page.modal.exchange-voucher.btn',
                    ).replace(
                      '$x',
                      `<span class="flex-center-center">
                    ${giftSelected?.score || 0}
                    <img
                      src="${EggCoinUrl}"
                      alt="egg-coin"
                      style="width: 23px; height: 23px"
                    />
                  </span>`,
                    ),
                  }}
                />
              </ButtonCustom>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {isExchangeMyGiftAndVoucherFailure && (
        <ToastError
          show
          content={translate('gift-detail-page.exchange-error-msg')}
        />
      )}
    </>
  );
});
