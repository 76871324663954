const vnOrderDetailPageLangLib = {
  'order-detail-page.title': 'Chi tiết đơn hàng',
  'order-detail-page.btn.reorder-order': 'Đặt lại',
  'order-detail-page.btn.cancel-order': 'Hủy đơn',
  'order-detail-page.extra.voucher.title': 'Voucher',
  'order-detail-page.extra.address.title': 'Địa chỉ',
  'order-detail-page.extra.gift.title': 'Quà tặng',
  'order-detail-page.extra.coin.title': 'Trứng vàng tích lũy',
  'order-detail-page.extra.ship.title': 'Phí ship',
  'order-detail-page.extra.sale.title': 'Ưu đãi',
  'order-detail-page.extra.note.title': 'Ghi chú',
  'order-detail-page.extra.none.title': 'Không',
  'order-detail-page.header.created.title': 'Ngày tạo đơn:',
  'order-detail-page.header.order-status.pending.title': 'Chờ xử lý',
  'order-detail-page.header.order-status.waiting.title': 'Chờ lấy hàng',
  'order-detail-page.header.order-status.delivery.title': 'Đang giao hàng',
  'order-detail-page.header.order-status.delivered.title': 'Đã lấy hàng',
  'order-detail-page.header.order-status.canceled.title': 'Đã hủy',
  'order-detail-page.model.reorder-order.title1': 'Đặt lại đơn hàng',
  'order-detail-page.model.reorder-order.title2': 'Đặt hàng thành công',
  'order-detail-page.model.reorder-order.description1': 'Đơn hàng của bạn đã được khởi tạo thành công.',
  'order-detail-page.model.reorder-order.description2': 'Mã đơn hàng: $x',
  'order-detail-page.model.reorder-order.description3': 'Ngày tạo: $x',
  'order-detail-page.model.reorder-order.description4': 'Tổng tiền: $x',
  'order-detail-page.model.reorder-order.btn-accept': 'Đặt ngay',
  'order-detail-page.model.reorder-order.btn-cancel': 'Hủy bỏ',
  'order-detail-page.model.reorder-order.btn-home': 'Trang chủ ($xs)',
  'order-detail-page.model.reorder-order.btn-orders-list': 'Đơn hàng',
  'order-detail-page.model.reorder-order.total': 'Tổng tiền:',
  'order-detail-page.model.cancel-order.title1': 'Xác nhận hủy đơn',
  'order-detail-page.model.cancel-order.title2': 'Hủy đơn thành công',
  'order-detail-page.model.cancel-order.description1': 'Bạn xác nhận hủy đơn hàng: $x, đặt ngày $y.',
  'order-detail-page.model.cancel-order.description2': 'Đơn hàng gồm có:',
  'order-detail-page.model.cancel-order.description3': 'Trứng tích lũy của bạn sẽ bị trừ $x sau khi bạn xác nhận hủy đơn hàng.',
  'order-detail-page.model.cancel-order.description3-unit': 'điểm',
  'order-detail-page.model.cancel-order.description4': 'Đơn hàng của bạn đã được hủy.',
  'order-detail-page.model.cancel-order.description5': 'Số Trứng vàng tích lũy của bạn đã bị trừ $x.',
  'order-detail-page.model.cancel-order.description5-unit': 'trứng',
  'order-detail-page.model.cancel-order.btn-confirm': 'Xác nhận',
  'order-detail-page.model.cancel-order.btn-cancel': 'Hủy bỏ',
  'order-detail-page.model.cancel-order.btn-home': 'Trang chủ ($xs)',
  'order-detail-page.model.cancel-order.error-msg': 'Hủy đơn hàng không thành công! Vui lòng thử lại sau.',
  'order-detail-page.model.cancel-order.label-reason': 'Lý do:',
  'order-detail-page.model.cancel-order.label-reason-error-msg': 'Vui lòng nhập lý do huỷ đơn!',
};

export default vnOrderDetailPageLangLib;
