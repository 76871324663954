import { memo } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

export const InputSelect = memo(
  ({
    labelFor,
    labelContent,
    isRequired,
    isError,
    msgError,
    children,
    disabled,
    ...rest
  }) => (
    <div className={`input-custom input-custom-select${disabled ? ' disabled' : ''}`}>
      {labelContent && (
        <Form.Label htmlFor={labelFor}>
          {labelContent} {isRequired && <span className="is-required">*</span>}
        </Form.Label>
      )}
      <InputGroup>
        <Form.Select disabled={disabled} {...rest}>{children}</Form.Select>
      </InputGroup>
      {isError && <div className="form-error-msg">{msgError}</div>}
    </div>
  ),
);
