/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getProductsList({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/products', {
      params: payload,
    }));
    yield put(Actions.getProductsListSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getProductsListFailure(messages));
    }
  }
}

function* getProductTypesList({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/products/types', {
      params: payload,
    }));
    yield put(Actions.getProductTypesListSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getProductTypesListFailure(messages));
    }
  }
}

function* getProductPrices({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/products/prices', {
      params: payload,
    }));
    yield put(Actions.getProductPricesSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getProductPricesFailure(messages));
    }
  }
}

function* getProductsListPrices({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/products/prices', {
      params: payload,
    }));
    yield put(Actions.getProductsListPricesSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getProductsListPricesFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getProductsListRequest, getProductsList);
  yield takeLatest(Actions.getProductTypesListRequest, getProductTypesList);
  yield takeLatest(Actions.getProductPricesRequest, getProductPrices);
  yield takeLatest(Actions.getProductsListPricesRequest, getProductsListPrices);
}
