const vnModalAddProductLangLib = {
  'modal-add-product.note1': 'Lưu ý: đơn từ 1000 trở lên mới được chọn quy cách. Nếu dưới 1000 chỉ áp dụng quy cách mặc định.',
  // 'modal-add-product.note2': 'Quy cách mặc định: 5g đến 15g quy cách 100.',
  'modal-add-product.note2': 'Quy cách mặc định: 5g quy cách 100, 6g đến 15g quy cách 50.',
  'modal-add-product.note3': 'Quy cách mặc định: 1kg cho các loại bột.',
  'modal-add-product.title': 'Thông tin sản phẩm',
  'modal-add-product.total.title': 'Thành tiền:',
  'modal-add-product.unit': 'bịch',
  'modal-add-product.sub-title.1': 'Số bịch:',
  'modal-add-product.sub-title.2': 'Số lượng:',
  'modal-add-product.title-1': 'Bạn đang dự định mua với số lượng này?',
  'modal-add-product.title-2': 'Số trứng bạn muốn lựa chọn là:',
  'modal-add-product.input-type.pack': 'Bịch',
  'modal-add-product.input-type.quantity-1': 'Trứng',
  'modal-add-product.input-type.quantity-2': 'Kg',
  'modal-add-product.description-1': 'Vui lòng nhập số lượng',
  'modal-add-product.description-2': 'chia hết cho',
};

export default vnModalAddProductLangLib;
