import { combineReducers } from 'redux';

import address from './address/reducer';
import auth from './auth/reducer';
import blog from './blog/reducer';
import giftVoucher from './giftVoucher/reducer';
import modal from './modal/reducer';
import notification from './notification/reducer';
import order from './order/reducer';
import point from './point/reducer';
import product from './product/reducer';
import region from './region/reducer';
import shipping from './shipping/reducer';
import user from './user/reducer';

const rootReducer = combineReducers({
  address,
  auth,
  blog,
  giftVoucher,
  modal,
  notification,
  order,
  point,
  product,
  region,
  shipping,
  user,
});

export default rootReducer;
