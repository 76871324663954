import { memo } from 'react';
import { useHistory } from 'react-router-dom';

import { CSArrowLeftNavigational } from '~/components/iconography/Navigational';
import { translate } from '~/helpers/utils';

export const HeaderChildWithoutCoin = memo(({ title, parentSlug, onBack }) => {
  const history = useHistory();

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else if (parentSlug) {
      history.push(parentSlug);
    } else {
      history.goBack();
    }
  };

  return (
    <header
      id="header-child-without-coin"
      className="header-child-without-coin--wrapper"
    >
      <div className="header-child-without-coin--inner flex-center-space mb-3">
        <span
          onClick={handleBack}
          className="mk-fz-14 mk-fw-400 mk-text-subtle-black mk-text-link flex-center-center gap-2 hover-stroke-brand-blue mk-text-hover-brand-blue"
        >
          <CSArrowLeftNavigational
            className="mk-fz-12 stroke-subtle-black"
            style={{ width: '18px', height: '18px' }}
          />
          {translate('button.back.title')}
        </span>
        <h3 className="mb-0 mk-fz-16 mk-fw-500">{title}</h3>
      </div>
    </header>
  );
});
