import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import LogoWithTextUrl from '~/assets/images/logo/logo-with-text.png';
import { CSArrowLeftNavigational } from '~/components/iconography/Navigational';
import { routesAuth } from '~/configs';
import { ToastError } from '~/helpers/funcs';
import { translate } from '~/helpers/utils';
import { resetAuthState } from '~/redux/auth/actions';
import { HelmetCustom } from '~/components/helmetCustom';
import { ChangePasswordFailureScreen } from './ChangePasswordFailureScreen';
import { ChangePasswordSuccessScreen } from './ChangePasswordSuccessScreen';
import { ForgotPasswordFirstScreen } from './ForgotPasswordFirstScreen';
import { ForgotPasswordSecondScreen } from './ForgotPasswordSecondScreen';

export const ForgotPasswordPage = () => {
  const {
    isCheckUserExistsRequest,
    isCheckUserExistsSuccess,
    checkUserExistsResponse,
    isUpdateUserRequest,
    isUpdateUserSuccess,
    isUpdateUserFailure,
  } = useSelector((store) => store.auth);
  const dispatch = useDispatch();

  const history = useHistory();

  const [isLoadingSendOtp, setIsLoadingSendOtp] = useState(false);
  const [isLoadingCheckOtp, setIsLoadingCheckOtp] = useState(false);
  const [isShowFirstScreen, setIsShowFirstScreen] = useState(true);
  const [
    isShowChangePasswordSuccessScreen,
    setIsShowChangePasswordSuccessScreen,
  ] = useState(false);
  const [
    isShowChangePasswordFailureScreen,
    setIsShowChangePasswordFailureScreen,
  ] = useState(false);

  const handleOnClickBack = () => {
    if (isShowChangePasswordFailureScreen) {
      dispatch(resetAuthState());
      setIsShowChangePasswordFailureScreen(false);
      return;
    }
    if (!isShowFirstScreen) {
      setIsShowFirstScreen(true);
    } else {
      history.push(routesAuth.login);
    }
  };

  useEffect(
    () => () => {
      dispatch(resetAuthState());
    },
    [],
  );

  useEffect(() => {
    if (isCheckUserExistsSuccess && checkUserExistsResponse.data.id === null) {
      setIsShowChangePasswordFailureScreen(true);
    }
  }, [isCheckUserExistsSuccess, checkUserExistsResponse]);

  useEffect(() => {
    if (isUpdateUserSuccess) {
      setIsShowChangePasswordSuccessScreen(true);
    }
  }, [isUpdateUserSuccess]);

  return (
    <HelmetCustom title={translate('helmet.title.forgot-password')}>
      <div id="forgot-password-page" className="forgot-password-page--wrapper">
        <ChangePasswordSuccessScreen
          isShowChangePasswordSuccessScreen={isShowChangePasswordSuccessScreen}
          checkUserExistsResponse={checkUserExistsResponse}
        />
        <ChangePasswordFailureScreen
          isShowChangePasswordFailureScreen={isShowChangePasswordFailureScreen}
        />
        <div
          className={`forgot-password-page--inner ${isShowFirstScreen ? 'show-first-screen' : 'show-second-screen'}${isShowChangePasswordSuccessScreen ? ' hidden' : ''}`}
        >
          <div className="forgot-password-page--header">
            <img src={LogoWithTextUrl} alt="Meko Trading" />
          </div>
          <div className="forgot-password-page--form">
            <div className="forgot-password-page--form__inner">
              <ForgotPasswordFirstScreen
                setIsShowFirstScreen={setIsShowFirstScreen}
                isLoadingSendOtp={isLoadingSendOtp}
                setIsLoadingSendOtp={setIsLoadingSendOtp}
              />
              <ForgotPasswordSecondScreen
                isLoadingCheckOtp={isLoadingCheckOtp}
                setIsLoadingCheckOtp={setIsLoadingCheckOtp}
              />
            </div>
          </div>
          <div className="forgot-password-page--footer">
            <p className="mk-fz-14 mk-fw-600 mb-0 mk-text-uppercase">
              {translate('forgot-password-page.footer.name')}
            </p>
            <p className="mk-fz-12 mk-fw-400 mb-0">
              {translate('forgot-password-page.footer.hotline')}{' '}
              <a
                href={
                  isCheckUserExistsRequest
                    || isLoadingSendOtp
                    || isUpdateUserRequest
                    || isLoadingCheckOtp
                    ? '#'
                    : 'tel:0939010193'
                }
                className="mk-text-link mk-text-hover-secondary-brand-blue"
              >
                093 901 01 93
              </a>
            </p>
            <p className="mk-fz-12 mk-fw-400 mb-0">
              {translate('forgot-password-page.footer.address')}
            </p>
          </div>
          {!isShowChangePasswordSuccessScreen && (
            <span
              onClick={
                isCheckUserExistsRequest
                  || isLoadingSendOtp
                  || isUpdateUserRequest
                  || isLoadingCheckOtp
                  ? null
                  : handleOnClickBack
              }
              className="back-link mk-fz-14 mk-fw-400 mk-text-subtle-black mk-text-link flex-center-center gap-2 hover-stroke-secondary-brand-blue mk-text-hover-secondary-brand-blue show"
            >
              <CSArrowLeftNavigational
                className="mk-fz-12 stroke-subtle-black"
                style={{ width: '18px', height: '18px' }}
              />
              {translate('button.back.title')}
            </span>
          )}
        </div>
      </div>
      {isUpdateUserFailure && (
        <ToastError
          show
          content={translate(
            'forgot-password-page.change-password-failure.title',
          )}
        />
      )}
    </HelmetCustom>
  );
};
