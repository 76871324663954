import { memo } from 'react';
import { Link } from 'react-router-dom';

import EggCoinUrl from '~/assets/images/common/egg-coin.png';
import { routesUser } from '~/configs';
import { parseCurrency, translate } from '~/helpers/utils';

export const OrderItem = memo(({ type, data }) => (
  <div className="order-item">
    <Link
      to={routesUser.orderDetail.replace(':orderId', data.id)}
      className="cursor-pointer mk-text-hover-secondary-brand-blue"
    >
      <div className="order-item--inner flex-stretch-center">
        <div className="order-item--img">
          <img
            src={data?.imageCustom}
            alt={`order-${data?.id || ''}`}
          />
        </div>
        <div className="flex-start-center flex-column flex-grow-1 px-4">
          <p className="mb-0 mk-fz-12 mk-fw-500 mk-text-subtle-black">
            {translate('orders-list-page.order-item.code')}
          </p>
          <p
            className={`mk-truncate-1 mb-0 mk-fz-14 mk-fw-600 ${type === 'current'
              ? 'mk-text-brand-blue'
              : type === 'success'
                ? 'mk-text-subtle-blue'
                : 'mk-text-red'}`}
            style={{ wordBreak: 'break-all' }}
          >
            {data?.code || data?.detail?.code || '---'}
          </p>
          <p className="mb-0 mk-fz-14 mk-fw-600">
            {parseCurrency(data?.detail?.total || 0)}
          </p>
        </div>
        {type === 'success' ? (
          <span className="btn-coin flex-center-start mk-fz-14 mk-fw-400 mk-text-brand-orange h-fit">
            +{data?.point || 0}
            <img
              src={EggCoinUrl}
              alt="egg-coin"
              style={{ width: '16px', height: '16px' }}
            />{' '}
          </span>
        ) : null}
      </div>
    </Link>
  </div>
));
