import { v4 as uuidv4 } from 'uuid';

import { routesAuth } from '~/configs';
import {
  ForgotPasswordPage, LoginPage, RegisterPage, RequiredChangePasswordPage,
} from '~/pages/Auth';

export const routerAuth = [
  {
    id: `auth-${uuidv4()}`,
    path: routesAuth.forgotPassword,
    component: ForgotPasswordPage,
  },
  {
    id: `auth-${uuidv4()}`,
    path: routesAuth.login,
    component: LoginPage,
  },
  {
    id: `auth-${uuidv4()}`,
    path: routesAuth.register,
    component: RegisterPage,
  },
  {
    id: `auth-${uuidv4()}`,
    path: routesAuth.requiredChangePassword,
    component: RequiredChangePasswordPage,
  },
];
