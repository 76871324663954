// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllGiftsRequest = createAction('GET_ALL_GIFTS_REQUEST');
export const getAllGiftsSuccess = createAction('GET_ALL_GIFTS_SUCCESS');
export const getAllGiftsFailure = createAction('GET_ALL_GIFTS_FAILURE');

export const getAllVouchersRequest = createAction('GET_ALL_VOUCHERS_REQUEST');
export const getAllVouchersSuccess = createAction('GET_ALL_VOUCHERS_SUCCESS');
export const getAllVouchersFailure = createAction('GET_ALL_VOUCHERS_FAILURE');

export const getAllMyGiftsAndVouchersRequest = createAction('GET_ALL_MY_GIFTS_AND_VOUCHERS_REQUEST');
export const getAllMyGiftsAndVouchersSuccess = createAction('GET_ALL_MY_GIFTS_AND_VOUCHERS_SUCCESS');
export const getAllMyGiftsAndVouchersFailure = createAction('GET_ALL_MY_GIFTS_AND_VOUCHERS_FAILURE');

export const updateListMyGiftsAndVouchers = createAction('UPDATE_LIST_MY_GIFTS_AND_VOUCHERS');

export const exchangeMyGiftAndVoucherRequest = createAction('EXCHANGE_MY_GIFT_AND_VOUCHER_REQUEST');
export const exchangeMyGiftAndVoucherSuccess = createAction('EXCHANGE_MY_GIFT_AND_VOUCHER_SUCCESS');
export const exchangeMyGiftAndVoucherFailure = createAction('EXCHANGE_MY_GIFT_AND_VOUCHER_FAILURE');
export const resetExchangeMyGiftAndVoucherState = createAction('RESET_EXCHANGE_MY_GIFT_AND_VOUCHER_STATE');

export const resetGiftVoucherState = createAction('RESET_GIFT_VOUCHER_STATE');
