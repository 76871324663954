// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  isGetAllPointsRequest: false,
  isGetAllPointsSuccess: false,
  isGetAllPointsFailure: false,
  getAllPointsResponse: {},
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Points
    [Actions.getAllPointsRequest]: (state) => ({
      ...state,
      isGetAllPointsRequest: true,
      isGetAllPointsSuccess: false,
      isGetAllPointsFailure: false,
    }),
    [Actions.getAllPointsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllPointsRequest: false,
      isGetAllPointsSuccess: true,
      isGetAllPointsFailure: false,
      getAllPointsResponse: payload,
    }),
    [Actions.getAllPointsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllPointsRequest: false,
      isGetAllPointsSuccess: false,
      isGetAllPointsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Local
    [Actions.resetPointState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
