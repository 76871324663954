/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Routing } from './components/routing';
import { routesUser } from './configs';
import { getFCMToken, updateFCMToken } from './helpers/utils';

const App = () => {
  const { isGetProfileSuccess } = useSelector(
    (store) => store.user,
  );

  const { pathname } = useLocation();

  useEffect(() => {
    const isApp = 'Android' in window || 'webkit' in window || true;
    if (isApp) {
      window._app = {
        updateFCMToken: (token) => {
          // if (profileResponse?.data) {
          updateFCMToken(token);
          // }
        },
      };
    }
  }, []);

  useEffect(() => {
    if (isGetProfileSuccess) {
      getFCMToken();
    }
  }, [isGetProfileSuccess]);

  return (
    <div className="App">
      <Container id="mobile-wrapper" className={`${pathname === routesUser.notifications ? 'p-0' : 0}`}>
        <Routing />
      </Container>
    </div>
  );
};

export default App;
