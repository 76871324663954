/* eslint-disable jsx-a11y/media-has-caption */
import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import BannerHomepageUrl from '~/assets/images/banner/banner-2.avif';
import { Banner, Pyro } from '~/components/common';
import { ButtonCustom } from '~/components/form';
import { routesUser } from '~/configs';
import { translate } from '~/helpers/utils';

export const GiftExchangeSuccessScreen = memo(
  ({ isExchangeGiftSuccess, giftData }) => {
    const history = useHistory();

    const [seconds, setSeconds] = useState(5);

    useEffect(() => {
      let interval;
      if (isExchangeGiftSuccess) {
        interval = setInterval(() => {
          const newSeconds = seconds - 1;
          if (newSeconds >= 0) {
            setSeconds((currentSeconds) => currentSeconds - 1);
          } else {
            clearInterval(interval);
            history.push(routesUser.home);
          }
        }, 1000);
      }

      return () => clearInterval(interval);
    }, [isExchangeGiftSuccess, seconds]);

    return (
      <div
        className={`gift-exchange-success-screen--wrapper pt-4${isExchangeGiftSuccess ? ' show' : ''}`}
      >
        <div className="box-content">
          <div className="gift-item--img">
            <img src={giftData?.image} alt="" />
          </div>
          <div className="gift-item--content">
            <h4 className="mb-0 mk-text-uppercase mk-text-brand-orange mk-fz-16 mk-fw-500">
              {translate('gift-detail-page.success-screen.title')}
            </h4>
            <p className="mb-0 mk-fz-14 mk-fw-600 mb-1">{giftData?.title}</p>
            <p className="mb-0 mk-fz-14 mk-fw-400 mb-3">
              {translate('gift-detail-page.success-screen.description')}
            </p>
            <div className="flex-center-center gap-3">
              <ButtonCustom
                size="s"
                color="brand-orange"
                className="w-100"
                onClick={() => history.push(routesUser.giftsList)}
              >
                {translate('gift-detail-page.success-screen.btn-again')}
              </ButtonCustom>
              <ButtonCustom
                size="s"
                color="secondary-brand-blue"
                className="w-100"
                onClick={() => history.push(routesUser.home)}
              >
                {translate(
                  'gift-detail-page.success-screen.btn-cancel',
                ).replace('$x', seconds)}
              </ButtonCustom>
            </div>
          </div>
        </div>
        <div className="mt-4" />
        <Banner
          src={BannerHomepageUrl}
          alt="Meko-Banner-Main"
          classNameInner="mb-5"
        />
        <Pyro isShow={isExchangeGiftSuccess} isHide={seconds < 4} />
      </div>
    );
  },
);
