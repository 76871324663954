const vnHomePageLangLib = {
  'home-page.trending-products.title': 'Sản phẩm mua nhiều',
  'home-page.categories-list.title': 'Dòng sản phẩm',
  'home-page.referral.title': 'Mã giới thiệu',
  'home-page.copy-referral-success.title': 'Copy mã giới thiệu thành công!',
  'home-page.post.title': 'Bài viết',
  'home-page.voucher.title': 'Voucher',
  'home-page.gift.title': 'Quà tặng',
  'home-page.recent-orders.title': 'Đơn hàng gần đây',
  'home-page.button-contact.title': 'Chào quý khách, nếu có khó khắn vui lòng liên hệ Meko để được hỗ trợ:',
  'home-page.button-contact.sub-title': 'Chuyên viên chăm sóc khách hàng',
  'home-page.button-contact.description': 'Hỗ trợ chăm sóc tài khoản quý khách',
};

export default vnHomePageLangLib;
