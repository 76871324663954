const vnCartPageLangLib = {
  'cart-page.title': 'Giỏ hàng',
  'cart-page.total': 'Tổng tiền',
  'cart-page.header.created.title': 'Ngày tạo đơn',
  'cart-page.header.order-status.delivering.title': 'Đang tạo đơn',
  'cart-page.first-screen.btn-1': 'Tiếp tục mua hàng',
  'cart-page.first-screen.btn-2': 'Đặt hàng',
  'cart-page.second-screen.btn-1': 'Đặt hàng',
  'cart-page.second-screen.btn-2': 'Hủy bỏ',
  'cart-page.modal.exchange-voucher.title': 'Đổi voucher',
  'cart-page.modal.exchange-voucher.btn': 'Đổi $x',
  'cart-page.modal.exchange-gift.title': 'Đổi quà tặng',
  'cart-page.modal.create-order-failure.title': 'Đặt hàng không thành công! Vui lòng thử lại sau.',
  'cart-page.modal.no-data.title': 'Chưa có sản phẩm trong giỏ hàng',
  'cart-page.require-number-product-title': 'Đối với đơn hàng chỉ có lòng đỏ, yêu cầu tối thiểu từ 100 trứng!',
};

export default vnCartPageLangLib;
