export const STORAGE_KEY = {
  CURRENT_LANGUAGE: 'CURRENT_LANGUAGE',
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  EXPIRES_IN: 'EXPIRES_IN',
  IS_LOGIN: 'IS_LOGIN',
  CART_STATE: 'CART_STATE',
  CART_STATE_TMP: 'CART_STATE_TMP',
  ID_LOGIN: 'ID_LOGIN',
};
