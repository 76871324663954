import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import EggCoinUrl from '~/assets/images/common/egg-coin.png';
import { CSArrowLeftNavigational } from '~/components/iconography/Navigational';
import { routesUser } from '~/configs';
import { translate } from '~/helpers/utils';

export const HeaderChildWithCoin = memo(({ title, parentSlug }) => {
  const { profileResponse } = useSelector((store) => store.user);

  const history = useHistory();

  const handleBack = () => {
    if (parentSlug) {
      history.push(parentSlug);
    } else {
      history.goBack();
    }
  };

  return (
    <header
      id="header-child-with-coin"
      className="header-child-with-coin--wrapper"
    >
      <div className="header-child-with-coin--inner flex-center-space mb-3">
        <span
          onClick={handleBack}
          className="mk-fz-14 mk-fw-400 mk-text-subtle-black mk-text-link flex-center-center gap-2 hover-stroke-brand-blue mk-text-hover-brand-blue"
        >
          <CSArrowLeftNavigational
            className="mk-fz-12 stroke-subtle-black"
            style={{ width: '18px', height: '18px' }}
          />
          {translate('button.back.title')}
        </span>
        <h3 className="mb-0 mk-fz-16 mk-fw-500">{title}</h3>
        <span className="flex-center-end mk-fz-18 mk-fw-500 mk-text-brand-orange mk-text-hover-orange cursor-pointer" onClick={() => history.push(routesUser.managerCoin)}>
          <img
            src={EggCoinUrl}
            alt="egg-coin"
            style={{ width: '23px', height: '23px', marginRight: '3px' }}
          />{' '}
          {profileResponse?.data?.point}
        </span>
      </div>
    </header>
  );
});
