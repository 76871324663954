import { motion as m } from 'framer-motion';
import { memo, useEffect } from 'react';

export const HelmetCustom = memo(({ title, titleEntire, children }) => {
  document.title = titleEntire || (title ? `${title} | Meko Trading` : 'Meko Trading');

  const config = {
    option1: {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      transition: { duration: 0.75, ease: 'easeOut' },
      exit: { opacity: 0 },
    },
    option2: {
      initial: { x: '100%' },
      animate: { x: '0%' },
      transition: { duration: 0.75, ease: 'easeOut' },
      exit: { opacity: 0 },
    },
    option3: {
      initial: { opacity: 0 },
      whileInView: { opacity: 1 },
      viewport: { once: true },
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <m.div {...config.option1} className="framer-motion--wrapper">
      {children}
    </m.div>
  );
});
