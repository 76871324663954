const vnInputLangLib = {
  //
  'input.full-name.label': 'Họ và Tên',
  'input.full-name.placeholder': 'Họ và Tên',
  'input.full-name.error.required': 'Vui lòng nhập họ và tên!',
  'input.full-name.error.min': 'Ít nhất 2 ký tự!',
  'input.full-name.error.max': 'Tối đa 200 ký tự!',
  //
  'input.gender.label': 'Giới tính',
  'input.gender.placeholder': 'Giới tính',
  'input.gender.value.male': 'Nam',
  'input.gender.value.female': 'Nữ',
  //
  'input.birthday.label': 'Ngày sinh',
  'input.birthday.error.invalid': 'Ngày sinh không hợp lệ!',
  //
  'input.otp.label': 'Nhập mã OTP',
  'input.otp.placeholder': 'Nhập mã OTP',
  'input.otp.error.required': 'Vui lòng nhập mã OTP!',
  'input.otp.error.invalid': 'Mã OTP của bạn không chính xác!',
  'input.otp.error.send-failure': 'Gửi mã OTP thất bại! Xin vui lòng thử lại sau.',
  'input.otp.error.many-requests': 'Bạn đã gửi quá nhiều lần! Xin vui lòng quay lại sau.',
  //
  'input.phone-number.label': 'Số điện thoại',
  'input.phone-number.placeholder': 'Số điện thoại',
  'input.phone-number.error.required': 'Vui lòng nhập số điện thoại!',
  'input.phone-number.error.invalid': 'Số điện thoại tối đa 10 ký tự số!',
  'input.phone-number.error.duplicate': 'Số điện thoại của bạn đã được đăng ký trước đó!',
  //
  'input.password.label': 'Mật khẩu',
  'input.password.placeholder': 'Mật khẩu',
  'input.password.error.required': 'Vui lòng nhập mật khẩu!',
  'input.password.error.min': 'Mật khẩu có ít nhất 5 ký tự!',
  'input.password.error.max': 'Mật khẩu có nhiều nhất 20 ký tự!',
  'input.password.error.uppercase': 'Mật khẩu có ít nhất 1 ký tự in HOA!',
  'input.password.error.number': 'Mật khẩu có ít nhất 1 ký tự số!',
  'input.password.error.special': 'Mật khẩu có ít nhất 1 ký tự đặc biệt!',
  //
  'input.re-password.label': 'Nhập lại mật khẩu',
  'input.re-password.placeholder': 'Nhập lại mật khẩu',
  'input.re-password.error.required': 'Vui lòng nhập lại mật khẩu!',
  'input.re-password.error.not-match': 'Mật khẩu không khớp!',
  //
  'input.new-password.label': 'Mật khẩu mới',
  'input.new-password.placeholder': 'Mật khẩu mới',
  'input.new-password.error.required': 'Vui lòng nhập mật khẩu mới!',
  //
  'input.re-new-password.label': 'Nhập lại mật khẩu mới',
  'input.re-new-password.placeholder': 'Nhập lại mật khẩu mới',
  'input.re-new-password.error.required': 'Vui lòng nhập lại mật khẩu mới!',
  'input.re-new-password.error.not-match': 'Mật khẩu không khớp!',
  //
  'input.address.label': 'Địa chỉ',
  'input.address.placeholder': 'Địa chỉ',
  'input.address-custom-1.placeholder': 'Tên đường, Tóa nhà, Số nhà.',
  'input.address-custom-2.placeholder': 'Tỉnh/Thành phố',
  'input.address-custom-3.placeholder': 'Quận/Huyện',
  'input.address-custom-4.placeholder': 'Phường/Xã',
  'input.address.error.required': 'Vui lòng nhập địa chỉ!',
  //
  'input.province.label': 'Tỉnh thành',
  'input.province.placeholder': 'Tỉnh thành',
  'input.province.error.required': 'Vui lòng nhập tỉnh thành!',
  //
  'input.district.label': 'Huyện',
  'input.district.placeholder': 'Huyện',
  'input.district.error.required': 'Vui lòng nhập huyện!',
  //
  'input.ward.label': 'Xã',
  'input.ward.placeholder': 'Xã',
  'input.ward.error.required': 'Vui lòng nhập xã!',
  //
  'input.email.label': 'Email',
  'input.email.placeholder': 'Email',
  'input.email.error.required': 'Vui lòng nhập email!',
  'input.email.error.invalid': 'Email không hợp lệ!',
  'input.email-optional.label': 'Email (nếu có)',
  'input.email-optional.placeholder': 'Email (nếu có)',
  //
  'input.company-name.label': 'Tên Công Ty / Quán Ăn',
  'input.company-name.placeholder': 'Tên Công Ty / Quán Ăn',
  'input.company-name-optional.label': 'Tên Công Ty / Quán Ăn (nếu có)',
  'input.company-name-optional.placeholder': 'Tên Công Ty / Quán Ăn (nếu có)',
  'input.company-name.error.max': 'Tối đa 400 ký tự!',
  //
  'input.referral-code.label': 'Mã giới thiệu',
  'input.referral-code.placeholder': 'Mã giới thiệu',
  'input.referral-code-optional.label': 'Mã giới thiệu (nếu có)',
  'input.referral-code-optional.placeholder': 'Mã giới thiệu (nếu có)',
  //
  'input.product-quantity.label': 'Nhập số lượng',
  'input.product-quantity.placeholder': 'Nhập số lượng',
  //
  'input.product-pack.label': 'Nhập số bịch',
  'input.product-pack.placeholder': 'Nhập số bịch',
  'input.product-pack.placeholder2': 'Nhập số trứng',
  'input.product-pack.placeholder3': 'Nhập số lượng kg',
  //
  'input.product-bag.label': 'Nhập quy cách',
  'input.product-bag.placeholder': 'Nhập quy cách',
};

export default vnInputLangLib;
