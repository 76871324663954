/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import EggCoinUrl from '~/assets/images/common/egg-coin.png';
import { Banner } from '~/components/common';
import { HeaderChildWithCoin } from '~/components/common/header/HeaderChildWithCoin';
import { ButtonCustom } from '~/components/form';
import { HelmetCustom } from '~/components/helmetCustom';
import { routesUser } from '~/configs';
import { ToastError } from '~/helpers/funcs';
import { translate } from '~/helpers/utils';
import {
  exchangeMyGiftAndVoucherRequest,
  resetExchangeMyGiftAndVoucherState,
} from '~/redux/giftVoucher/actions';
import { changePointRequest } from '~/redux/user/actions';
import { getAllPointsRequest } from '~/redux/point/actions';
import { VoucherExchangeSuccessScreen } from './VoucherExchangeSuccessScreen';

export const UserVoucherDetail = () => {
  const { profileResponse, configResponse } = useSelector((store) => store.user);
  const {
    isGetAllVouchersSuccess,
    getAllVouchersResponse,
    isExchangeMyGiftAndVoucherRequest,
    isExchangeMyGiftAndVoucherSuccess,
    isExchangeMyGiftAndVoucherFailure,
  } = useSelector((store) => store.giftVoucher);
  const dispatch = useDispatch();

  const history = useHistory();
  const { voucherId } = useParams();

  const [isExchangeVoucherSuccess, setIsExchangeVoucherSuccess] = useState(false);
  const [isCheckVoucherExist, setIsCheckVoucherExist] = useState(false);
  const [voucherData, setVoucherData] = useState(null);

  useEffect(() => {
    if (isExchangeMyGiftAndVoucherFailure) {
      dispatch(resetExchangeMyGiftAndVoucherState());
    }
  }, []);

  useEffect(() => {
    if (isGetAllVouchersSuccess) {
      if (
        getAllVouchersResponse.data?.length === 0 || getAllVouchersResponse.data?.findIndex(
          (item) => item.id === parseInt(voucherId),
        ) === -1
      ) {
        history.push(routesUser.vouchersList);
      } else {
        setIsCheckVoucherExist(true);
        setVoucherData(
          getAllVouchersResponse.data.find(
            (item) => item.id === parseInt(voucherId),
          ),
        );
      }
    }
  }, [isGetAllVouchersSuccess, voucherId]);

  const handleExchangeVoucher = () => {
    if (voucherData.id) {
      const payload = {
        type: 'voucher',
        voucher_id: voucherData.id,
      };
      dispatch(exchangeMyGiftAndVoucherRequest(payload));
    }
  };

  useEffect(() => {
    if (isExchangeMyGiftAndVoucherSuccess) {
      dispatch(changePointRequest({ point: voucherData.score }));
      dispatch(getAllPointsRequest({ limit: 0 }));
      dispatch(resetExchangeMyGiftAndVoucherState());
      setIsExchangeVoucherSuccess(true);
    }
  }, [isExchangeMyGiftAndVoucherSuccess]);

  return (
    <HelmetCustom title={translate('helmet.title.voucher-detail')}>
      <div
        id="voucher-detail-page"
        className="voucher-detail-page--wrapper h-screen pt-4 d-flex flex-column"
      >
        <VoucherExchangeSuccessScreen
          isExchangeVoucherSuccess={isExchangeVoucherSuccess}
          voucherData={voucherData}
        />
        {!isExchangeVoucherSuccess && (
          <>
            <HeaderChildWithCoin
              title={translate('voucher-detail-page.title')}
            />
            <div className="gift-detail-page--inner flex-grow-1 d-flex flex-column scroll-y">
              <div className="flex-grow-1">
                {!isCheckVoucherExist || voucherData === null ? (
                  <>
                    <Skeleton
                      count={1}
                      width="100%"
                      style={{ lineHeight: 'unset', aspectRatio: '1/1' }}
                    />
                    <Skeleton
                      count={1}
                      width="100%"
                      height="20px"
                      style={{ lineHeight: 'unset', marginTop: '15px' }}
                    />
                    <Skeleton
                      count={1}
                      width="100%"
                      height="20px"
                      style={{ lineHeight: 'unset', marginTop: '15px' }}
                    />
                    <Skeleton
                      count={1}
                      width="100%"
                      height="20px"
                      style={{ lineHeight: 'unset', marginTop: '15px' }}
                    />
                    <Skeleton
                      count={1}
                      width="100%"
                      height="100px"
                      style={{ lineHeight: 'unset', marginTop: '15px' }}
                    />
                  </>
                ) : (
                  <>
                    <Banner
                      src={voucherData.image}
                      alt={voucherData.title}
                      classNameInner="mb-4"
                    />
                    <div className="box-content mb-5">
                      <h1 className="post-name mk-text-brand-blue mk-fz-16 mk-fw-600 mb-0">
                        {voucherData.title}
                      </h1>
                      <div className="post-detail mk-fz-14 mk-fw-400 flex-center-start">
                        {translate('voucher-detail-page.detail-title-1')}&nbsp;
                        <span className="flex-center-start mk-fw-500 mk-text-brand-orange">
                          {voucherData.score}{' '}
                          <img
                            src={EggCoinUrl}
                            alt="egg-coin"
                            style={{
                              width: '15px',
                              height: '15px',
                              marginLeft: '3px',
                            }}
                          />
                        </span>
                      </div>
                      <div
                        className="post-detail mk-fz-14 mk-fw-400"
                        dangerouslySetInnerHTML={{
                          __html: voucherData.content || '',
                        }}
                      />
                      <h3 className="post-name mk-text-brand-blue mk-fz-16 mk-fw-600 mt-2 mb-0">
                        {translate('voucher-detail-page.detail-title-2')}
                      </h3>
                      {
                        configResponse?.data?.content_voucher && (
                          <div
                            className="post-detail mk-fz-14 mk-fw-400"
                            dangerouslySetInnerHTML={{
                              __html: configResponse.data.content_voucher || '',
                            }}
                          />
                        )
                      }
                      {/* <div className="post-detail mk-fz-14 mk-fw-400">
                        <span className="mk-fw-500">{translate('voucher-detail-page.detail-title-3')}</span>
                        <br />
                        {translate('voucher-detail-page.detail-title-4')}
                        <br />
                        <span className="mk-fw-500">{translate('voucher-detail-page.detail-title-5')}</span>
                        <br />
                        {translate('voucher-detail-page.detail-title-6')}
                        <br />
                        <span className="mk-fw-500">{translate('voucher-detail-page.detail-title-7')}</span>
                        <br />
                        {translate('voucher-detail-page.detail-title-8')}
                      </div> */}
                      <ButtonCustom
                        disabled={
                          voucherData.score > profileResponse.data?.point
                          || isExchangeMyGiftAndVoucherRequest
                        }
                        isLoading={isExchangeMyGiftAndVoucherRequest}
                        color="brand-orange"
                        className="w-100 mt-4"
                        onClick={handleExchangeVoucher}
                      >
                        {translate(
                          voucherData.score > profileResponse.data?.point
                            ? 'voucher-detail-page.btn-unqualified'
                            : 'voucher-detail-page.btn-exchange',
                        )}
                      </ButtonCustom>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {isExchangeMyGiftAndVoucherFailure && (
        <ToastError
          show
          content={translate('voucher-detail-page.exchange-error-msg')}
        />
      )}
    </HelmetCustom>
  );
};
