// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getProductsListRequest = createAction('GET_PRODUCTS_LIST_REQUEST');
export const getProductsListSuccess = createAction('GET_PRODUCTS_LIST_SUCCESS');
export const getProductsListFailure = createAction('GET_PRODUCTS_LIST_FAILURE');

export const getProductTypesListRequest = createAction('GET_PRODUCT_TYPES_LIST_REQUEST');
export const getProductTypesListSuccess = createAction('GET_PRODUCT_TYPES_LIST_SUCCESS');
export const getProductTypesListFailure = createAction('GET_PRODUCT_TYPES_LIST_FAILURE');

export const getProductPricesRequest = createAction('GET_PRODUCT_PRICES_REQUEST');
export const getProductPricesSuccess = createAction('GET_PRODUCT_PRICES_SUCCESS');
export const getProductPricesFailure = createAction('GET_PRODUCT_PRICES_FAILURE');
export const resetGetProductPricesState = createAction('RESET_PRODUCT_PRICES_STATE');

export const getProductsListPricesRequest = createAction('GET_PRODUCTS_LIST_PRICES_REQUEST');
export const getProductsListPricesSuccess = createAction('GET_PRODUCTS_LIST_PRICES_SUCCESS');
export const getProductsListPricesFailure = createAction('GET_PRODUCTS_LIST_PRICES_FAILURE');
export const resetGetProductsListPricesState = createAction('RESET_PRODUCTS_LIST_PRICES_STATE');

export const resetProductState = createAction('RESET_PRODUCT_STATE');
