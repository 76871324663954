const vnAddressesManagerPageLangLib = {
  'addresses-manager-page.title': 'Địa chỉ của tôi',
  'addresses-manager-page.title-1': 'Địa chỉ mới',
  'addresses-manager-page.title-2': 'Sửa địa chỉ',
  'addresses-manager-page.apply.title': 'Địa chỉ mua hàng mặc định',
  'addresses-manager-page.apply.label': 'Đặt làm địa chỉ mặc định',
  'addresses-manager-page.ward-title': 'Phường',
  'addresses-manager-page.district-title': 'Quận',
  'addresses-manager-page.province-title': 'Thành phố',
  'addresses-manager-page.btn-add-new-address': 'Thêm địa chỉ mới',
  'addresses-manager-page.form-group-label-1': 'Liên hệ',
  'addresses-manager-page.form-group-label-2': 'Địa chỉ',
  'addresses-manager-page.form-group-label-3': 'Cài đặt',
  'addresses-manager-page.create-address-failure': 'Thêm địa chỉ mới không thành công! Vui lòng thử lại sau.',
  'addresses-manager-page.update-address-failure': 'Sửa địa chỉ không thành công! Vui lòng thử lại sau.',
  'addresses-manager-page.delete-address-failure': 'Xóa địa chỉ không thành công! Vui lòng thử lại sau.',
  'addresses-manager-page.btn-create-new-address': 'Thêm địa chỉ mới',
  'addresses-manager-page.btn-update-address': 'Hoàn thành',
  'addresses-manager-page.btn-delete-address': 'Xóa địa chỉ',
};

export default vnAddressesManagerPageLangLib;
