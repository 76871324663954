import { memo, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import EggCoinUrl from '~/assets/images/common/egg-coin.png';
import { routesUser } from '~/configs';
import { translate } from '~/helpers/utils';

export const VouchersList = memo(() => {
  const {
    isGetAllVouchersRequest,
    isGetAllVouchersSuccess,
    getAllVouchersResponse,
  } = useSelector((store) => store.giftVoucher);

  const [vouchersList, setVouchersList] = useState([]);

  useEffect(() => {
    if (isGetAllVouchersSuccess) {
      let vouchersListTmp = [];
      if (getAllVouchersResponse?.data.length > 0) {
        vouchersListTmp = [...getAllVouchersResponse.data];
      }
      setVouchersList(vouchersListTmp);
    }
  }, [isGetAllVouchersSuccess, getAllVouchersResponse]);

  return (
    <section className="home-page--vouchers-list">
      <div className="vouchers-list--inner mb-4">
        <div className="flex-center-space mb-3">
          <h3 className="section-title">
            {translate('home-page.voucher.title')}
          </h3>
          <p className="section-sub-title">
            <Link
              to={routesUser.vouchersList}
              className="mk-text-hover-secondary-brand-blue"
            >
              {translate('button.see-all.title')}
            </Link>
          </p>
        </div>
        <div className="vouchers-list">
          {isGetAllVouchersRequest
            ? [...new Array(5)].map((_, index) => (
              <div key={index} className="voucher-item">
                <Skeleton
                  count={1}
                  width="100%"
                  style={{ lineHeight: 'unset', aspectRatio: '1/1' }}
                />
                <Skeleton
                  count={1}
                  width="100%"
                  height="20px"
                  style={{ lineHeight: 'unset', marginTop: '8px' }}
                />
              </div>
            ))
            : vouchersList.map((item, index) => (
              <div key={index} className="voucher-item">
                <Link
                  to={routesUser.voucherDetail.replace(':voucherId', item.id)}
                  className="mk-text-hover-secondary-brand-blue"
                >
                  <img src={item.image} alt={item.title} draggable={false} />
                  <p className="mt-2 mb-0 mk-fz-14 mk-fw-500 mk-truncate-2">
                    {item.title}
                  </p>
                  <p className="flex-center-start mk-fz-16 mk-fw-500 mk-text-brand-orange mt-2">
                    {item.score}{' '}
                    <img
                      src={EggCoinUrl}
                      alt="egg-coin"
                      style={{
                        width: '19px',
                        height: '19px',
                        marginLeft: '3px',
                      }}
                    />
                  </p>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
});
