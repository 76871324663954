import { memo, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';

import { VoucherItem } from './VoucherItem';

export const VouchersList = memo(() => {
  const {
    isGetAllVouchersRequest,
    isGetAllVouchersSuccess,
    getAllVouchersResponse,
  } = useSelector((store) => store.giftVoucher);

  const [vouchersList, setVouchersList] = useState([]);

  useEffect(() => {
    if (isGetAllVouchersSuccess) {
      let vouchersListTmp = [];
      if (getAllVouchersResponse.data?.length > 0) {
        vouchersListTmp = [...getAllVouchersResponse.data];
      }
      setVouchersList(vouchersListTmp);
    }
  }, [isGetAllVouchersSuccess, getAllVouchersResponse]);

  return (
    <section className="vouchers-list--wrapper">
      <div className="vouchers-list--inner d-flex flex-wrap">
        {isGetAllVouchersRequest
          ? [...new Array(4)].map((_, index) => (
            <div key={index} className="voucher-item--wrapper w-50 mb-4">
              <Skeleton
                count={1}
                width="100%"
                style={{ lineHeight: 'unset', aspectRatio: '1/1' }}
              />
              <Skeleton
                count={1}
                width="100%"
                height="20px"
                style={{ lineHeight: 'unset', marginTop: '8px' }}
              />
            </div>
          ))
          : vouchersList.map((item, index) => (
            <VoucherItem key={index} data={item} />
          ))}
      </div>
    </section>
  );
});
