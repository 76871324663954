// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isCallDefaultApi: false,
  isCallApiFirstTime: false,
  //
  isGetProfileRequest: false,
  isGetProfileSuccess: false,
  isGetProfileFailure: false,
  profileResponse: {},
  //
  isGetConfigRequest: false,
  isGetConfigSuccess: false,
  isGetConfigFailure: false,
  configResponse: {},
  //
  isRemoveUserRequest: false,
  isRemoveUserSuccess: false,
  isRemoveUserFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get Profile
    [Actions.getProfileRequest]: (state) => ({
      ...state,
      isCallApiFirstTime: false,
      isGetProfileRequest: true,
      isGetProfileSuccess: false,
      isGetProfileFailure: false,
    }),
    [Actions.getProfileSuccess]: (state, { payload }) => ({
      ...state,
      isCallApiFirstTime: true,
      isGetProfileRequest: false,
      isGetProfileSuccess: true,
      isGetProfileFailure: false,
      profileResponse: payload,
    }),
    [Actions.getProfileFailure]: (state, { payload }) => ({
      ...state,
      isCallApiFirstTime: false,
      isGetProfileRequest: false,
      isGetProfileSuccess: false,
      isGetProfileFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get Profile
    [Actions.getConfigRequest]: (state) => ({
      ...state,
      isGetConfigRequest: true,
      isGetConfigSuccess: false,
      isGetConfigFailure: false,
    }),
    [Actions.getConfigSuccess]: (state, { payload }) => ({
      ...state,
      isGetConfigRequest: false,
      isGetConfigSuccess: true,
      isGetConfigFailure: false,
      configResponse: payload,
    }),
    [Actions.getConfigFailure]: (state, { payload }) => ({
      ...state,
      isGetConfigRequest: false,
      isGetConfigSuccess: false,
      isGetConfigFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Local
    [Actions.updateAddressesConfig]: (state, { payload }) => ({
      ...state,
      configResponse: {
        ...state.configResponse,
        data: {
          ...state.configResponse.data,
          profile: {
            ...state.configResponse.data.profile,
            addresses: {
              data: payload.type === 'create'
                ? [
                  ...state.configResponse.data.profile.addresses.data.map((item) => {
                    const response = payload.data;
                    if (response.apply) {
                      return {
                        ...item,
                        apply: false,
                      };
                    } else {
                      return item;
                    }
                  }),
                  payload.data,
                ]
                : payload.type === 'update' ? [
                  ...state.configResponse.data.profile.addresses.data.map((item) => {
                    const response = payload.data;
                    if (item.id === response.id) {
                      return response;
                    } else if (item.id !== response.id) {
                      if (response.apply) {
                        return {
                          ...item,
                          apply: false,
                        };
                      } else {
                        return item;
                      }
                    }
                  }),
                ] : payload.type === 'delete' ? [...state.configResponse.data.profile.addresses.data.filter((item) => item.id !== payload.id)] : [...state.configResponse.data.profile.addresses.data],
            },
          },
        },
      },
    }),
    [Actions.updateAddressesProfile]: (state, { payload }) => ({
      ...state,
      profileResponse: {
        ...state.profileResponse,
        data: {
          ...state.profileResponse.data,
          address: {
            data: { ...payload },
          },
        },
      },
    }),
    [Actions.updateInformationUserRequest]: (state, { payload }) => ({
      ...state,
      profileResponse: {
        ...state.profileResponse,
        data: {
          ...payload.data,
        },
      },
    }),
    [Actions.changePointRequest]: (state, { payload }) => ({
      ...state,
      profileResponse: {
        ...state.profileResponse,
        data: {
          ...state.profileResponse.data,
          point: state.profileResponse.data.point - payload.point,
        },
      },
    }),
    [Actions.callApiFirstTimeRequest]: (state) => ({
      ...state,
      isCallApiFirstTime: false,
    }),
    [Actions.callApiDefaultRequest]: (state) => ({
      ...state,
      isCallDefaultApi: true,
    }),
    [Actions.removeUserRequest]: (state) => ({
      ...state,
      isRemoveUserRequest: true,
      isRemoveUserSuccess: false,
      isRemoveUserFailure: false,
    }),
    [Actions.removeUserSuccess]: (state) => ({
      ...state,
      isRemoveUserRequest: false,
      isRemoveUserSuccess: true,
      isRemoveUserFailure: false,
    }),
    [Actions.removeUserFailure]: (state, { payload }) => ({
      ...state,
      isRemoveUserRequest: false,
      isRemoveUserSuccess: false,
      isRemoveUserFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetRemoveUserState]: (state) => ({
      ...state,
      isRemoveUserRequest: false,
      isRemoveUserSuccess: false,
      isRemoveUserFailure: false,
      errorMessages: [],
    }),
    [Actions.resetUserState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
