/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import EggCoinUrl from '~/assets/images/common/egg-coin.png';
import { Banner } from '~/components/common';
import { HeaderChildWithCoin } from '~/components/common/header/HeaderChildWithCoin';
import { ButtonCustom } from '~/components/form';
import { HelmetCustom } from '~/components/helmetCustom';
import { routesAuth, routesUser } from '~/configs';
import { ToastError } from '~/helpers/funcs';
import { translate } from '~/helpers/utils';
import {
  exchangeMyGiftAndVoucherRequest,
  resetExchangeMyGiftAndVoucherState,
} from '~/redux/giftVoucher/actions';
import { changePointRequest } from '~/redux/user/actions';
import { getAllPointsRequest } from '~/redux/point/actions';
import { GiftExchangeSuccessScreen } from './GiftExchangeSuccessScreen';

export const UserGiftDetail = () => {
  const { profileResponse, configResponse } = useSelector((store) => store.user);
  const {
    isGetAllGiftsSuccess,
    getAllGiftsResponse,
    isExchangeMyGiftAndVoucherRequest,
    isExchangeMyGiftAndVoucherSuccess,
    isExchangeMyGiftAndVoucherFailure,
  } = useSelector((store) => store.giftVoucher);
  const dispatch = useDispatch();

  const history = useHistory();
  const { giftId } = useParams();

  const [isExchangeGiftSuccess, setIsExchangeGiftSuccess] = useState(false);
  const [isCheckGiftExist, setIsCheckGiftExist] = useState(false);
  const [giftData, setGiftData] = useState(null);

  useEffect(() => {
    if (isExchangeMyGiftAndVoucherFailure) {
      dispatch(resetExchangeMyGiftAndVoucherState());
    }
  }, []);

  useEffect(() => {
    if (isGetAllGiftsSuccess) {
      if (
        getAllGiftsResponse.data?.length === 0
        || getAllGiftsResponse.data?.findIndex(
          (item) => item.id === parseInt(giftId),
        ) === -1
      ) {
        history.push(routesUser.giftsList);
      } else {
        setIsCheckGiftExist(true);
        setGiftData(
          getAllGiftsResponse.data.find((item) => item.id === parseInt(giftId)),
        );
      }
    }
  }, [isGetAllGiftsSuccess, giftId]);

  const handleExchangeGift = () => {
    if (giftData.id) {
      const payload = {
        type: 'gift',
        gift_id: giftData.id,
      };
      dispatch(exchangeMyGiftAndVoucherRequest(payload));
    }
  };

  useEffect(() => {
    if (isExchangeMyGiftAndVoucherSuccess) {
      dispatch(changePointRequest({ point: giftData.score }));
      dispatch(getAllPointsRequest({ limit: 0 }));
      dispatch(resetExchangeMyGiftAndVoucherState());
      setIsExchangeGiftSuccess(true);
    }
  }, [isExchangeMyGiftAndVoucherSuccess]);

  return (
    <HelmetCustom title={translate('helmet.title.gift-detail')}>
      <div
        id="gift-detail-page"
        className="gift-detail-page--wrapper h-screen pt-4 d-flex flex-column"
      >
        <GiftExchangeSuccessScreen
          isExchangeGiftSuccess={isExchangeGiftSuccess}
          giftData={giftData}
        />
        {!isExchangeGiftSuccess && (
          <>
            <HeaderChildWithCoin
              title={translate('gift-detail-page.title')}
            />
            <div className="gift-detail-page--inner flex-grow-1 d-flex flex-column scroll-y">
              <div className="flex-grow-1">
                {!isCheckGiftExist || giftData === null ? (
                  <>
                    <Skeleton
                      count={1}
                      width="100%"
                      style={{ lineHeight: 'unset', aspectRatio: '1/1' }}
                    />
                    <Skeleton
                      count={1}
                      width="100%"
                      height="20px"
                      style={{ lineHeight: 'unset', marginTop: '15px' }}
                    />
                    <Skeleton
                      count={1}
                      width="100%"
                      height="20px"
                      style={{ lineHeight: 'unset', marginTop: '15px' }}
                    />
                    <Skeleton
                      count={1}
                      width="100%"
                      height="20px"
                      style={{ lineHeight: 'unset', marginTop: '15px' }}
                    />
                    <Skeleton
                      count={1}
                      width="100%"
                      height="100px"
                      style={{ lineHeight: 'unset', marginTop: '15px' }}
                    />
                  </>
                ) : (
                  <>
                    <Banner
                      src={giftData.image}
                      alt={giftData.title}
                      classNameInner="mb-4"
                    />
                    <div className="box-content mb-5">
                      <h1 className="post-name mk-text-brand-blue mk-fz-16 mk-fw-600 mb-0">
                        {giftData.title}
                      </h1>
                      <div className="post-detail mk-fz-14 mk-fw-400 flex-center-start">
                        {translate('voucher-detail-page.detail-title-1')}&nbsp;
                        <span className="flex-center-start mk-fw-500 mk-text-brand-orange">
                          {giftData.score}{' '}
                          <img
                            src={EggCoinUrl}
                            alt="egg-coin"
                            style={{
                              width: '15px',
                              height: '15px',
                              marginLeft: '3px',
                            }}
                          />
                        </span>
                      </div>
                      <div
                        className="post-detail mk-fz-14 mk-fw-400"
                        dangerouslySetInnerHTML={{
                          __html: giftData.content || '',
                        }}
                      />
                      <h3 className="post-name mk-text-brand-blue mk-fz-16 mk-fw-600 mt-2 mb-0">
                        {translate('voucher-detail-page.detail-title-2')}
                      </h3>
                      {
                        configResponse?.data?.content_voucher && (
                          <div
                            className="post-detail mk-fz-14 mk-fw-400"
                            dangerouslySetInnerHTML={{
                              __html: configResponse.data.content_voucher || '',
                            }}
                          />
                        )
                      }
                      {/*
                      <div className="post-detail mk-fz-14 mk-fw-400">
                        <span className="mk-fw-500">{translate('voucher-detail-page.detail-title-3')}</span>
                        <br />
                        {translate('voucher-detail-page.detail-title-4')}
                        <br />
                        <span className="mk-fw-500">{translate('voucher-detail-page.detail-title-5')}</span>
                        <br />
                        {translate('voucher-detail-page.detail-title-6')}
                        <br />
                        <span className="mk-fw-500">{translate('voucher-detail-page.detail-title-7')}</span>
                        <br />
                        {translate('voucher-detail-page.detail-title-8')}
                      </div> */}
                      {
                        profileResponse?.data ? (
                          <ButtonCustom
                            disabled={
                              giftData.score > profileResponse.data?.point
                              || isExchangeMyGiftAndVoucherRequest
                            }
                            isLoading={isExchangeMyGiftAndVoucherRequest}
                            color="brand-orange"
                            className="w-100 mt-4"
                            onClick={handleExchangeGift}
                          >
                            {translate(
                              giftData.score > profileResponse.data?.point
                                ? 'gift-detail-page.btn-unqualified'
                                : 'gift-detail-page.btn-exchange',
                            )}
                          </ButtonCustom>
                        ) : (
                          <ButtonCustom
                            color="brand-orange"
                            className="w-100 mt-4"
                            onClick={() => history.push(routesAuth.login)}
                          >
                            {translate('button.required.login')}
                          </ButtonCustom>
                        )
                      }
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {isExchangeMyGiftAndVoucherFailure && (
        <ToastError
          show
          content={translate('gift-detail-page.exchange-error-msg')}
        />
      )}
    </HelmetCustom>
  );
};
