const vnProductTypePageLangLib = {
  'product-type-page.title': 'Dòng sản phẩm',
  'product-type-page.footer.title': 'Tổng đơn hàng',
  'product-type-page.modal-confirm-back.title': 'Xác nhận lưu sản phẩm!',
  'product-type-page.modal-confirm-back.content': 'Các sản phẩm của bạn hiện chưa được lưu vào đơn hàng. Bấm nút <span>Mua hàng</span> để lưu các sản phẩm vào giỏ hàng.',
  'product-type-page.modal-confirm-back.btn-1': 'Quay lại',
  'product-type-page.modal-confirm-back.btn-2': 'Thoát',
};

export default vnProductTypePageLangLib;
