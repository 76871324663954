import BannerVouchersListUrl from '~/assets/images/banner/banner-1.avif';
import { Banner, FooterInfo } from '~/components/common';
import { HeaderChildWithCoin } from '~/components/common/header/HeaderChildWithCoin';
import { HelmetCustom } from '~/components/helmetCustom';
import { translate } from '~/helpers/utils';
import { VouchersList } from './components';

export const UserVouchersList = () => (
  <HelmetCustom title={translate('helmet.title.vouchers-list')}>
    <div
      id="vouchers-list-page"
      className="vouchers-list-page--wrapper h-screen pt-4 d-flex flex-column"
    >
      <HeaderChildWithCoin
        title={translate('vouchers-list-page.title')}
      />
      <div className="vouchers-list-page--inner flex-grow-1 d-flex flex-column scroll-y">
        <Banner
          src={BannerVouchersListUrl}
          alt="Meko-Banner"
          classNameInner="mb-4"
        />
        <div className="vouchers-list--main flex-grow-1">
          <VouchersList />
        </div>
        <FooterInfo />
      </div>
    </div>
  </HelmetCustom>
);
