// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllNotificationsRequest = createAction('GET_ALL_NOTIFICATIONS_REQUEST');
export const getAllNotificationsSuccess = createAction('GET_ALL_NOTIFICATIONS_SUCCESS');
export const getAllNotificationsFailure = createAction('GET_ALL_NOTIFICATIONS_FAILURE');

export const readNotificationRequest = createAction('READ_NOTIFICATION_REQUEST');
export const readNotificationSuccess = createAction('READ_NOTIFICATION_SUCCESS');
export const readNotificationFailure = createAction('READ_NOTIFICATION_FAILURE');
export const resetReadNotificationState = createAction('RESET_READ_NOTIFICATION_STATE');

export const resetNotificationState = createAction('RESET_NOTIFICATION_STATE');
