import { useFormik } from 'formik';
import { useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import LogoWithTextUrl from '~/assets/images/logo/logo-with-text.png';
import { ButtonCustom, InputPassword } from '~/components/form';
import { routesAuth } from '~/configs';
import { translate } from '~/helpers/utils';
import { ChangePasswordRequireSuccessScreen } from './ChangePasswordRequireSuccessScreen';

export const RequiredChangePasswordPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [
    isShowChangePasswordRequireSuccessScreen,
    setIsShowChangePasswordRequireSuccessScreen,
  ] = useState(false);

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      reNewPassword: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .trim()
        .required(translate('input.new-password.error.required'))
        .min(8, translate('input.password.error.max'))
        .max(20, translate('input.password.error.min'))
        .matches(/[A-Z]/, translate('input.password.error.uppercase'))
        .matches(/[0-9]/, translate('input.password.error.number')),
      reNewPassword: Yup.string()
        .required(translate('input.re-new-password.error.required'))
        .oneOf(
          [Yup.ref('newPassword')],
          translate('input.re-new-password.error.not-match'),
        ),
    }),
    onSubmit: () => {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
        setIsShowChangePasswordRequireSuccessScreen(true);
      }, 2000);
    },
  });

  return (
    <div
      id="required-change-password-page"
      className="required-change-password-page--wrapper"
    >
      <ChangePasswordRequireSuccessScreen
        isShowChangePasswordRequireSuccessScreen={
          isShowChangePasswordRequireSuccessScreen
        }
      />
      <div
        className={`required-change-password-page--inner${isShowChangePasswordRequireSuccessScreen ? ' hidden' : ''}`}
      >
        <div className="required-change-password-page--header">
          <img src={LogoWithTextUrl} alt="Meko Trading" />
        </div>
        <div className="required-change-password-page--form">
          <Form noValidate onSubmit={formik.handleSubmit}>
            <h3 className="mb-3 mk-fz-16 mk-fw-500">
              {translate('required-change-password-page.title')}
            </h3>
            <InputPassword
              id="newPassword"
              name="newPassword"
              placeholder={translate('input.new-password.placeholder')}
              isRequired
              {...formik.getFieldProps('newPassword')}
              isValid={formik.touched.newPassword && !formik.errors.newPassword}
              isInvalid={
                formik.touched.newPassword && formik.errors.newPassword
              }
              isError={formik.touched.newPassword && formik.errors.newPassword}
              msgError={formik.errors.newPassword}
              disabled={isLoading}
            />
            <div className="mb-3" />
            <InputPassword
              id="reNewPassword"
              name="reNewPassword"
              placeholder={translate('input.re-new-password.placeholder')}
              isRequired
              {...formik.getFieldProps('reNewPassword')}
              isValid={
                formik.touched.reNewPassword && !formik.errors.reNewPassword
              }
              isInvalid={
                formik.touched.reNewPassword && formik.errors.reNewPassword
              }
              isError={
                formik.touched.reNewPassword && formik.errors.reNewPassword
              }
              msgError={formik.errors.reNewPassword}
              disabled={isLoading}
            />
            <div className="mb-5" />
            <ButtonCustom
              variant={isLoading ? 'disabled' : 'primary'}
              type="submit"
              className="w-100"
              style={{ height: '50px' }}
            >
              {isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                translate('button.update.title')
              )}
            </ButtonCustom>
          </Form>
        </div>
        <div className="required-change-password-page--footer">
          <p className="mk-text-center mk-fz-14 mk-fw-500 mb-0">
            {translate('required-change-password-page.dont-have-account.title')}{' '}
            <Link
              to={isLoading ? '#' : routesAuth.register}
              className={`mk-fz-16 mk-fw-600 mk-text-secondary-brand mk-text-link${isLoading ? ' disabled' : ''}`}
            >
              {translate('button.sign-up.title')}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
