// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllAddressesRequest = createAction('GET_ALL_ADDRESSES_REQUEST');
export const getAllAddressesSuccess = createAction('GET_ALL_ADDRESSES_SUCCESS');
export const getAllAddressesFailure = createAction('GET_ALL_ADDRESSES_FAILURE');

export const createAddressRequest = createAction('CREATE_ADDRESS_REQUEST');
export const createAddressSuccess = createAction('CREATE_ADDRESS_SUCCESS');
export const createAddressFailure = createAction('CREATE_ADDRESS_FAILURE');
export const resetCreateAddressState = createAction('RESET_CREATE_ADDRESS_STATE');

export const updateAddressRequest = createAction('UPDATE_ADDRESS_REQUEST');
export const updateAddressSuccess = createAction('UPDATE_ADDRESS_SUCCESS');
export const updateAddressFailure = createAction('UPDATE_ADDRESS_FAILURE');
export const resetUpdateAddressState = createAction('RESET_UPDATE_ADDRESS_STATE');

export const deleteAddressRequest = createAction('DELETE_ADDRESS_REQUEST');
export const deleteAddressSuccess = createAction('DELETE_ADDRESS_SUCCESS');
export const deleteAddressFailure = createAction('DELETE_ADDRESS_FAILURE');
export const resetDeleteAddressState = createAction('RESET_DELETE_ADDRESS_STATE');

export const resetAddressState = createAction('RESET_ADDRESS_STATE');
