/* eslint-disable jsx-a11y/media-has-caption */
import {
  memo, useEffect, useRef, useState,
} from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import BackgroundUrl from '~/assets/images/pages/order-detail/reorder-order.png';
import successSound from '~/assets/sounds/success-noti.mp3';
import { ButtonCustom } from '~/components/form';
import { routesUser } from '~/configs';

export const ModalSuccess = memo(({ handleClose }) => {
  const history = useHistory();
  const audioRef = useRef(null);

  const [seconds, setSeconds] = useState(5);
  const [isPlayAudio, setIsPlayAudio] = useState(false);

  useEffect(() => {
    if (!isPlayAudio) {
      audioRef.current.play();
      setIsPlayAudio(true);
    }

    const interval = setInterval(() => {
      const newSeconds = seconds - 1;
      if (newSeconds >= 0) {
        setSeconds((currentSeconds) => currentSeconds - 1);
      } else {
        clearInterval(interval);
        history.push(routesUser.home);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <Modal
      show
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="modal-cart-page modal-success--wrapper"
    >
      <Modal.Body>
        <div className="modal-success--inner">
          <div className="image-wrapepr">
            <img src={BackgroundUrl} alt="" className="m-auto" style={{ width: '60%' }} />
          </div>
          <p className="mb-0 mk-fz-18 mk-text-uppercase mk-fw-500 mk-text-brand-orange mk-text-center">Chúc mừng đổi điểm thành công</p>
          <p className="mb-0 mk-fz-14 mk-fw-500">Quà tặng / Voucher đã được quy đổi thành công và đang áp dụng cho đơn hàng hiện tại.</p>
          <p className="mb-5 mk-fs-i mk-fz-12 mk-fw-500">Lưu ý: Quà tặng có thể gửi đồng thời hoặc gửi sau đơn hàng</p>
          <ButtonCustom className="w-100" color="secondary-brand-blue" onClick={() => history.push(routesUser.home)}>Quay về trang chủ ({seconds}s)</ButtonCustom>
        </div>
        <div className={`pyro${seconds < 4 ? ' hidden' : ''}`}>
          <div className="before" />
          <div className="after" />
        </div>
        <audio
          ref={audioRef}
          controls
          id="success-sound-noti"
          className="d-none"
        >
          <source src={successSound} type="audio/mpeg" />
        </audio>
      </Modal.Body>
    </Modal>
  );
});
