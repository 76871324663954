import Skeleton from 'react-loading-skeleton';

import { useSelector } from 'react-redux';
import { memo } from 'react';
import { ProductItem } from './ProductItem';

export const ProductsList = memo(({
  isLoading,
  isLoadingPrice,
  productsListData,
  productPricesList,
}) => {
  const { profileResponse } = useSelector((store) => store.user);
  return (
    <section className="product-list--wrapper">
      <div className={`product-list--inner ${profileResponse?.data ? '' : 'full'}`}>
        {isLoading
          ? [...new Array(5)].map((_, index) => (
            <div
              key={index}
              style={index !== 4 ? { marginBottom: '20px' } : {}}
            >
              <Skeleton
                count={1}
                width="100%"
                height="26px"
                style={{ lineHeight: 'unset' }}
              />
            </div>
          ))
          : productsListData.map((item, index) => (
            <ProductItem
              key={index}
              data={item}
              isLoadingPrice={isLoadingPrice}
              productPricesList={productPricesList}
            />
          ))}
      </div>
    </section>
  );
});
