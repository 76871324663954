import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrdersCancelRequest } from '~/redux/order/actions';
import { OrdersList } from './OrdersList';

export const OrdersListCancel = () => {
  const {
    isGetOrdersCancelRequest,
    isGetOrdersCancelSuccess,
    getOrdersCancelResponse,
  } = useSelector((store) => store.order);
  const dispatch = useDispatch();

  const [ordersList, setOrdersList] = useState([]);

  useEffect(() => {
    if (!isGetOrdersCancelSuccess) {
      dispatch(getOrdersCancelRequest());
    }
  }, []);

  useEffect(() => {
    if (isGetOrdersCancelSuccess) {
      let ordersListTmp = [];
      if (getOrdersCancelResponse?.data.length > 0) {
        ordersListTmp = [...getOrdersCancelResponse.data];
      }
      setOrdersList(ordersListTmp);
    }
  }, [isGetOrdersCancelSuccess, getOrdersCancelResponse]);

  return (
    <div className="orders-list-cancel--tab">
      <OrdersList
        type="cancel"
        data={ordersList}
        isLoading={isGetOrdersCancelRequest}
      />
    </div>
  );
};
