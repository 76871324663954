import moment from 'moment';
import { memo } from 'react';
import { Link } from 'react-router-dom';

import EggCoinUrl from '~/assets/images/common/egg-coin.png';
import { routesUser } from '~/configs';
import { translate } from '~/helpers/utils';

export const Item = memo(({ type, data }) => (
  <div className="item-wrapper">
    <Link
      to={
        type === 'gift'
          ? routesUser.giftDetail.replace(':giftId', data.id)
          : routesUser.voucherDetail.replace(':voucherId', data.id)
      }
      className="cursor-pointer mk-text-hover-secondary-brand-blue"
    >
      <div className="item--inner flex-stretch-center">
        {type !== 'history' && (
          <div className="item-img">
            <img
              src={
                type === 'gift'
                  ? data?.gift?.data?.image
                  : data?.voucher?.data?.image
              }
              alt={`order-${data?.id || ''}`}
            />
          </div>
        )}
        <div className="flex-start-center flex-column flex-grow-1 px-3">
          {type !== 'history' ? (
            <>
              <p className="mb-0 mk-fz-14 mk-fw-600 mk-truncate-2">
                {type === 'gift'
                  ? data?.gift?.data?.title
                  : data?.voucher?.data?.title}
              </p>
              <p className="mt-2 w-100 mb-0 mk-fz-10 mk-fw-300 flex-center-space">
                <span>
                  {translate('manager-coin-page.sub-title-2')}{' '}
                  {moment(data?.created_at).format('DD/MM/YYYY')}
                </span>
                <span
                  className={`mk-fw-600 ${data?.apply
                    ? 'mk-text-secondary-brand-blue'
                    : 'mk-text-brand-orange'}`}
                >
                  {translate(
                    data.apply
                      ? 'manager-coin-page.nav-5'
                      : 'manager-coin-page.nav-4',
                  )}
                </span>
              </p>
            </>
          ) : (
            <div className="py-3">
              <p className="mb-0 flex-center-start gap-2">
                <span className="mk-fz-12 mk-text-subtle-black mk-fw-400">
                  {moment(data?.created_at || new Date()).format('DD/MM/YYYY')}
                </span>
                <span className="flex-center-center mk-fz-14 mk-text-brand-orange mk-fw-500">
                  {data?.score < 0 ? data?.score : '+'.concat(data?.score)}
                  <img
                    src={EggCoinUrl}
                    alt="egg-coin"
                    style={{ width: '18px', height: '18px' }}
                  />
                </span>
              </p>
              <p className="mk-fz-14 mk-fw-500 mb-0">{data?.name}</p>
              <p className="mk-fz-14 mk-fw-500 mk-text-secondary-brand-blue mb-0">
                {data?.note}
              </p>
            </div>
          )}
        </div>
      </div>
    </Link>
  </div>
));
