// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  isGetFeeRequest: false,
  isGetFeeSuccess: false,
  isGetFeeFailure: false,
  feeResponse: {},
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get Fee
    [Actions.getFeeRequest]: (state) => ({
      ...state,
      isGetFeeRequest: true,
      isGetFeeSuccess: false,
      isGetFeeFailure: false,
    }),
    [Actions.getFeeSuccess]: (state, { payload }) => ({
      ...state,
      isGetFeeRequest: false,
      isGetFeeSuccess: true,
      isGetFeeFailure: false,
      feeResponse: payload,
    }),
    [Actions.getFeeFailure]: (state, { payload }) => ({
      ...state,
      isGetFeeRequest: false,
      isGetFeeSuccess: false,
      isGetFeeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetFeeState]: (state) => ({
      ...state,
      isGetFeeRequest: false,
      isGetFeeSuccess: false,
      isGetFeeFailure: false,
      feeResponse: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetShippingState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
