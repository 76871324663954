import { memo } from 'react';
import { Link } from 'react-router-dom';

import EggCoinUrl from '~/assets/images/common/egg-coin.png';
import { routesUser } from '~/configs';

export const GiftItem = memo(({ data }) => (
  <div className="gift-item--wrapper w-50">
    <div className="gift-item--inner">
      <Link
        to={routesUser.giftDetail.replace(':giftId', data.id)}
        className="mk-text-hover-brand-blue"
      >
        <div className="gift-item--img mb-2">
          <img src={data.image} alt={data.title} className="img-square" />
        </div>
        <p className="gift-item--name mk-fz-14 mk-fw-500 mb-0 mk-truncate-2">
          {data.title}
        </p>
        <p className="flex-center-start mk-fz-14 mk-fw-500 mk-text-brand-orange mt-1 mb-4">
          {data.score}{' '}
          <img
            src={EggCoinUrl}
            alt="egg-coin"
            style={{ width: '15px', height: '15px', marginLeft: '3px' }}
          />
        </p>
      </Link>
    </div>
  </div>
));
