import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import AvtDefaultUrl from '~/assets/images/common/avt-default.svg';
import {
  ContactButton,
  FooterInfo,
  HeaderChildWithoutCoin,
} from '~/components/common';
import {
  ButtonCustom,
  InputDate,
  InputSelect,
  InputText,
} from '~/components/form';
import { HelmetCustom } from '~/components/helmetCustom';
import {
  CSLoadOutline,
  CSUpload2Outline,
} from '~/components/iconography/Outline';
import { ToastError, ToastSuccess } from '~/helpers/funcs';
import { translate } from '~/helpers/utils';
import { resetUpdateUserState, updateUserRequest } from '~/redux/auth/actions';
import { updateInformationUserRequest } from '~/redux/user/actions';

export const UserInformation = () => {
  const { profileResponse } = useSelector((store) => store.user);
  const {
    isUpdateUserRequest,
    isUpdateUserSuccess,
    isUpdateUserFailure,
    updateUserResponse,
  } = useSelector((store) => store.auth);
  const dispatch = useDispatch();

  const [avatar, setAvatar] = useState('');
  const [isUploadAvatar, setIsUploadAvatar] = useState(false);
  const [isShowErrMsgUploadAvt, setIsShowErrMsgUploadAvt] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: profileResponse.data.name,
      company: profileResponse.data.company || '',
      email: profileResponse.data.email || '',
      gender: profileResponse.data.gender || 'unspecified',
      birthday: profileResponse.data.birthday
        ? moment(profileResponse.data.birthday).format('DD/MM/YYYY')
        : '',
      phone: profileResponse.data.phone, //
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .trim()
        .required(translate('input.full-name.error.required'))
        .min(2, translate('input.full-name.error.min'))
        .max(200, translate('input.full-name.error.max')),
      company: Yup.string()
        .trim()
        .max(400, translate('input.company-name.error.max')),
      email: Yup.string().trim().email(translate('input.email.error.invalid')),
      gender: Yup.string().trim(),
      birthday: Yup.string().trim(),
    }),
    onSubmit: (values) => {
      if (
        values.birthday.includes('d')
        || values.birthday.includes('m')
        || values.birthday.includes('y')
      ) {
        return;
      }
      setIsShowErrMsgUploadAvt(false);
      const payload = {
        type: 'update-information',
        data: {
          id: profileResponse.data.id,
          name: values.name,
          gender: values.gender,
        },
      };
      if (values.company.trim().length > 0) {
        payload.data.company = values.company;
      }
      if (values.email.trim().length > 0) {
        payload.data.email = values.email;
      }
      if (values.birthday.trim().length > 0) {
        payload.data.birthday = `${values.birthday.slice(6)}-${values.birthday.slice(3, 5)}-${values.birthday.slice(0, 2)}`;
      }

      dispatch(updateUserRequest(payload));
    },
  });

  useEffect(
    () => () => {
      dispatch(resetUpdateUserState());
    },
    [],
  );

  useEffect(() => {
    if (avatar) {
      setIsShowErrMsgUploadAvt(false);
      const payload = {
        type: 'update-avatar',
        data: {
          id: profileResponse.data.id,
          avatar,
        },
      };
      dispatch(updateUserRequest(payload));
      setIsUploadAvatar(true);
      setAvatar(false);
    }
  }, [avatar]);

  useEffect(() => {
    if (isUpdateUserSuccess || isUpdateUserFailure) {
      if (isUpdateUserSuccess) {
        dispatch(updateInformationUserRequest(updateUserResponse));
      }
      if (isUploadAvatar) {
        setIsUploadAvatar(false);
        if (isUpdateUserFailure) {
          setIsShowErrMsgUploadAvt(true);
        }
        dispatch(resetUpdateUserState());
      }
    }
  }, [isUpdateUserSuccess, isUpdateUserFailure]);

  return (
    <HelmetCustom title={translate('helmet.title.information')}>
      <div
        id="information-page"
        className="information-page--wrapper h-screen pt-4 d-flex flex-column"
      >
        <HeaderChildWithoutCoin title={translate('information-page.title')} />
        <div className="information-page--inner flex-grow-1 d-flex flex-column scroll-y">
          <div className="mb-2 flex-grow-1">
            <div className="avatar-wrapper">
              <img
                src={profileResponse.data.avatar || AvtDefaultUrl}
                alt={`${profileResponse.data.name}-avatar`}
              />
              <input
                id="avatar"
                name="avatar"
                type="file"
                onChange={(e) => setAvatar(e.target.files[0])}
              />
              <span className="btn-upload">
                <CSUpload2Outline className="fill-white" />
              </span>
              {isUploadAvatar && (
                <span className="btn-loading">
                  <CSLoadOutline className="fill-secondary-brand-blue" />
                </span>
              )}
            </div>
            <Form noValidate onSubmit={formik.handleSubmit}>
              <div className="information-wrapper">
                <InputText
                  labelFor="phone"
                  labelContent={translate('input.phone-number.label')}
                  id="phone"
                  name="phone"
                  disabled
                  {...formik.getFieldProps('phone')}
                />
                <InputText
                  labelFor="name"
                  labelContent={translate('input.full-name.label')}
                  id="name"
                  name="name"
                  isRequired
                  disabled={isUpdateUserRequest}
                  {...formik.getFieldProps('name')}
                  isValid={formik.touched.name && !formik.errors.name}
                  isInvalid={formik.touched.name && formik.errors.name}
                  isError={formik.touched.name && formik.errors.name}
                  msgError={formik.errors.name}
                />
                <InputText
                  labelFor="company"
                  labelContent={translate('input.company-name.label')}
                  id="company"
                  name="company"
                  disabled={isUpdateUserRequest}
                  {...formik.getFieldProps('company')}
                  isValid={formik.touched.company && !formik.errors.company}
                  isInvalid={formik.touched.company && formik.errors.company}
                  isError={formik.touched.company && formik.errors.company}
                  msgError={formik.errors.company}
                />
                <InputText
                  labelFor="email"
                  labelContent={translate('input.email.label')}
                  id="email"
                  name="email"
                  disabled={isUpdateUserRequest}
                  {...formik.getFieldProps('email')}
                  isValid={formik.touched.email && !formik.errors.email}
                  isInvalid={formik.touched.email && formik.errors.email}
                  isError={formik.touched.email && formik.errors.email}
                  msgError={formik.errors.email}
                />
                <InputSelect
                  labelFor="gender"
                  labelContent={translate('input.gender.label')}
                  id="gender"
                  name="gender"
                  className={
                    formik.values.gender === 'unspecified'
                      ? 'color-placeholder'
                      : ''
                  }
                  disabled={isUpdateUserRequest}
                  {...formik.getFieldProps('gender')}
                  isValid={formik.touched.gender && !formik.errors.gender}
                >
                  <option value="unspecified">---</option>
                  <option value="male">
                    {translate('input.gender.value.male')}
                  </option>
                  <option value="female">
                    {translate('input.gender.value.female')}
                  </option>
                </InputSelect>
                <InputDate
                  labelFor="birthday"
                  labelContent={translate('input.birthday.label')}
                  id="birthday"
                  name="birthday"
                  disabled={isUpdateUserRequest}
                  {...formik.getFieldProps('birthday')}
                  isValid={formik.touched.birthday && !formik.errors.birthday}
                  isError={
                    formik.touched.birthday
                    && (formik.values.birthday.includes('d')
                      || formik.values.birthday.includes('m')
                      || formik.values.birthday.includes('y'))
                  }
                  msgError={translate('input.birthday.error.invalid')}
                  onChange={(e) => {
                    formik.setFieldValue('birthday', e);
                    formik.setTouched({ ...formik.touched, birthday: true });
                  }}
                />
                <ButtonCustom
                  disabled={isUpdateUserRequest}
                  isLoading={isUpdateUserRequest}
                  className="mt-4 mx-auto"
                >
                  {translate('button.save.title')}
                </ButtonCustom>
              </div>
            </Form>
          </div>
          <div className="mb-4" />
          <ContactButton />
          <div className="mb-4" />
          <FooterInfo />
        </div>
      </div>
      {isUpdateUserSuccess && (
        <ToastSuccess
          show
          content={translate('information-page.update-information.success')}
        />
      )}
      {!isShowErrMsgUploadAvt && !isUploadAvatar && isUpdateUserFailure && (
        <ToastError
          show
          content={translate('information-page.update-information.failure')}
        />
      )}
      {isShowErrMsgUploadAvt && (
        <ToastError
          show
          content={translate('information-page.update-avt.failure2')}
        />
      )}
    </HelmetCustom>
  );
};
