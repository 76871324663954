import vnBlogDetailPageLangLib from './blog-detail-page';
import vnBlogsPageLangLib from './blogs-page';
import vnCartPageLangLib from './cart-page';
import vnForgotPasswordPageLangLib from './forgot-password-page';
import vnGiftDetailPageLangLib from './gift-detail-page';
import vnGiftsListPageLangLib from './gifts-list-page';
import vnHomePageLangLib from './home-page';
import vnLoginPageLangLib from './login-page';
import vnOrderDetailPageLangLib from './order-detail-page';
import vnOrdersListPageLangLib from './orders-list-page';
import vnProductTypePageLangLib from './product-type-page';
import vnProfilePageLangLib from './profile-page';
import vnRegisterPageLangLib from './register-page';
import vnRequiredChangePasswordPageLangLib from './required-change-password-page';
import vnVoucherDetailPageLangLib from './voucher-detail-page';
import vnVouchersListPageLangLib from './vouchers-list-page';
import vnNotificationsPageLangLib from './notifications-age';
import vnManagerCoinPageLangLib from './manager-coin-page';
import vnInformationPageLangLib from './information-page';
import vnAddressesManagerPageLangLib from './addresses-manager-page';

const vnPageLangLib = {
  ...vnBlogDetailPageLangLib,
  ...vnBlogsPageLangLib,
  ...vnCartPageLangLib,
  ...vnForgotPasswordPageLangLib,
  ...vnGiftDetailPageLangLib,
  ...vnGiftsListPageLangLib,
  ...vnHomePageLangLib,
  ...vnLoginPageLangLib,
  ...vnOrderDetailPageLangLib,
  ...vnOrdersListPageLangLib,
  ...vnProductTypePageLangLib,
  ...vnProfilePageLangLib,
  ...vnRegisterPageLangLib,
  ...vnRequiredChangePasswordPageLangLib,
  ...vnVoucherDetailPageLangLib,
  ...vnVouchersListPageLangLib,
  ...vnNotificationsPageLangLib,
  ...vnManagerCoinPageLangLib,
  ...vnInformationPageLangLib,
  ...vnAddressesManagerPageLangLib,
};

export default vnPageLangLib;
