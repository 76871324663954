/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

// import { getFCMToken } from '~/helpers/utils';
import { axiosMicro } from '~/services';
import { STORAGE_KEY } from '~/constants';
import * as Actions from './actions';

function* getProfile() {
  try {
    const response = yield call(() => axiosMicro.get('/profile'));
    localStorage.setItem(STORAGE_KEY.ID_LOGIN, response?.data?.data?.id);
    if (response?.status === 200) {
      yield put(Actions.getProfileSuccess(response.data));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getProfileFailure(messages));
    }
  }
}

function* getConfig() {
  try {
    const response = yield call(() => axiosMicro.get('/configs'));
    yield put(Actions.getConfigSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getConfigFailure(messages));
    }
  }
}

function* removeUser({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/users/${payload}/remove`));
    if (response?.status === 204) {
      yield put(Actions.removeUserSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.removeUserFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getProfileRequest, getProfile);
  yield takeLatest(Actions.getConfigRequest, getConfig);
  yield takeLatest(Actions.removeUserRequest, removeUser);
}
