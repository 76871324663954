import { memo, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';

import { GiftItem } from './GiftItem';

export const GiftsList = memo(() => {
  const { isGetAllGiftsRequest, isGetAllGiftsSuccess, getAllGiftsResponse } = useSelector((store) => store.giftVoucher);

  const [giftsList, setGiftsList] = useState([]);

  useEffect(() => {
    if (isGetAllGiftsSuccess) {
      let giftsListTmp = [];
      if (getAllGiftsResponse.data?.length > 0) {
        giftsListTmp = [...getAllGiftsResponse.data];
      }
      setGiftsList(giftsListTmp);
    }
  }, [isGetAllGiftsSuccess, getAllGiftsResponse]);

  return (
    <section className="gifts-list--wrapper">
      <div className="gifts-list--inner d-flex flex-wrap">
        {isGetAllGiftsRequest
          ? [...new Array(4)].map((_, index) => (
            <div key={index} className="gift-item--wrapper w-50 mb-4">
              <Skeleton
                count={1}
                width="100%"
                style={{ lineHeight: 'unset', aspectRatio: '1/1' }}
              />
              <Skeleton
                count={1}
                width="100%"
                height="20px"
                style={{ lineHeight: 'unset', marginTop: '8px' }}
              />
            </div>
          ))
          : giftsList.map((item, index) => (
            <GiftItem key={index} data={item} />
          ))}
      </div>
    </section>
  );
});
