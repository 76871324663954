import BannerGiftsListUrl from '~/assets/images/banner/banner-1.avif';
import { Banner, FooterInfo } from '~/components/common';
import { HeaderChildWithCoin } from '~/components/common/header/HeaderChildWithCoin';
import { HelmetCustom } from '~/components/helmetCustom';
import { translate } from '~/helpers/utils';
import { GiftsList } from './components';

export const UserGiftsList = () => (
  <HelmetCustom title={translate('helmet.title.gifts-list')}>
    <div
      id="gifts-list-page"
      className="gifts-list-page--wrapper h-screen pt-4 d-flex flex-column"
    >
      <HeaderChildWithCoin
        title={translate('gifts-list-page.title')}
      />
      <div className="gifts-list-page--inner flex-grow-1 d-flex flex-column scroll-y">
        <Banner
          src={BannerGiftsListUrl}
          alt="Meko-Banner"
          classNameInner="mb-4"
        />
        <div className="gifts-list--main flex-grow-1">
          <GiftsList />
        </div>
        <FooterInfo />
      </div>
    </div>
  </HelmetCustom>
);
