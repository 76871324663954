import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';

import { NoData } from '~/components/common';
import { translate } from '~/helpers/utils';
import { NotificationItem } from './NotificationItem';

export const NotificationsList = () => {
  const {
    isGetAllNotificationsRequest,
    isGetAllNotificationsSuccess,
    getAllNotificationsResponse,
  } = useSelector((store) => store.notification);

  const [notificationsList, setNotificationsList] = useState([]);

  useEffect(() => {
    if (isGetAllNotificationsSuccess) {
      let notificationsListTmp = [];
      if (getAllNotificationsResponse.data?.length > 0) {
        notificationsListTmp = [...getAllNotificationsResponse.data];
      }
      setNotificationsList(notificationsListTmp);
    }
  }, [isGetAllNotificationsSuccess, getAllNotificationsResponse]);

  return (
    <div className="notifications-list--wrapper d-flex flex-column">
      {isGetAllNotificationsRequest ? (
        [...new Array(4)].map((_, index) => (
          <div className="notification-item--wrapper w-100" key={index}>
            <div
              className="notification-item--link flex-start-start gap-3 mk-text-hover-black"
              to="#"
            >
              <Skeleton
                count={1}
                width="80px"
                height="80px"
                style={{ lineHeight: 'unset', aspectRatio: '1/1' }}
              />
              <div className="w-100">
                <Skeleton
                  count={1}
                  width="100%"
                  height="20px"
                  style={{ lineHeight: 'unset', aspectRatio: '1/1' }}
                />
                <Skeleton
                  count={1}
                  width="100%"
                  height="12px"
                  style={{
                    lineHeight: 'unset',
                    aspectRatio: '1/1',
                    marginTop: '8px',
                  }}
                />
                <Skeleton
                  count={1}
                  width="100%"
                  height="12px"
                  style={{
                    lineHeight: 'unset',
                    aspectRatio: '1/1',
                    marginTop: '5px',
                  }}
                />
                <Skeleton
                  count={1}
                  width="100%"
                  height="12px"
                  style={{
                    lineHeight: 'unset',
                    aspectRatio: '1/1',
                    marginTop: '5px',
                  }}
                />
              </div>
            </div>
          </div>
        ))
      ) : notificationsList.length > 0 ? (
        notificationsList.map((item, index) => (
          <NotificationItem key={index} data={item} />
        ))
      ) : (
        <NoData title={translate('notifications-page.no-data.title')} />
      )}
    </div>
  );
};
