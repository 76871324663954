import { memo } from 'react';
import { Spinner } from 'react-bootstrap';

/**
 * color: brand-blue, secondary-brand-blue, brand-orange, green, orange, red
 * variant: normal, primary, secondary, line, link, outline-secondary, disabled
 * size: l, m, s
 */

export const ButtonCustom = memo(
  ({
    color = 'brand-blue',
    variant = 'primary',
    size = 'm',
    disabled = false,
    rounded = true,
    isLoading = false,
    className = '',
    children,
    ...rest
  }) => {
    const COLOR = `btn-custom__color--${color || 'brand-blue'}`;
    const TYPE = `btn-custom__variant--${variant || 'primary'}`;
    const SIZE = `btn-custom__size--${size || 'm'}`;
    const ROUNDED = rounded ? 'btn-custom__rounded' : '';
    const DISABLED = disabled ? 'btn-custom__disabled' : '';

    return (
      // eslint-disable-next-line react/button-has-type
      <button
        className={`btn-custom ${COLOR} ${TYPE} ${SIZE} ${ROUNDED} ${DISABLED} ${className}`}
        disabled={disabled}
        {...rest}
      >
        {isLoading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          children || 'Button Content'
        )}
      </button>
    );
  },
);
