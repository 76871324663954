import { v4 as uuidv4 } from 'uuid';

import { routesUser } from '~/configs';
import {
  UserAddressesManager,
  UserBlogDetail, UserBlogsList, UserCart, UserGiftDetail, UserGiftsList, UserHomePage, UserInformation, UserManagerCoin, UserNotifications, UserOrderDetail, UserOrdersList, UserProductType, UserProfile, UserVoucherDetail, UserVouchersList,
} from '~/pages/User';

export const routerUser = [
  {
    id: `user-${uuidv4()}`,
    path: routesUser.addressesManager,
    component: UserAddressesManager,
  },
  {
    id: `user-${uuidv4()}`,
    path: routesUser.blogDetail,
    component: UserBlogDetail,
  },
  {
    id: `user-${uuidv4()}`,
    path: routesUser.blogs,
    component: UserBlogsList,
  },
  {
    id: `user-${uuidv4()}`,
    path: routesUser.cart,
    component: UserCart,
  },
  {
    id: `user-${uuidv4()}`,
    path: routesUser.giftDetail,
    component: UserGiftDetail,
  },
  {
    id: `user-${uuidv4()}`,
    path: routesUser.giftsList,
    component: UserGiftsList,
  },
  {
    id: `user-${uuidv4()}`,
    path: routesUser.home,
    component: UserHomePage,
  },
  {
    id: `user-${uuidv4()}`,
    path: routesUser.information,
    component: UserInformation,
  },
  {
    id: `user-${uuidv4()}`,
    path: routesUser.managerCoin,
    component: UserManagerCoin,
  },
  {
    id: `user-${uuidv4()}`,
    path: routesUser.notifications,
    component: UserNotifications,
  },
  {
    id: `user-${uuidv4()}`,
    path: routesUser.orderDetail,
    component: UserOrderDetail,
  },
  {
    id: `user-${uuidv4()}`,
    path: routesUser.ordersList,
    component: UserOrdersList,
  },
  {
    id: `user-${uuidv4()}`,
    path: routesUser.productType,
    component: UserProductType,
  },
  {
    id: `user-${uuidv4()}`,
    path: routesUser.profile,
    component: UserProfile,
  },
  {
    id: `user-${uuidv4()}`,
    path: routesUser.voucherDetail,
    component: UserVoucherDetail,
  },
  {
    id: `user-${uuidv4()}`,
    path: routesUser.vouchersList,
    component: UserVouchersList,
  },
];
