import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { List } from './List';

export const VoucherTab = () => {
  const {
    isGetAllMyGiftsAndVouchersRequest,
    isGetAllMyGiftsAndVouchersSuccess,
    getAllMyGiftsAndVouchersResponse,
  } = useSelector((store) => store.giftVoucher);

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [filterState] = useState(1);
  const [giftData, setGiftData] = useState([]);

  useEffect(() => {
    if (isFirstRender && isGetAllMyGiftsAndVouchersSuccess) {
      setGiftData(
        getAllMyGiftsAndVouchersResponse?.data?.filter(
          (item) => item.type === 'voucher',
        ) || [],
      );
      setIsFirstRender(false);
    }
  }, [isGetAllMyGiftsAndVouchersSuccess]);

  useEffect(() => {
    if (!isFirstRender) {
      let giftDataTmp = [];
      const giftDataClone = [...getAllMyGiftsAndVouchersResponse?.data?.filter(
        (item) => item.type === 'voucher',
      )];
      if (filterState === 1) {
        giftDataClone.map((item) => {
          if (item.apply === false) {
            giftDataTmp.push(item);
          }
        });
      } else if (filterState === 2) {
        giftDataClone.map((item) => {
          if (item.apply === true) {
            giftDataTmp.push(item);
          }
        });
      } else {
        giftDataTmp = [...giftDataClone];
      }
      setGiftData(giftDataTmp);
    }
  }, [isFirstRender, filterState]);

  return (
    <div className="gift-tab--wrapper">
      {/* <div className="nav-filter--wrapper flex-center-space mt-3">
        <p
          className={`nav-filter--item mb-0 mk-fz-14 mk-fw-500 cursor-pointer mk-text-hover-secondary-brand-blue${filterState === 1 ? ' mk-text-secondary-brand-blue' : ''}`}
          onClick={() => setFilterState(1)}
        >
          {translate('manager-coin-page.nav-4')}
        </p>
        <p
          className={`nav-filter--item mb-0 mk-fz-14 mk-fw-500 cursor-pointer mk-text-hover-secondary-brand-blue${filterState === 2 ? ' mk-text-secondary-brand-blue' : ''}`}
          onClick={() => setFilterState(2)}
        >
          {translate('manager-coin-page.nav-5')}
        </p>
        <p
          className={`nav-filter--item mb-0 mk-fz-14 mk-fw-500 cursor-pointer mk-text-hover-secondary-brand-blue${filterState === 3 ? ' mk-text-secondary-brand-blue' : ''}`}
          onClick={() => setFilterState(3)}
        >
          {translate('manager-coin-page.nav-6')}
        </p>
      </div> */}
      <div className="gift-tab--main">
        <List
          data={giftData}
          isLoading={isGetAllMyGiftsAndVouchersRequest}
          type="voucher"
        />
      </div>
    </div>
  );
};
