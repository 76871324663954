/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getOrdersRecent() {
  try {
    const response = yield call(() => axiosMicro.get('/orders', {
      params: {
        orderBy: 'id',
        sortedBy: 'desc',
        limit: 10,
      },
    }));
    yield put(Actions.getOrdersRecentSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getOrdersRecentFailure(messages));
    }
  }
}

function* getOrdersPending() {
  try {
    const response = yield call(() => axiosMicro.get('/orders', {
      params: {
        orderBy: 'id',
        sortedBy: 'desc',
        limit: 0,
        status: 'pending,new,waiting,printed,delivery',
      },
    }));
    yield put(Actions.getOrdersPendingSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getOrdersPendingFailure(messages));
    }
  }
}

function* getOrdersSuccess() {
  try {
    const response = yield call(() => axiosMicro.get('/orders', {
      params: {
        orderBy: 'id',
        sortedBy: 'desc',
        limit: 0,
        status: 'delivered,paid',
      },
    }));
    yield put(Actions.getOrdersSuccessSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getOrdersSuccessFailure(messages));
    }
  }
}

function* getOrdersCancel() {
  try {
    const response = yield call(() => axiosMicro.get('/orders', {
      params: {
        orderBy: 'id',
        sortedBy: 'desc',
        limit: 0,
        status: 'canceled,refunded',
      },
    }));
    yield put(Actions.getOrdersCancelSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getOrdersCancelFailure(messages));
    }
  }
}

function* getOrderDetail({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/orders', {
      params: {
        id: payload.id,
      },
    }));
    yield put(Actions.getOrderDetailSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getOrderDetailFailure(messages));
    }
  }
}

function* getAllOrders({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/orders', {
      params: {
        ...payload,
        orderBy: 'id',
        sortedBy: 'desc',
      },
    }));
    yield put(Actions.getAllOrdersSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllOrdersFailure(messages));
    }
  }
}

function* createOrder({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/orders', payload));
    yield put(Actions.createOrderSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createOrderFailure(messages));
    }
  }
}

function* cancelOrder({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch('/orders/cancel', payload));
    yield put(Actions.cancelOrderSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.cancelOrderFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getOrdersRecentRequest, getOrdersRecent);
  yield takeLatest(Actions.getOrdersPendingRequest, getOrdersPending);
  yield takeLatest(Actions.getOrdersSuccessRequest, getOrdersSuccess);
  yield takeLatest(Actions.getOrdersCancelRequest, getOrdersCancel);
  yield takeLatest(Actions.getOrderDetailRequest, getOrderDetail);
  yield takeLatest(Actions.getAllOrdersRequest, getAllOrders);
  yield takeLatest(Actions.createOrderRequest, createOrder);
  yield takeLatest(Actions.cancelOrderRequest, cancelOrder);
}
