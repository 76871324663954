/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllNotifications({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/notifications', {
      params: {
        orderBy: 'id',
        sortedBy: 'desc',
        ...payload,
      },
    }));
    yield put(Actions.getAllNotificationsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllNotificationsFailure(messages));
    }
  }
}

function* readNotification({ payload }) {
  try {
    yield call(() => axiosMicro.patch('/notifications/read', payload));
    yield put(Actions.readNotificationSuccess(payload));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.readNotificationFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllNotificationsRequest, getAllNotifications);
  yield takeLatest(Actions.readNotificationRequest, readNotification);
}
