import { memo } from 'react';
import { ReactComponent as NoDataSvg } from '~/assets/images/common/no_data.svg';

export const NoData = memo(({ title }) => (
  <div className="flex-center-center flex-column mt-2">
    <NoDataSvg style={{ width: '30%', height: 'auto' }} />
    <p className="mk-text-center mt-4 mk-fz-18 mk-fw-600 mk-text-uppercase mk-text-secondary-brand-blue">
      {title}
    </p>
  </div>
));
