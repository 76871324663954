// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  isCheckUserExistsRequest: false,
  isCheckUserExistsSuccess: false,
  isCheckUserExistsFailure: false,
  checkUserExistsResponse: {},
  //
  isLoginRequest: false,
  isLoginSuccess: false,
  isLoginFailure: false,
  loginResponse: {},
  //
  isRegisterRequest: false,
  isRegisterSuccess: false,
  isRegisterFailure: false,
  registerResponse: {},
  //
  isUpdateUserRequest: false,
  isUpdateUserSuccess: false,
  isUpdateUserFailure: false,
  updateUserResponse: {},
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Check User Exists
    [Actions.checkUserExistsRequest]: (state) => ({
      ...state,
      isCheckUserExistsRequest: true,
      isCheckUserExistsSuccess: false,
      isCheckUserExistsFailure: false,
    }),
    [Actions.checkUserExistsSuccess]: (state, { payload }) => ({
      ...state,
      isCheckUserExistsRequest: false,
      isCheckUserExistsSuccess: true,
      isCheckUserExistsFailure: false,
      checkUserExistsResponse: payload,
    }),
    [Actions.checkUserExistsFailure]: (state, { payload }) => ({
      ...state,
      isCheckUserExistsRequest: false,
      isCheckUserExistsSuccess: false,
      isCheckUserExistsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Login
    [Actions.loginRequest]: (state) => ({
      ...state,
      isLoginRequest: true,
      isLoginSuccess: false,
      isLoginFailure: false,
    }),
    [Actions.loginSuccess]: (state, { payload }) => ({
      ...state,
      isLoginRequest: false,
      isLoginSuccess: true,
      isLoginFailure: false,
      loginResponse: payload,
    }),
    [Actions.loginFailure]: (state, { payload }) => ({
      ...state,
      isLoginRequest: false,
      isLoginSuccess: false,
      isLoginFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Register
    [Actions.registerRequest]: (state) => ({
      ...state,
      isRegisterRequest: true,
      isRegisterSuccess: false,
      isRegisterFailure: false,
    }),
    [Actions.registerSuccess]: (state, { payload }) => ({
      ...state,
      isRegisterRequest: false,
      isRegisterSuccess: true,
      isRegisterFailure: false,
      registerResponse: payload,
    }),
    [Actions.registerFailure]: (state, { payload }) => ({
      ...state,
      isRegisterRequest: false,
      isRegisterSuccess: false,
      isRegisterFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Update User
    [Actions.updateUserRequest]: (state) => ({
      ...state,
      isUpdateUserRequest: true,
      isUpdateUserSuccess: false,
      isUpdateUserFailure: false,
    }),
    [Actions.updateUserSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateUserRequest: false,
      isUpdateUserSuccess: true,
      isUpdateUserFailure: false,
      updateUserResponse: payload,
    }),
    [Actions.updateUserFailure]: (state, { payload }) => ({
      ...state,
      isUpdateUserRequest: false,
      isUpdateUserSuccess: false,
      isUpdateUserFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateUserState]: (state) => ({
      ...state,
      isUpdateUserRequest: false,
      isUpdateUserSuccess: false,
      isUpdateUserFailure: false,
      updateUserResponse: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetAuthState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
