import { HeaderChildWithoutCoin } from '~/components/common';
import { HelmetCustom } from '~/components/helmetCustom';
import { translate } from '~/helpers/utils';
import { NotificationsList } from './components';

export const UserNotifications = () => (
  <HelmetCustom title={translate('helmet.title.notifications')}>
    <div
      id="notifications-page"
      className="notifications-page--wrapper h-screen pt-4 d-flex flex-column"
    >
      <HeaderChildWithoutCoin
        title={translate('notifications-page.title')}
      />
      <div className="notifications-page--inner flex-grow-1 d-flex flex-column">
        <NotificationsList />
      </div>
    </div>
  </HelmetCustom>
);
