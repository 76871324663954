import { memo, useEffect, useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ButtonCustom, InputText } from '~/components/form';
import { routesAuth, routesUser } from '~/configs';
import { STORAGE_KEY } from '~/constants';
import { useDebounce } from '~/helpers/hooks';
import { parseCurrency, translate } from '~/helpers/utils';
import {
  addProductToCartRequest,
  closeModalAddProductRequest,
} from '~/redux/modal/actions';
import {
  getProductPricesRequest,
  resetGetProductPricesState,
  resetGetProductsListPricesState,
} from '~/redux/product/actions';
import { resetFeeState } from '~/redux/shipping/actions';
import { CSChevronDownNavigational } from '../iconography/Navigational';

export const ModalAddProduct = memo(() => {
  const { profileResponse } = useSelector((store) => store.user);
  const { dataSelectedModalAddProduct, isCheckEditModalAddProduct, openFrom } = useSelector((store) => store.modal);
  const {
    isGetProductPricesRequest,
    isGetProductPricesSuccess,
    productPricesResponse,
  } = useSelector((store) => store.product);
  const dispatch = useDispatch();

  const [cartState] = useState(
    localStorage.getItem(STORAGE_KEY.CART_STATE_TMP)
      ? JSON.parse(localStorage.getItem(STORAGE_KEY.CART_STATE_TMP))
      : [],
  );
  const [isCallApiGetPrice, setIsCallApiGetPrice] = useState(false);
  const [packNumber, setPackNumber] = useState('');
  const [price, setPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [inputType, setInputType] = useState('quantity'); // pack, quantity
  const [isShowOptionSection, setIsShowOptionSection] = useState(false);
  const [optionList, setOptionList] = useState([]);

  const packNumberDebounced = useDebounce(packNumber, 300);

  useEffect(() => {
    if (packNumberDebounced && parseInt(packNumberDebounced) > 0) {
      setIsCallApiGetPrice(true);
    } else {
      setPrice(0);
      setTotal(0);
    }
  }, [packNumberDebounced]);

  useEffect(() => {
    if (cartState.length > 0) {
      const productChecked = cartState.filter(
        (item) => item.id === dataSelectedModalAddProduct.id,
      );
      if (productChecked.length > 0) {
        setPackNumber(productChecked[0].packNumber);
        setInputType(productChecked[0].type);
      }
    }
  }, [cartState]);

  useEffect(() => {
    if (isCallApiGetPrice && profileResponse?.data) {
      dispatch(
        getProductPricesRequest({
          ids: parseInt(dataSelectedModalAddProduct.id),
          numbers:
            inputType === 'pack'
              ? (
                parseInt(packNumber)
                * parseInt(dataSelectedModalAddProduct.bag)
              ).toString()
              : parseInt(packNumber).toString(),
        }),
      );
      setIsCallApiGetPrice(false);
    }
  }, [isCallApiGetPrice, profileResponse]);

  useEffect(() => {
    if (isGetProductPricesSuccess) {
      setTotal(productPricesResponse.data.total);
      setPrice(productPricesResponse.data.products[0].price);
    }
  }, [isGetProductPricesSuccess]);

  const handleCloseModalAddProduct = () => {
    dispatch(closeModalAddProductRequest());
    dispatch(resetGetProductPricesState());
  };

  const handleAddProductToCart = (type) => {
    if (
      inputType === 'quantity'
      && parseInt(packNumberDebounced) % dataSelectedModalAddProduct.bag !== 0
    ) {
      setIsShowOptionSection(true);
      setOptionList([
        parseInt(packNumberDebounced)
        - (parseInt(packNumberDebounced) % dataSelectedModalAddProduct.bag),
        parseInt(packNumberDebounced)
        - (parseInt(packNumberDebounced) % dataSelectedModalAddProduct.bag)
        + dataSelectedModalAddProduct.bag,
      ]);
    } else {
      let cartStateTmp = [];
      if (type === 'add') {
        if (cartState) {
          cartStateTmp = [...cartState];
          const productChecked = cartState.findIndex(
            (item) => item.id === dataSelectedModalAddProduct.id,
          );
          if (productChecked !== -1) {
            cartStateTmp[productChecked].packNumber = packNumber;
            cartStateTmp[productChecked].type = inputType;
          } else {
            cartStateTmp.push({
              id: dataSelectedModalAddProduct.id,
              packNumber,
              type: inputType,
            });
          }
        } else {
          cartStateTmp.push({
            id: dataSelectedModalAddProduct.id,
            packNumber,
            type: inputType,
          });
        }
      } else {
        cartStateTmp = [...cartState];
        const productChecked = cartState.findIndex(
          (item) => item.id === dataSelectedModalAddProduct.id,
        );
        if (parseInt(packNumber) > 0) {
          cartStateTmp[productChecked].packNumber = packNumber;
          cartStateTmp[productChecked].type = inputType;
        } else {
          cartStateTmp.splice(productChecked, 1);
        }
      }
      if (openFrom === routesUser.cart || openFrom === 'trending-product') {
        localStorage.setItem(
          STORAGE_KEY.CART_STATE,
          JSON.stringify(cartStateTmp),
        );
      }
      localStorage.setItem(
        STORAGE_KEY.CART_STATE_TMP,
        JSON.stringify(cartStateTmp),
      );
      dispatch(addProductToCartRequest());
      dispatch(resetGetProductsListPricesState());
      dispatch(resetFeeState());
      handleCloseModalAddProduct();
    }
  };

  return (
    <>
      <Modal
        show
        onHide={handleCloseModalAddProduct}
        backdrop="static"
        keyboard={false}
        className="modal-add-product--wrapper"
      >
        <Modal.Body>
          <h3 className="mb-4 mk-fz-16 mk-text-brand-blue mk-fw-500">
            {dataSelectedModalAddProduct.name} (
            {dataSelectedModalAddProduct.bag} {dataSelectedModalAddProduct.unit}
            /{translate('modal-add-product.unit')})
          </h3>
          {
            profileResponse?.data ? (
              <>
                <div className={`flex-center-center input-number-custom--wrapper${inputType === 'pack' ? ' mb-4' : ''}`}>
                  <InputText
                    type="number"
                    min={0}
                    className="w-100"
                    value={packNumber}
                    onChange={(e) => setPackNumber(e.target.value)}
                    placeholder={translate(
                      inputType === 'pack'
                        ? 'input.product-pack.placeholder'
                        : dataSelectedModalAddProduct.name.includes('Bột')
                          ? 'input.product-pack.placeholder3'
                          : 'input.product-pack.placeholder2',
                    )}
                  />
                  <Dropdown className="dropdown-input-type">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <span className="mk-text-subtle-black flex-center-center cursor-pointer mk-text-hover-secondary-brand-blue hover-fill-secondary-brand-blue px-2">
                        {inputType === 'pack'
                          ? translate('modal-add-product.input-type.pack')
                          : translate(
                            dataSelectedModalAddProduct.name.includes('Bột')
                              ? 'modal-add-product.input-type.quantity-2'
                              : 'modal-add-product.input-type.quantity-1',
                          )}{' '}
                        <CSChevronDownNavigational
                          className="fill-subtle-black stroke-white"
                          style={{ width: '25px', height: '25px' }}
                        />
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          setInputType('pack');
                          setPackNumber('');
                        }}
                      >
                        {translate('modal-add-product.input-type.pack')}
                      </Dropdown.Item>
                      {dataSelectedModalAddProduct.name.includes('Bột') ? (
                        <Dropdown.Item
                          onClick={() => {
                            setInputType('quantity');
                            setPackNumber('');
                          }}
                        >
                          {translate('modal-add-product.input-type.quantity-2')}
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item
                          onClick={() => {
                            setInputType('quantity');
                            setPackNumber('');
                          }}
                        >
                          {translate('modal-add-product.input-type.quantity-1')}
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                {inputType !== 'pack' && (
                  <p className="mk-fz-14 mk-fw-400 mt-1">
                    {translate('modal-add-product.description-1')}{' '}
                    <span className="mk-text-lowercase">
                      {translate(
                        dataSelectedModalAddProduct.name.includes('Bột')
                          ? 'modal-add-product.input-type.quantity-2'
                          : 'modal-add-product.input-type.quantity-1',
                      )}
                    </span>
                    {' '}{translate('modal-add-product.description-2')}{' '}<span className="mk-fw-500">{dataSelectedModalAddProduct.bag}</span>.
                  </p>
                )}
                <div className="mk-fz-12 mk-fw-500 mk-text-secondary-brand-blue mb-1">
                  {translate('modal-add-product.title')}
                </div>
                <div>
                  {packNumberDebounced && parseInt(packNumberDebounced) > 0 ? (
                    <>
                      <div className="mk-fz-14 mk-fw-500 mb-1">
                        {inputType === 'pack' ? (
                          <>
                            <div>
                              {dataSelectedModalAddProduct.bag}{' '}
                              {dataSelectedModalAddProduct.unit}/
                              {translate('modal-add-product.unit')} x{' '}
                              {parseInt(packNumberDebounced)}{' '}
                              {translate('modal-add-product.unit')} ={' '}
                              {parseInt(packNumberDebounced)
                                * parseInt(dataSelectedModalAddProduct.bag)}{' '}
                              {dataSelectedModalAddProduct.unit}
                            </div>
                            <div>
                              1 {dataSelectedModalAddProduct.unit} ={' '}
                              {isGetProductPricesRequest ? (
                                <Skeleton
                                  count={1}
                                  width="100px"
                                  style={{ lineHeight: 'unset' }}
                                />
                              ) : (
                                profileResponse?.data ? parseCurrency(price) : 'Vui lòng đăng nhập để xem giá'
                              )}
                            </div>
                          </>
                        ) : (
                          <div>
                            {parseInt(packNumberDebounced)}{' '}
                            {dataSelectedModalAddProduct.unit} x{' '}
                            {profileResponse?.data ? parseCurrency(price) : 'Vui lòng đăng nhập để xem giá'}
                          </div>
                        )}
                      </div>
                      <div className="mk-fz-14 mk-fw-500 mk-text-secondary-brand-blue mb-0">
                        {translate('modal-add-product.total.title')}{' '}
                        <span className="mk-fw-600">
                          {isGetProductPricesRequest ? (
                            <Skeleton
                              count={1}
                              width="100px"
                              style={{ lineHeight: 'unset' }}
                            />
                          ) : (
                            parseCurrency(total)
                          )}
                        </span>
                      </div>
                    </>
                  ) : (
                    <div className="mk-text-secondary-black mk-fw-300 mk-fz-14 mb-0">
                      ---
                    </div>
                  )}
                </div>
                <div className="mb-0 mt-3 mk-fz-12 mk-text-subtle-black mk-fs-i mk-fw-400">
                  {translate(dataSelectedModalAddProduct.name.includes('Bột') ? 'modal-add-product.note3' : 'modal-add-product.note2')}
                </div>
              </>
            ) : (
              <Link to={routesAuth.login} className="">Vui lòng đăng nhập để xem chi tiết</Link>
            )
          }
          <div className="flex-center-center gap-2 mt-4">
            {isCheckEditModalAddProduct && profileResponse?.data ? (
              <ButtonCustom
                disabled={isGetProductPricesRequest}
                isLoading={isGetProductPricesRequest}
                className="w-100"
                color="brand-orange"
                onClick={() => handleAddProductToCart('edit')}
              >
                {translate('button.update.title')}
              </ButtonCustom>
            ) : !isCheckEditModalAddProduct && profileResponse?.data && (
              <ButtonCustom
                disabled={
                  !(packNumberDebounced && parseInt(packNumberDebounced) > 0)
                  || isGetProductPricesRequest
                }
                isLoading={isGetProductPricesRequest}
                className="w-100"
                color="brand-orange"
                onClick={() => handleAddProductToCart('add')}
              >
                {translate('button.add.title')}
              </ButtonCustom>
            )}
            <ButtonCustom
              disabled={isGetProductPricesRequest}
              className="w-100"
              variant="outline-secondary"
              color="red"
              onClick={handleCloseModalAddProduct}
            >
              {translate('button.cancel.title')}
            </ButtonCustom>
          </div>
        </Modal.Body>
      </Modal>
      {isShowOptionSection && (
        <Modal
          show
          backdrop="static"
          keyboard={false}
          className="modal-add-product--wrapper modal-option"
        >
          <Modal.Body>
            <h3 className="mk-fz-16 mk-fw-500 mk-text-brand-blue mk-text-center">
              {translate('modal-add-product.title-1')}
            </h3>
            <div className="flex-center-center gap-3 mt-4">
              {
                optionList[0] > 0 && (
                  <ButtonCustom
                    className="w-50"
                    size="s"
                    variant="line"
                    onClick={() => {
                      setPackNumber(optionList[0]);
                      setIsShowOptionSection(false);
                      setOptionList([]);
                    }}
                  >
                    {optionList[0]}
                  </ButtonCustom>
                )
              }
              <ButtonCustom
                className="w-50"
                size="s"
                variant="line"
                onClick={() => {
                  setPackNumber(optionList[1]);
                  setIsShowOptionSection(false);
                  setOptionList([]);
                }}
              >
                {optionList[1]}
              </ButtonCustom>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
});
