import { memo, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { useSelector } from 'react-redux';
import { BlogItem } from './BlogItem';

export const BlogsList = memo(({ filter = [] }) => {
  const { isGetAllBlogsRequest, isGetAllBlogsSuccess, getAllBlogsResponse } = useSelector((store) => store.blog);

  const [blogsList, setBlogsList] = useState([]);

  useEffect(() => {
    if (isGetAllBlogsSuccess) {
      let blogsListTmp = [];
      if (getAllBlogsResponse.data?.length > 0) {
        if (filter?.length > 0) {
          blogsListTmp = [...getAllBlogsResponse.data].filter(
            (item) => !filter.includes(item.id),
          );
        } else {
          blogsListTmp = [...getAllBlogsResponse.data];
        }
      }
      setBlogsList(blogsListTmp);
    }
  }, [isGetAllBlogsSuccess, filter]);

  return (
    <section className="blogs-list--wrapper">
      <div className="blogs-list--inner d-flex flex-wrap">
        {isGetAllBlogsRequest
          ? [...new Array(4)].map((_, index) => (
            <div key={index} className="blog-item--wrapper w-50 mb-4">
              <Skeleton
                count={1}
                width="100%"
                style={{ lineHeight: 'unset', aspectRatio: '1/1' }}
              />
              <Skeleton
                count={1}
                width="100%"
                height="20px"
                style={{ lineHeight: 'unset', marginTop: '8px' }}
              />
            </div>
          ))
          : blogsList.map((item, index) => (
            <BlogItem key={index} data={item} />
          ))}
      </div>
    </section>
  );
});
