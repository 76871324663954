/* eslint-disable react/no-danger */
import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CancelOrderImgUrl from '~/assets/images/pages/order-detail/cancel-order.png';
import { ButtonCustom, InputTextArea } from '~/components/form';
import { routesUser } from '~/configs';
import { ToastError } from '~/helpers/funcs';
import { translate } from '~/helpers/utils';
import {
  cancelOrderRequest,
  resetCancelOrderState,
} from '~/redux/order/actions';

export const ModalCancelOrder = memo(
  ({ handleClose, orderDetail, productsList }) => {
    const {
      isCancelOrderRequest,
      isCancelOrderSuccess,
      isCancelOrderFailure,
    } = useSelector((store) => store.order);
    const dispatch = useDispatch();
    const history = useHistory();

    const [seconds, setSeconds] = useState(5);
    const [isShowScreenCancelOrderSuccess, setIsShowScreenCancelOrderSuccess] = useState(false);
    const [reason, setReason] = useState('');
    const [isShowErrMsgReason, setIsShowErrMsgReason] = useState(false);

    useEffect(() => {
      let interval;
      if (isShowScreenCancelOrderSuccess) {
        interval = setInterval(() => {
          const newSeconds = seconds - 1;
          if (newSeconds >= 0) {
            setSeconds((currentSeconds) => currentSeconds - 1);
          } else {
            clearInterval(interval);
            history.push(routesUser.home);
          }
        }, 1000);
      }

      return () => clearInterval(interval);
    }, [isShowScreenCancelOrderSuccess, seconds]);

    const handleCancelOrder = () => {
      if (reason.trim().length === 0) {
        setIsShowErrMsgReason(true);
      } else {
        dispatch(cancelOrderRequest({
          id: orderDetail.id,
          reason,
        }));
      }
    };

    useEffect(() => {
      if (isCancelOrderSuccess) {
        setIsShowScreenCancelOrderSuccess(true);
        dispatch(resetCancelOrderState());
      }
    }, [isCancelOrderSuccess]);

    return (
      <>
        <Modal
          show
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          className="modal-order-detail modal-cancel-order--wrapper"
        >
          <Modal.Body>
            <div className="modal-cancel-order--inner pb-4">
              <div className="image-wrapper">
                <img
                  src={CancelOrderImgUrl}
                  alt="cancel-order"
                  style={{ width: '180px', height: 'auto' }}
                  className="m-auto"
                />
              </div>
              {isShowScreenCancelOrderSuccess ? (
                <>
                  <h4 className="mk-fz-18 mk-fw-500 mk-text-brand-orange mk-text-center mk-text-uppercase mb-3">
                    {translate('order-detail-page.model.cancel-order.title2')}
                  </h4>
                  <p className="mk-text-center mb-4 mk-fz-14 mk-fw-400">
                    {translate(
                      'order-detail-page.model.cancel-order.description4',
                    )}
                  </p>
                  <ButtonCustom
                    color="secondary-brand-blue"
                    className="m-auto"
                    onClick={() => history.push(routesUser.home)}
                  >
                    {translate(
                      'order-detail-page.model.cancel-order.btn-home',
                    ).replace('$x', seconds)}
                  </ButtonCustom>
                </>
              ) : (
                <>
                  <h4 className="mk-fz-18 mk-fw-500 mk-text-brand-orange mk-text-center mk-text-uppercase mb-3">
                    {translate('order-detail-page.model.cancel-order.title1')}
                  </h4>
                  <p className="mb-0 mk-fz-14 mk-fw-400">
                    {translate(
                      'order-detail-page.model.cancel-order.description1',
                    )
                      .replace(
                        '$x',
                        orderDetail?.code || orderDetail?.detail?.code || '---',
                      )
                      .replace(
                        '$y',
                        moment(orderDetail?.created_at).format('DD/MM/YYYY'),
                      )}
                  </p>
                  <p className="mb-0 mk-fz-14 mk-fw-400">
                    {translate(
                      'order-detail-page.model.cancel-order.description2',
                    )}
                  </p>
                  {productsList.map((item, index) => (
                    <p key={index} className="mb-0 mk-fz-14 mk-fw-400">
                      - {item.name}: {item.quantity}
                    </p>
                  ))}
                  {/* <p
                    className="mb-4 mt-3 mk-fz-14 mk-fw-400"
                    dangerouslySetInnerHTML={{
                      __html: translate(
                        'order-detail-page.model.cancel-order.description3',
                      ).replace(
                        '$x',
                        `<span class="mk-text-brand-orange">-${parseInt(
                          (orderDetail?.point || 0) / 1.5,
                        )} ${translate(
                          'order-detail-page.model.cancel-order.description3-unit',
                        )}</span>`,
                      ),
                    }}
                  /> */}
                  <div className="mt-1">
                    <InputTextArea
                      labelFor="reason"
                      labelContent={translate('order-detail-page.model.cancel-order.label-reason')}
                      id="reason"
                      name="reason"
                      value={reason}
                      isRequired
                      isValid={isShowErrMsgReason && reason.trim().length > 0}
                      isInvalid={isShowErrMsgReason && reason.trim().length === 0}
                      isError={reason.trim().length === 0 && isShowErrMsgReason}
                      msgError={translate('order-detail-page.model.cancel-order.label-reason-error-msg')}
                      onChange={(e) => setReason(e.target.value)}
                      onBlur={() => setIsShowErrMsgReason(true)}
                    />
                  </div>
                  <div className="flex-center-space w-100 gap-3 mt-4">
                    <ButtonCustom
                      disabled={isCancelOrderRequest}
                      isLoading={isCancelOrderRequest}
                      color="red"
                      className="w-100"
                      onClick={handleCancelOrder}
                    >
                      {translate(
                        'order-detail-page.model.cancel-order.btn-confirm',
                      )}
                    </ButtonCustom>
                    <ButtonCustom
                      disabled={isCancelOrderRequest}
                      variant="outline-secondary"
                      color="red"
                      className="w-100"
                      onClick={handleClose}
                    >
                      {translate(
                        'order-detail-page.model.cancel-order.btn-cancel',
                      )}
                    </ButtonCustom>
                  </div>
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
        {isCancelOrderFailure && (
          <ToastError
            show
            content={translate(
              'order-detail-page.model.cancel-order.error-msg',
            )}
          />
        )}
      </>
    );
  },
);
