/* eslint-disable jsx-a11y/media-has-caption */
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DuckLogoUrl from '~/assets/images/logo/duck.png';
import LogoWithTextUrl from '~/assets/images/logo/logo-with-text.png';
import successSound from '~/assets/sounds/success-noti.mp3';
import { routesUser } from '~/configs';
import { translate } from '~/helpers/utils';

export const ChangePasswordRequireSuccessScreen = ({
  isShowChangePasswordRequireSuccessScreen,
}) => {
  const history = useHistory();
  const audioRef = useRef(null);

  const [seconds, setSeconds] = useState(5);
  const [isPlayAudio, setIsPlayAudio] = useState(false);

  useEffect(() => {
    let interval;
    if (isShowChangePasswordRequireSuccessScreen) {
      if (!isPlayAudio) {
        audioRef.current.play();
        setIsPlayAudio(true);
      }

      interval = setInterval(() => {
        const newSeconds = seconds - 1;
        if (newSeconds >= 0) {
          setSeconds((currentSeconds) => currentSeconds - 1);
        } else {
          clearInterval(interval);
          history.push(routesUser.home);
        }
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isShowChangePasswordRequireSuccessScreen, seconds]);

  return (
    <div
      className={`change-password-required-success-screen--wrapper${isShowChangePasswordRequireSuccessScreen ? ' show' : ''}`}
    >
      <div className="change-password-required-success-screen--inner">
        <div className="change-password-required-success-screen--header">
          <img src={LogoWithTextUrl} alt="Meko Trading" />
        </div>
        <div className="change-password-required-success-screen--main mk-text-center">
          <h2 className="mk-fz-20 mk-fw-700 mk-text-uppercase mk-text-brand">
            {translate('required-change-password-page.update-success.title')}
          </h2>
          <p
            className="mk-fz-16 mk-fw-500"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: translate(
                'required-change-password-page.update-success.description',
              ).replace(
                '$x',
                '<span class="mk-text-brand mk-fw-600">Nguyễn Văn Hà</span>',
              ),
            }}
          />
          <img src={DuckLogoUrl} alt="Meko Trading" className="m-auto" style={{ width: '200px' }} />
          <p className="mk-fz-14 mk-fw-600 mk-text-brand mk-fs-i mt-5">
            {translate(
              'required-change-password-page.auto-return-home.title',
            ).replace('$x', seconds)}
          </p>
        </div>
        <div className={`pyro${seconds < 4 ? ' hidden' : ''}`}>
          <div className="before" />
          <div className="after" />
        </div>
        <audio ref={audioRef} controls id="success-sound-noti" className="d-none">
          <source src={successSound} type="audio/mpeg" />
        </audio>
      </div>
    </div>
  );
};
