/* eslint-disable react/no-danger */
import moment from 'moment';
import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { routesUser } from '~/configs';
import { readNotificationRequest } from '~/redux/notification/actions';

export const NotificationItem = memo(({ data }) => {
  const dispatch = useDispatch();

  const history = useHistory();

  const handleReadNotification = () => {
    if (data.read === null) {
      dispatch(readNotificationRequest({
        id: data.id,
      }));
    }
    if (data?.type === 'order' && data?.data?.orderId) {
      history.push(routesUser.orderDetail.replace(':orderId', data.data.orderId));
    }
  };

  return (
    <div className={`notification-item--wrapper w-100${data?.read === null ? ' unread' : ''}`}>
      <div className="notification-item--link flex-start-start gap-3 mk-text-hover-black cursor-pointer" onClick={handleReadNotification}>
        <div className="noti-item--img" style={{ minWidth: '80px', width: '80px' }}>
          <img src={data?.imageCustom} alt="" className="img-square" style={{ borderRadius: '5px' }} />
        </div>
        <div className="noti-item--content">
          <h5 className={`noti-item--title mk-fz-16 mk-fw-500 mb-0${data?.data?.status === 'delivered' ? ' mk-text-green' : data?.data?.status === 'canceled' ? ' mk-text-red' : data?.data?.status === 'delivery' ? ' mk-text-blue' : ''}`}>{data?.title}</h5>
          <p
            className="noti-item--description mk-fz-14 mk-fw-400 mb-0"
            dangerouslySetInnerHTML={{
              __html: data?.data?.orderCode && data?.message.includes(data?.data?.orderCode) ? data.message.replace(data.data.orderCode, `<span class="mk-text-secondary-brand-blue mk-fw-500">${data.data.orderCode}</span>`) : data?.message,
            }}
          />
          {
            data?.time && (
              <p className="mk-fz-12 mk-text-subtle-black mb-0">{moment(data.time).format('DD/MM/YYYY')}</p>
            )
          }
        </div>
      </div>
    </div>
  );
});
