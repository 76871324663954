import { useFormik } from 'formik';
import { memo, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { ButtonCustom, InputPassword, InputText } from '~/components/form';
import { useDebounce } from '~/helpers/hooks';
import { translate } from '~/helpers/utils';
import { checkUserExistsRequest } from '~/redux/auth/actions';

export const RegisterFirstScreen = memo(({
  setIsShowFirstScreen,
  setDataFormRegister,
}) => {
  const {
    isCheckUserExistsRequest,
    isCheckUserExistsSuccess,
    checkUserExistsResponse,
  } = useSelector((store) => store.auth);
  const dispatch = useDispatch();

  const [
    isShowErrMsgDuplicatePhoneNumber,
    setIsShowErrMsgDuplicatePhoneNumber,
  ] = useState(false);

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      password: '',
      rePassword: '',
      referralCode: '', // optional
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .trim()
        .required(translate('input.phone-number.error.required'))
        .min(10, translate('input.phone-number.error.invalid'))
        .max(10, translate('input.phone-number.error.invalid')),
      password: Yup.string()
        .trim()
        .required(translate('input.password.error.required'))
        .min(5, translate('input.password.error.min'))
        .max(20, translate('input.password.error.max')),
      rePassword: Yup.string()
        .required(translate('input.re-password.error.required'))
        .oneOf(
          [Yup.ref('password')],
          translate('input.re-password.error.not-match'),
        ),
    }),
    onSubmit: (values) => {
      if (!isShowErrMsgDuplicatePhoneNumber) {
        setDataFormRegister((currentDataFormRegister) => ({
          ...currentDataFormRegister,
          ...values,
        }));
        setIsShowFirstScreen(false);
      }
    },
  });

  const phoneNumberDebounced = useDebounce(formik.values.phoneNumber, 700);

  useEffect(() => {
    if (phoneNumberDebounced.trim().length === 10) {
      dispatch(
        checkUserExistsRequest({ type: 'phone', phone: phoneNumberDebounced }),
      );
    }
  }, [phoneNumberDebounced]);

  useEffect(() => {
    if (isCheckUserExistsSuccess) {
      if (checkUserExistsResponse.data.id !== null) {
        setIsShowErrMsgDuplicatePhoneNumber(true);
      } else {
        setIsShowErrMsgDuplicatePhoneNumber(false);
      }
    }
  }, [isCheckUserExistsSuccess, checkUserExistsResponse]);

  return (
    <Form noValidate onSubmit={formik.handleSubmit}>
      <div className="register-page--first-screen">
        <h3 className="mb-3 mk-fz-16 mk-fw-500">
          {translate('register-page.first-screen.title')}
        </h3>
        <InputText
          id="phoneNumber"
          name="phoneNumber"
          type="number"
          placeholder={translate('input.phone-number.placeholder')}
          isRequired
          {...formik.getFieldProps('phoneNumber')}
          onChange={(e) => formik.setFieldValue('phoneNumber', e.target.value.toString())}
          isValid={formik.touched.phoneNumber && !formik.errors.phoneNumber}
          isInvalid={
            isShowErrMsgDuplicatePhoneNumber
            || (formik.touched.phoneNumber && formik.errors.phoneNumber)
          }
          isError={
            isShowErrMsgDuplicatePhoneNumber
            || (formik.touched.phoneNumber && formik.errors.phoneNumber)
          }
          msgError={
            isShowErrMsgDuplicatePhoneNumber
              ? translate('input.phone-number.error.duplicate')
              : formik.errors.phoneNumber
          }
          isLoading={isCheckUserExistsRequest}
        />
        <div className="mb-3" />
        <InputPassword
          id="password"
          name="password"
          placeholder={translate('input.password.placeholder')}
          isRequired
          {...formik.getFieldProps('password')}
          isValid={formik.touched.password && !formik.errors.password}
          isInvalid={formik.touched.password && formik.errors.password}
          isError={formik.touched.password && formik.errors.password}
          msgError={formik.errors.password}
        />
        <div className="mb-3" />
        <InputPassword
          id="rePassword"
          name="rePassword"
          placeholder={translate('input.re-password.placeholder')}
          isRequired
          {...formik.getFieldProps('rePassword')}
          isValid={formik.touched.rePassword && !formik.errors.rePassword}
          isInvalid={formik.touched.rePassword && formik.errors.rePassword}
          isError={formik.touched.rePassword && formik.errors.rePassword}
          msgError={formik.errors.rePassword}
        />
        <div className="mb-3" />
        <InputText
          id="referralCode"
          name="referralCode"
          placeholder={translate('input.referral-code-optional.placeholder')}
          {...formik.getFieldProps('referralCode')}
          isValid={formik.touched.referralCode && !formik.errors.referralCode}
          isInvalid={formik.touched.referralCode && formik.errors.referralCode}
          isError={formik.touched.referralCode && formik.errors.referralCode}
          msgError={formik.errors.referralCode}
        />
        <div className="mb-5" />
        <ButtonCustom
          disabled={
            isShowErrMsgDuplicatePhoneNumber || isCheckUserExistsRequest
          }
          type="submit"
          className="w-100"
        >
          {translate('button.next.title')}
        </ButtonCustom>
      </div>
    </Form>
  );
});
