import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route, useHistory, useLocation,
} from 'react-router-dom';

import { FooterAction, ModalAddProduct } from '~/components/common';
import { routesUser } from '~/configs';
import { MODAL_KEY, STORAGE_KEY } from '~/constants';
import { isObjectEmpty } from '~/helpers/utils';
import { LoadingPage } from '~/pages/Other';
import { getAllBlogsRequest } from '~/redux/blog/actions';
import {
  getAllGiftsRequest,
  getAllMyGiftsAndVouchersRequest,
  getAllVouchersRequest,
} from '~/redux/giftVoucher/actions';
import { getAllNotificationsRequest } from '~/redux/notification/actions';
import {
  getOrdersCancelRequest, getOrdersPendingRequest, getOrdersRecentRequest, getOrdersSuccessRequest,
} from '~/redux/order/actions';
import { getAllPointsRequest } from '~/redux/point/actions';
import { getRegionsRequest } from '~/redux/region/actions';
import {
  callApiDefaultRequest,
  callApiFirstTimeRequest,
  getConfigRequest,
  getProfileRequest,
} from '~/redux/user/actions';

export const UserLayout = (props) => {
  const {
    isGetProfileSuccess, isGetConfigSuccess, profileResponse, isCallApiFirstTime, isCallDefaultApi,
  } = useSelector((store) => store.user);
  const { dataSelectedModalAddProduct } = useSelector((store) => store.modal);
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const { pathname, hash } = location;
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowModalAddProduct, setIsShowModalAddProduct] = useState(false);

  const checkAuth = async () => {
    const isLogin = localStorage.getItem(STORAGE_KEY.IS_LOGIN);
    const accessToken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    if (isLogin && accessToken) {
      setIsAuth(true);
    }
  };

  const resetCartState = () => {
    if (isCallApiFirstTime) {
      const cartStateCurrent = localStorage.getItem(STORAGE_KEY.CART_STATE)
        ? JSON.parse(localStorage.getItem(STORAGE_KEY.CART_STATE))
        : [];
      localStorage.setItem(
        STORAGE_KEY.CART_STATE,
        JSON.stringify(cartStateCurrent),
      );
      localStorage.setItem(
        STORAGE_KEY.CART_STATE_TMP,
        JSON.stringify(cartStateCurrent),
      );
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    if (isObjectEmpty(profileResponse) && isAuth) {
      dispatch(getProfileRequest());
    }
    if (!isCallDefaultApi || isCallApiFirstTime) {
      dispatch(getConfigRequest());
      dispatch(getAllVouchersRequest({ limit: 0 }));
      dispatch(getAllGiftsRequest({ limit: 0 }));
      dispatch(getAllBlogsRequest({ limit: 0 }));
      resetCartState();
      dispatch(callApiFirstTimeRequest());
      dispatch(callApiDefaultRequest());
    }
  }, [profileResponse, isAuth]);

  useEffect(() => {
    if (isGetProfileSuccess && isGetConfigSuccess) {
      setIsLoading(false);
      if (isCallApiFirstTime) {
        dispatch(getOrdersRecentRequest());
        dispatch(getAllPointsRequest({ limit: 0 }));
        dispatch(getAllNotificationsRequest({ limit: 0 }));
        dispatch(getAllMyGiftsAndVouchersRequest({ limit: 0 }));
        dispatch(getOrdersSuccessRequest());
        dispatch(getOrdersCancelRequest());
        dispatch(getOrdersPendingRequest());
        dispatch(getRegionsRequest({ limit: 0 }));
      }
    }
  }, [isGetProfileSuccess, isGetConfigSuccess]);

  useEffect(() => {
    if (dataSelectedModalAddProduct) {
      history.push(MODAL_KEY.MODAL_ADD_PRODUCT);
      setIsShowModalAddProduct(true);
    } else if (hash.includes(MODAL_KEY.MODAL_ADD_PRODUCT)) {
      const { pathname, hash } = location;
      history.push(
        pathname.concat(hash).replace(MODAL_KEY.MODAL_ADD_PRODUCT, ''),
      );
      setIsShowModalAddProduct(false);
    } else {
      setIsShowModalAddProduct(false);
    }
  }, [dataSelectedModalAddProduct, hash]);

  const render = () => {
    if (isLoading) return <LoadingPage />;
    // if (!isAuth) return <Redirect to={routesAuth.login} />;

    return (
      <div className={`user-layout${(pathname.includes(routesUser.ordersList.concat('/')) || pathname.includes(routesUser.cart) || pathname.includes(routesUser.profile) || pathname.includes(routesUser.information) || pathname.includes(routesUser.addressesManager) || pathname.includes(routesUser.productType.replace(':productTypeId', '')) || pathname.includes(routesUser.vouchersList.concat('/')) || pathname.includes(routesUser.giftsList.concat('/'))) ? ' hide-footer-action' : ''}`}>
        <Route {...props} />
        {isShowModalAddProduct && dataSelectedModalAddProduct && (
          <ModalAddProduct />
        )}
        {
          (pathname.includes(routesUser.ordersList.concat('/')) || pathname.includes(routesUser.cart) || pathname.includes(routesUser.profile) || pathname.includes(routesUser.information) || pathname.includes(routesUser.addressesManager) || pathname.includes(routesUser.productType.replace(':productTypeId', '')) || pathname.includes(routesUser.vouchersList.concat('/')) || pathname.includes(routesUser.giftsList.concat('/'))) ? '' : (
            <FooterAction />
          )
        }
      </div>
    );
  };

  return render();
};
