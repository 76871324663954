const vnOrdersListPageLangLib = {
  'orders-list-page.title': 'Đơn Hàng',
  'orders-list-page.tab.nav.current': 'Hiện tại',
  'orders-list-page.tab.nav.success': 'Hoàn thành',
  'orders-list-page.tab.nav.cancel': 'Hủy đơn',
  'orders-list-page.order-item.code': 'Mã đơn hàng:',
  'orders-list-page.no-data.title': 'Chưa có đơn hàng',
};

export default vnOrdersListPageLangLib;
