import { vnLangLib } from '~/configs';
import { STORAGE_KEY } from '~/constants';

export const translate = (id) => {
  const currentLang = localStorage.getItem(STORAGE_KEY.CURRENT_LANGUAGE);
  let translateLang = '';

  if (currentLang === 'vn' && id) {
    translateLang = vnLangLib[id];
  } else {
    translateLang = vnLangLib[id];
  }

  return translateLang;
};
