import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrdersPendingRequest } from '~/redux/order/actions';
import { OrdersList } from './OrdersList';

export const OrdersListPending = () => {
  const {
    isGetOrdersPendingRequest,
    isGetOrdersPendingSuccess,
    getOrdersPendingResponse,
  } = useSelector((store) => store.order);
  const dispatch = useDispatch();

  const [ordersList, setOrdersList] = useState([]);

  useEffect(() => {
    if (!isGetOrdersPendingSuccess) {
      dispatch(getOrdersPendingRequest());
    }
  }, []);

  useEffect(() => {
    if (isGetOrdersPendingSuccess) {
      let ordersListTmp = [];
      if (getOrdersPendingResponse?.data.length > 0) {
        ordersListTmp = [...getOrdersPendingResponse.data];
      }
      setOrdersList(ordersListTmp);
    }
  }, [isGetOrdersPendingSuccess, getOrdersPendingResponse]);

  return (
    <div className="orders-list-pending--tab">
      <OrdersList
        type="current"
        data={ordersList}
        isLoading={isGetOrdersPendingRequest}
      />
    </div>
  );
};
