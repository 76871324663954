import { memo } from 'react';
import {
  Form, FormControl, InputGroup, Spinner,
} from 'react-bootstrap';

export const InputText = memo(
  ({
    labelFor, labelContent, isRequired, isError, msgError, isLoading, ...rest
  }) => (
    <div className={`input-custom input-custom-text${isLoading ? ' loading' : ''}`}>
      {labelContent && (
        <Form.Label htmlFor={labelFor}>
          {labelContent} {isRequired && <span className="is-required">*</span>}
        </Form.Label>
      )}
      <InputGroup>
        <FormControl {...rest} />
        {
          isLoading && (
            <span className="input-loading-icon">
              <Spinner animation="border" size="sm" variant="secondary" />
            </span>
          )
        }
      </InputGroup>
      {isError && <div className="form-error-msg">{msgError}</div>}
    </div>
  ),
);
