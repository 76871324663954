const vnProfilePageLangLib = {
  'profile-page.title': 'Cá nhân',
  'profile-page.nav.link.update-information': 'Cập nhật thông tin tài khoản',
  'profile-page.nav.link.addresses-manager': 'Địa chỉ của tôi',
  'profile-page.nav.link.exchange-coin': 'Quản lý điểm tích lũy',
  'profile-page.nav.link.order-manager': 'Quản lý đơn hàng',
  'profile-page.nav.link.tutorial': 'Hướng dẫn bảo quản',
  'profile-page.nav.link.policy': 'Điều khoản sử dụng',
  'profile-page.nav.link.logout': 'Đăng xuất',
  'profile-page.nav.link.remove-account': 'Xóa tài khoản của tôi',
  'profile-page.nav.link.remove-account.confirm': 'Bạn chắc chắn sẽ xóa tài khoản này?',
  'profile-page.coin-title': 'Trứng Tích lũy:',
  'profile-page.coin-sub-title': 'điểm',
  'profile-page.nav.link.remove-account.failure': 'Xóa tài khoản thất bại',
  'profile-page.nav.link.remove-account.success': 'Xóa tài khoản thành công',
};

export default vnProfilePageLangLib;
