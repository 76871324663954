// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  dataSelectedModalAddProduct: null,
  isCheckEditModalAddProduct: false,
  openFrom: null,
  isAddProductToCart: false,
};

const reducer = handleActions(
  {
    // #region : Modal Add Product
    [Actions.openModalAddProductRequest]: (state, { payload }) => ({
      ...state,
      dataSelectedModalAddProduct: payload.data,
      isCheckEditModalAddProduct: payload.type === 'edit',
      openFrom: payload.from || null,
    }),
    [Actions.closeModalAddProductRequest]: (state) => ({
      ...state,
      dataSelectedModalAddProduct: null,
      isCheckEditModalAddProduct: false,
      openFrom: null,
    }),
    [Actions.addProductToCartRequest]: (state) => ({
      ...state,
      isAddProductToCart: true,
    }),
    [Actions.resetAddProductToCartRequest]: (state) => ({
      ...state,
      isAddProductToCart: false,
    }),
    // #endregion
  },
  initialState,
);

export default reducer;
