/* eslint-disable import/no-extraneous-dependencies */
// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import ProductImg1Url from '~/assets/images/products/long-do.jpg';
import ProductImg2Url from '~/assets/images/products/bot-trung.jpg';
import ProductImg3Url from '~/assets/images/products/long-trang.png';
import ProductImg4Url from '~/assets/images/products/vo-trung.jpg';
import * as Actions from './actions';

const PRODUCT_IMAGE_LIST = [
  ProductImg1Url,
  ProductImg2Url,
  ProductImg3Url,
  ProductImg4Url,
];

const initialState = {
  //
  isGetAllNotificationsRequest: false,
  isGetAllNotificationsSuccess: false,
  isGetAllNotificationsFailure: false,
  getAllNotificationsResponse: {},
  //
  isReadNotificationRequest: false,
  isReadNotificationSuccess: false,
  isReadNotificationFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Notifications
    [Actions.getAllNotificationsRequest]: (state) => ({
      ...state,
      isGetAllNotificationsRequest: true,
      isGetAllNotificationsSuccess: false,
      isGetAllNotificationsFailure: false,
    }),
    [Actions.getAllNotificationsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllNotificationsRequest: false,
      isGetAllNotificationsSuccess: true,
      isGetAllNotificationsFailure: false,
      getAllNotificationsResponse: {
        ...payload,
        data: [...payload.data.map((item) => ({ ...item, imageCustom: PRODUCT_IMAGE_LIST[Math.floor(Math.random() * PRODUCT_IMAGE_LIST.length)] }))],
      },
    }),
    [Actions.getAllNotificationsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllNotificationsRequest: false,
      isGetAllNotificationsSuccess: false,
      isGetAllNotificationsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Read Notification
    [Actions.readNotificationRequest]: (state) => ({
      ...state,
      isReadNotificationRequest: true,
      isReadNotificationSuccess: false,
      isReadNotificationFailure: false,
    }),
    [Actions.readNotificationSuccess]: (state, { payload }) => ({
      ...state,
      isReadNotificationRequest: false,
      isReadNotificationSuccess: true,
      isReadNotificationFailure: false,
      getAllNotificationsResponse: {
        ...state.getAllNotificationsResponse,
        data: [
          ...state.getAllNotificationsResponse.data.map((item) => item.id === payload.id ? { ...item, read: true } : item),
        ],
      },
    }),
    [Actions.readNotificationFailure]: (state, { payload }) => ({
      ...state,
      isReadNotificationRequest: false,
      isReadNotificationSuccess: false,
      isReadNotificationFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetReadNotificationState]: (state, { payload }) => ({
      ...state,
      isReadNotificationRequest: false,
      isReadNotificationSuccess: false,
      isReadNotificationFailure: false,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Local
    [Actions.resetNotificationState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
