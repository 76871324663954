const vnHetmetLangLib = {
  'helmet.title.loading-page': 'Đang tải...',
  'helmet.title.login-page': 'Trang đăng nhập',
  'helmet.title.register-page': 'Trang đăng ký',
  'helmet.title.forgot-password': 'Trang quên mật khẩu',
  'helmet.title.homepage': 'Trang chủ',
  'helmet.title.orders-list': 'Đơn hàng',
  'helmet.title.order-detail': 'Chi tiết đơn hàng',
  'helmet.title.blogs': 'Danh sách bài viết',
  'helmet.title.gifts-list': 'Quà tặng',
  'helmet.title.gift-detail': 'Đổi quà tặng',
  'helmet.title.vouchers-list': 'Voucher',
  'helmet.title.voucher-detail': 'Đổi voucher',
  'helmet.title.profile': 'Cá nhân',
  'helmet.title.information': 'Cập nhập thông tin',
  'helmet.title.addresses-manager': 'Địa chỉ của tôi',
  'helmet.title.cart': 'Giỏ hàng',
  'helmet.title.notifications': 'Thông báo',
  'helmet.title.manager-coin': 'Quản lý trứng vàng',
};

export default vnHetmetLangLib;
