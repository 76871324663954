import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import LogoWithTextUrl from '~/assets/images/logo/logo-with-text.png';
import { CSArrowLeftNavigational } from '~/components/iconography/Navigational';
import { routesAuth } from '~/configs';
import { ToastError } from '~/helpers/funcs';
import { translate } from '~/helpers/utils';
import { resetAuthState } from '~/redux/auth/actions';
import { getRegionsRequest } from '~/redux/region/actions';
import { HelmetCustom } from '~/components/helmetCustom';
import { RegisterFirstScreen } from './RegisterFirstScreen';
import { RegisterSecondScreen } from './RegisterSecondScreen';
import { RegisterSuccessScreen } from './RegisterSuccessScreen';

export const RegisterPage = () => {
  const { isRegisterRequest, isRegisterSuccess, isRegisterFailure } = useSelector((store) => store.auth);
  const { isGetRegionsSuccess } = useSelector((store) => store.region);
  const dispatch = useDispatch();

  const [isShowFirstScreen, setIsShowFirstScreen] = useState(true);
  const [isShowRegisterSuccessScreen, setIsShowRegisterSuccessScreen] = useState(false);
  const [dataFormRegister, setDataFormRegister] = useState({});

  useEffect(() => {
    if (!isGetRegionsSuccess) {
      dispatch(getRegionsRequest({ limit: 0 }));
    }

    return () => {
      dispatch(resetAuthState());
    };
  }, []);

  useEffect(() => {
    if (isRegisterSuccess) {
      setIsShowRegisterSuccessScreen(true);
    }
  }, [isRegisterSuccess]);

  return (
    <HelmetCustom title={translate('helmet.title.register-page')}>
      <div id="register-page" className="register-page--wrapper">
        <RegisterSuccessScreen
          isShowRegisterSuccessScreen={isShowRegisterSuccessScreen}
          dataFormRegister={dataFormRegister}
        />
        <div
          className={`register-page--inner ${isShowFirstScreen ? 'show-first-screen' : 'show-second-screen'}${isShowRegisterSuccessScreen ? ' hidden' : ''}`}
        >
          <div className="register-page--header">
            <img src={LogoWithTextUrl} alt="Meko Trading" />
          </div>
          <div className="register-page--form">
            <div className="register-page--form__inner">
              <RegisterFirstScreen
                setIsShowFirstScreen={setIsShowFirstScreen}
                setDataFormRegister={setDataFormRegister}
              />
              <RegisterSecondScreen
                dataFormRegister={dataFormRegister}
                setDataFormRegister={setDataFormRegister}
              />
            </div>
          </div>
          <div className="register-page--footer">
            <p className="mk-text-center mk-fz-14 mk-fw-500 mb-0">
              {translate('register-page.dont-have-account.title')}{' '}
              <Link
                to={isRegisterRequest ? '#' : routesAuth.login}
                className={`mk-fw-600 mk-text-secondary-brand-blue mk-text-link mk-text-hover-brand-blue${isRegisterRequest ? ' disabled' : ''}`}
              >
                {translate('button.login.title')}
              </Link>
            </p>
          </div>
          <span
            onClick={
              isRegisterRequest ? null : () => setIsShowFirstScreen(true)
            }
            className={`back-link mk-fz-14 mk-fw-400 mk-text-subtle-black mk-text-link flex-center-center gap-2 hover-stroke-secondary-brand-blue mk-text-hover-secondary-brand-blue ${!isShowFirstScreen ? 'show' : 'disabled'}`}
          >
            <CSArrowLeftNavigational
              className="mk-fz-12 stroke-subtle-black"
              style={{ width: '18px', height: '18px' }}
            />
            {translate('button.back.title')}
          </span>
        </div>
      </div>
      {isRegisterFailure && (
        <ToastError
          show
          content={translate('register-page.register-failure.title')}
        />
      )}
    </HelmetCustom>
  );
};
