import { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { routesUser } from '~/configs';
import { STORAGE_KEY } from '~/constants';
import { LoadingPage } from '~/pages/Other';

export const AuthLayout = (props) => {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const checkAuth = async () => {
    const isLogin = localStorage.getItem(STORAGE_KEY.IS_LOGIN);
    const accessToken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    if (isLogin && accessToken) {
      setIsAuth(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    checkAuth();
  }, []);

  const render = () => {
    if (isLoading) return <LoadingPage />;
    if (isAuth) return <Redirect to={routesUser.home} />;

    return (
      <div className="auth-layout">
        <Route {...props} />
      </div>
    );
  };

  return render();
};
