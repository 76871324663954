/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/media-has-caption */
import moment from 'moment';
import {
  memo, useEffect, useRef, useState,
} from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import EggCoinUrl from '~/assets/images/common/egg-coin.png';
import ReorderOrderImgUrl from '~/assets/images/pages/order-detail/reorder-order.png';
import successSound from '~/assets/sounds/success-noti.mp3';
import { ButtonCustom, InputTextArea } from '~/components/form';
import { CSChevronDownNavigational } from '~/components/iconography/Navigational';
import { routesUser } from '~/configs';
import { ToastError } from '~/helpers/funcs';
import { parseCurrency, translate } from '~/helpers/utils';
import {
  createOrderRequest,
  resetCreateOrderState,
} from '~/redux/order/actions';
import { getFeeRequest, resetFeeState } from '~/redux/shipping/actions';

export const ModalReorderOrder = memo(
  ({ handleClose, orderDetail, productsList }) => {
    const { isGetFeeRequest, isGetFeeSuccess, feeResponse } = useSelector(
      (store) => store.shipping,
    );
    const { configResponse } = useSelector((store) => store.user);
    const {
      isGetAllMyGiftsAndVouchersRequest,
      isGetAllMyGiftsAndVouchersSuccess,
      getAllMyGiftsAndVouchersResponse,
    } = useSelector((store) => store.giftVoucher);
    const {
      isCreateOrderRequest,
      isCreateOrderSuccess,
      isCreateOrderFailure,
      createOrderResponse,
    } = useSelector((store) => store.order);
    const dispatch = useDispatch();

    const history = useHistory();
    const audioRef = useRef(null);

    const [fee, setFee] = useState(0);
    const [voucherList, setVoucherList] = useState([]);
    const [voucherSelected, setVoucherSelected] = useState();
    const [giftList, setGiftList] = useState([]);
    const [giftSelected, setGiftSelected] = useState();
    const [seconds, setSeconds] = useState(5);
    const [isPlayAudio, setIsPlayAudio] = useState(false);
    const [
      isShowScreenReorderOrderSuccess,
      setIsShowScreenReorderOrderSuccess,
    ] = useState(false);
    const [note, setNote] = useState('');

    useEffect(() => () => {
      dispatch(resetFeeState());
      dispatch(resetCreateOrderState());
    }, []);

    useEffect(() => {
      let interval;
      if (isShowScreenReorderOrderSuccess) {
        if (!isPlayAudio) {
          audioRef.current.play();
          setIsPlayAudio(true);
        }

        interval = setInterval(() => {
          const newSeconds = seconds - 1;
          if (newSeconds >= 0) {
            setSeconds((currentSeconds) => currentSeconds - 1);
          } else {
            clearInterval(interval);
            history.push(routesUser.home);
          }
        }, 1000);
      }

      return () => clearInterval(interval);
    }, [isShowScreenReorderOrderSuccess, seconds]);

    // Handle gift + voucher
    useEffect(() => {
      if (isGetAllMyGiftsAndVouchersSuccess) {
        if (getAllMyGiftsAndVouchersResponse.data?.length > 0) {
          const voucherListTmp = getAllMyGiftsAndVouchersResponse.data.filter(
            (item) => item.type === 'voucher' && item.apply === false,
          );
          const giftListTmp = getAllMyGiftsAndVouchersResponse.data.filter(
            (item) => item.type === 'gift' && item.apply === false,
          );
          setVoucherList(voucherListTmp);
          setGiftList(giftListTmp);
          setVoucherSelected(voucherListTmp[voucherListTmp.length - 1]);
          setGiftSelected(giftListTmp[giftListTmp.length - 1]);
        } else {
          setVoucherList([]);
          setGiftList([]);
          setVoucherSelected();
          setGiftSelected();
        }
      }
    }, [isGetAllMyGiftsAndVouchersSuccess]);

    // Handle fee
    useEffect(() => {
      if (productsList.length > 0) {
        dispatch(
          getFeeRequest({
            address_id: configResponse.data.profile.addresses.data.find((item) => item.apply)
              .id,
            number: productsList.reduce(
              (total, item) => total + item.quantity,
              0,
            ),
          }),
        );
      }
    }, [productsList]);

    useEffect(() => {
      if (isGetFeeSuccess) {
        setFee(feeResponse.data.cost);
      }
    }, [isGetFeeSuccess]);

    const handleReorder = () => {
      const payload = {
        address_id: configResponse.data.profile.addresses.data.find((item) => item.apply)
          .id,
      };
      if (giftSelected) {
        payload.gift_id = parseInt(giftSelected.id);
      }
      if (voucherSelected) {
        payload.voucher_id = parseInt(voucherSelected.id);
      }
      if (note.trim().length > 0) {
        payload.note = note.trim();
      }
      payload.products = productsList.map((item) => ({
        id: item.id,
        number: item.quantity,
        bag: item.newBag,
      }));
      dispatch(createOrderRequest(payload));
    };

    useEffect(() => {
      if (isCreateOrderSuccess) {
        setIsShowScreenReorderOrderSuccess(true);
      }
    }, [isCreateOrderSuccess]);

    return (
      <>
        <Modal
          show
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          className="modal-order-detail modal-reorder-order--wrapper"
        >
          <Modal.Body>
            <div className="modal-reorder-order--inner pb-4">
              <div className="image-wrapper">
                <img
                  src={ReorderOrderImgUrl}
                  alt="cancel-order"
                  style={{ width: '200px', height: 'auto' }}
                  className="m-auto"
                />
              </div>
              {isShowScreenReorderOrderSuccess ? (
                <>
                  <h4 className="mk-fz-18 mk-fw-500 mk-text-secondary-brand-blue mk-text-center mk-text-uppercase mb-3">
                    {translate('order-detail-page.model.reorder-order.title2')}
                  </h4>
                  <p className="mb-0 mk-fz-14 mk-fw-400">
                    {translate(
                      'order-detail-page.model.reorder-order.description1',
                    )}
                  </p>
                  <p className="mb-0 mk-fz-14 mk-fw-400">
                    {translate(
                      'order-detail-page.model.reorder-order.description2',
                    ).replace('$x', createOrderResponse?.code || createOrderResponse?.data?.code || '---')}
                  </p>
                  <p className="mb-0 mk-fz-14 mk-fw-400">
                    {translate(
                      'order-detail-page.model.reorder-order.description3',
                    ).replace('$x', moment(createOrderResponse?.data?.created_at).format('DD/MM/YYYY'))}
                  </p>
                  <p
                    className="mb-0 mk-fz-14 mk-fw-400"
                    dangerouslySetInnerHTML={{
                      __html: translate(
                        'order-detail-page.model.reorder-order.description4',
                      ).replace(
                        '$x',
                        `<span class="mk-text-secondary-brand-blue">${parseCurrency(
                          createOrderResponse?.data?.detail?.total || 0,
                        )}</span>`,
                      ),
                    }}
                  />
                  <div className="mt-4 flex-center-space w-100 gap-3">
                    <ButtonCustom
                      color="secondary-brand-blue"
                      className="w-100"
                      onClick={() => history.push(routesUser.home)}
                    >
                      {translate(
                        'order-detail-page.model.reorder-order.btn-home',
                      ).replace('$x', seconds)}
                    </ButtonCustom>
                    <ButtonCustom
                      variant="outline-secondary"
                      color="red"
                      className="w-100"
                      onClick={() => history.push(routesUser.ordersList)}
                    >
                      {translate(
                        'order-detail-page.model.reorder-order.btn-orders-list',
                      )}
                    </ButtonCustom>
                  </div>
                  <div className={`pyro${seconds < 4 ? ' hidden' : ''}`}>
                    <div className="before" />
                    <div className="after" />
                  </div>
                  <audio
                    ref={audioRef}
                    controls
                    id="success-sound-noti"
                    className="d-none"
                  >
                    <source src={successSound} type="audio/mpeg" />
                  </audio>
                </>
              ) : (
                <>
                  <h4 className="mk-fz-18 mk-fw-500 mk-text-brand-orange mk-text-center mk-text-uppercase mb-3">
                    {translate('order-detail-page.model.reorder-order.title1')}
                  </h4>
                  <div className="order-detail--list mb-4">
                    {productsList.map((item, index) => (
                      <div
                        key={index}
                        className={`product-item--wrapper${index !== 0 ? ' mt-3' : ''}`}
                      >
                        <p className="product-item--name mb-0 mk-fz-14 mk-fw-500 mk-text-subtle-blue">
                          {item.name}
                        </p>
                        <div className="flex-center-space">
                          <p className="mb-0 mk-fz-12 mk-text-subtle-black mk-fw-400">
                            {item.quantity} x {parseCurrency(item.newPrice)}
                          </p>
                          <p className="mb-0 mk-fz-14 mk-text-black mk-fw-500">
                            {parseCurrency(item.quantity * item.newPrice)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="order-detail--extra mb-2">
                    <div
                      className="flex-center-space mk-fz-14 mk-fw-400"
                      style={{ height: '30px' }}
                    >
                      <span className="mk-fw-500">
                        {translate('order-detail-page.extra.voucher.title')}
                      </span>
                      {isGetAllMyGiftsAndVouchersRequest ? (
                        <span className="mk-text-subtle-black flex-center-center cursor-pointer mk-text-hover-secondary-brand-blue hover-fill-secondary-brand-blue">
                          <Skeleton
                            count={1}
                            width="100px"
                            height="20px"
                            style={{ lineHeight: 'unset' }}
                          />
                        </span>
                      ) : voucherList.length > 0 ? (
                        <Dropdown className="dropdown-select-voucher">
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <span className="mk-text-subtle-black">
                              {voucherSelected
                                ? voucherSelected.voucher.data.title
                                : translate(
                                  'order-detail-page.extra.none.title',
                                )}{' '}
                              <CSChevronDownNavigational
                                className="fill-subtle-black stroke-white"
                                style={{ width: '25px', height: '25px' }}
                              />
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {voucherList.map((item, index) => (
                              <Dropdown.Item
                                className={
                                  voucherSelected.id === item.id ? 'active' : ''
                                }
                                key={index}
                                onClick={() => setVoucherSelected(item)}
                              >
                                {item.voucher.data.title}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        <span className="mk-text-subtle-black flex-center-center cursor-pointer mk-text-hover-secondary-brand-blue hover-fill-secondary-brand-blue">
                          {translate('order-detail-page.extra.none.title')}
                        </span>
                      )}
                    </div>
                    <div
                      className="flex-center-space mk-fz-14 mk-fw-400"
                      style={{ height: '30px' }}
                    >
                      <span className="mk-fw-500">
                        {translate('order-detail-page.extra.gift.title')}
                      </span>
                      {isGetAllMyGiftsAndVouchersRequest ? (
                        <span className="mk-text-subtle-black flex-center-center cursor-pointer mk-text-hover-secondary-brand-blue hover-fill-secondary-brand-blue">
                          <Skeleton
                            count={1}
                            width="100px"
                            height="20px"
                            style={{ lineHeight: 'unset' }}
                          />
                        </span>
                      ) : giftList.length > 0 ? (
                        <Dropdown className="dropdown-select-voucher">
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            <span className="mk-text-subtle-black flex-center-center cursor-pointer mk-text-hover-secondary-brand-blue hover-fill-secondary-brand-blue">
                              {giftSelected
                                ? giftSelected.gift.data.title
                                : translate(
                                  'order-detail-page.extra.none.title',
                                )}{' '}
                              <CSChevronDownNavigational
                                className="fill-subtle-black stroke-white"
                                style={{ width: '25px', height: '25px' }}
                              />
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {giftList.map((item, index) => (
                              <Dropdown.Item
                                className={
                                  giftSelected.id === item.id ? 'active' : ''
                                }
                                key={index}
                                onClick={() => setGiftSelected(item)}
                              >
                                {item.gift.data.title}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        <span className="mk-text-subtle-black flex-center-center cursor-pointer mk-text-hover-secondary-brand-blue hover-fill-secondary-brand-blue">
                          {translate('order-detail-page.extra.none.title')}
                        </span>
                      )}
                    </div>
                    <div
                      className="flex-center-space mk-fz-14 mk-fw-400"
                      style={{ height: '30px' }}
                    >
                      <span className="mk-fw-500">
                        {translate('order-detail-page.extra.coin.title')}
                      </span>
                      <span className="mk-text-brand-orange flex-center-center">
                        +{orderDetail.point}{' '}
                        <img
                          src={EggCoinUrl}
                          alt="egg-coin"
                          style={{ width: '15px', height: '15px' }}
                        />
                      </span>
                    </div>
                    <div
                      className="flex-center-space mk-fz-14 mk-fw-400"
                      style={{ height: '30px' }}
                    >
                      <span className="mk-fw-500">
                        {translate('order-detail-page.extra.ship.title')}
                      </span>
                      <span className="mk-text-subtle-black">
                        {isGetFeeRequest ? (
                          <Skeleton
                            count={1}
                            width="100px"
                            height="20px"
                            style={{ lineHeight: 'unset' }}
                          />
                        ) : voucherSelected ? (
                          voucherSelected?.voucher?.data?.type === 'fee' ? (
                            parseCurrency(0)
                          ) : (
                            parseCurrency(fee || 0)
                          )
                        ) : (
                          parseCurrency(fee || 0)
                        )}
                      </span>
                    </div>
                    <div
                      className="flex-center-space mk-fz-14 mk-fw-400"
                      style={{ height: '30px' }}
                    >
                      <span className="mk-fw-500">
                        {translate('order-detail-page.extra.sale.title')}
                      </span>
                      <span className="mk-text-subtle-black">
                        {isGetFeeRequest ? (
                          <Skeleton
                            count={1}
                            width="100px"
                            height="20px"
                            style={{ lineHeight: 'unset' }}
                          />
                        ) : voucherSelected?.voucher?.data?.type === 'price' ? (
                          parseCurrency(voucherSelected?.voucher?.data?.discount || 0)
                        ) : (
                          parseCurrency(0)
                        )}
                      </span>
                    </div>
                    <div
                      style={{ height: '30px' }}
                      className="flex-center-start"
                    >
                      <span className="mk-fz-14 mk-fw-500">
                        {translate('order-detail-page.extra.note.title')}
                      </span>
                    </div>
                    <div className="mt-1">
                      <InputTextArea
                        id="note"
                        name="note"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </div>
                  </div>
                  <p className="mk-text-right mk-fz-14 mk-fw-400 mk-text-subtle-black mb-0">
                    {translate('order-detail-page.model.reorder-order.total')}
                  </p>
                  <p className="mk-text-right mk-fz-24 mk-text-secondary-brand-blue mk-fw-600 mb-0">
                    {isGetFeeRequest ? (
                      <Skeleton
                        count={1}
                        width="180px"
                        style={{ lineHeight: 'unset' }}
                      />
                    ) : (
                      parseCurrency(
                        voucherSelected
                          ? voucherSelected?.voucher?.data?.type === 'fee'
                            ? productsList.reduce(
                              (total, item) => total + item.newPrice * item.quantity,
                              0,
                            )
                            : productsList.reduce(
                              (total, item) => total + item.newPrice * item.quantity,
                              0,
                            ) - voucherSelected?.voucher?.data?.discount + fee
                          : productsList.reduce(
                            (total, item) => total + item.newPrice * item.quantity,
                            0,
                          ) + fee,
                      )
                    )}
                  </p>
                  <div className="mt-4 flex-center-space w-100 gap-3">
                    <ButtonCustom
                      disabled={isCreateOrderRequest}
                      isLoading={isCreateOrderRequest}
                      color="secondary-brand-blue"
                      className="w-100"
                      onClick={handleReorder}
                    >
                      {translate(
                        'order-detail-page.model.reorder-order.btn-accept',
                      )}
                    </ButtonCustom>
                    <ButtonCustom
                      disabled={isCreateOrderRequest}
                      variant="outline-secondary"
                      color="red"
                      className="w-100"
                      onClick={handleClose}
                    >
                      {translate(
                        'order-detail-page.model.reorder-order.btn-cancel',
                      )}
                    </ButtonCustom>
                  </div>
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
        {isCreateOrderFailure && (
          <ToastError
            show
            content={translate('cart-page.modal.create-order-failure.title')}
          />
        )}
      </>
    );
  },
);
