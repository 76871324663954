import moment from 'moment';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import EggCoinUrl from '~/assets/images/common/egg-coin.png';
import { FooterInfo } from '~/components/common';
import { HeaderChildWithCoin } from '~/components/common/header/HeaderChildWithCoin';
import { ButtonCustom } from '~/components/form';
import { HelmetCustom } from '~/components/helmetCustom';
import { routesUser } from '~/configs';
import { MODAL_KEY } from '~/constants';
import { parseCurrency, translate } from '~/helpers/utils';
import {
  getOrderDetailRequest,
  resetOrderDetailState,
} from '~/redux/order/actions';
import { ModalCancelOrder, ModalReorderOrder } from './components';

const renderStatus = (status) => {
  if (status === 'pending') {
    return (
      <span className="mk-text-secondary-brand-blue">
        {translate('order-detail-page.header.order-status.pending.title')}
      </span>
    );
  } else if (status === 'delivery') {
    return (
      <span className="mk-text-brand-blue">
        {translate('order-detail-page.header.order-status.delivery.title')}
      </span>
    );
  } else if (status === 'delivered' || status === 'paid') {
    return (
      <span className="mk-text-green">
        {translate('order-detail-page.header.order-status.delivered.title')}
      </span>
    );
  } else if (status === 'canceled' || status === 'refunded') {
    return (
      <span className="mk-text-red">
        {translate('order-detail-page.header.order-status.canceled.title')}
      </span>
    );
  } else {
    return (
      <span className="mk-text-brand-orange">
        {translate('order-detail-page.header.order-status.waiting.title')}
      </span>
    );
  }
};

export const UserOrderDetail = () => {
  const {
    isGetAllGiftsRequest,
    isGetAllGiftsSuccess,
    getAllGiftsResponse,
    isGetAllVouchersRequest,
    isGetAllVouchersSuccess,
    getAllVouchersResponse,
  } = useSelector((store) => store.giftVoucher);
  const { isGetConfigRequest, isGetConfigSuccess, configResponse } = useSelector((store) => store.user);
  const {
    isGetOrderDetailRequest,
    isGetOrderDetailSuccess,
    isGetOrderDetailFailure,
    getOrderDetailResponse,
  } = useSelector((store) => store.order);
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();
  const { orderId } = useParams();

  const { pathname, hash } = location;

  const [orderDetail, setOrderDetail] = useState();
  const [isShowModalReorderOrder, setIsShowModalReorderOrder] = useState(false);
  const [isShowModalCancelOrder, setIsShowModalCancelOrder] = useState(false);
  const [isFirstRendering, setIsFirstRendering] = useState(true);
  const [voucherSelected, setVoucherSelected] = useState();
  const [giftSelected, setGiftSelected] = useState();
  const [productsList, setProductsList] = useState([]);

  // Handle check order have exist
  useEffect(() => {
    if (hash) {
      if (hash.includes(MODAL_KEY.MODAL_CONFIRM_REORDER_ORDER)) {
        history.push(
          pathname
            .concat(hash)
            .replace(MODAL_KEY.MODAL_CONFIRM_REORDER_ORDER, ''),
        );
      } else if (hash.includes(MODAL_KEY.MODAL_CONFIRM_CANCEL_ORDER)) {
        history.push(
          pathname
            .concat(hash)
            .replace(MODAL_KEY.MODAL_CONFIRM_CANCEL_ORDER, ''),
        );
      }
    }
    setIsFirstRendering(false);

    return () => {
      dispatch(resetOrderDetailState());
    };
  }, []);

  useEffect(() => {
    if (!isFirstRendering && hash) {
      if (hash.includes(MODAL_KEY.MODAL_CONFIRM_REORDER_ORDER)) {
        setIsShowModalReorderOrder(true);
      } else if (hash.includes(MODAL_KEY.MODAL_CONFIRM_CANCEL_ORDER)) {
        setIsShowModalCancelOrder(true);
      }
    }
  }, [hash]);

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetailRequest({ id: orderId }));
    }
  }, [orderId]);

  useEffect(() => {
    if (isGetOrderDetailFailure) {
      history.push(routesUser.ordersList);
    }
  }, [isGetOrderDetailFailure]);

  useEffect(() => {
    if (isGetOrderDetailSuccess) {
      if (getOrderDetailResponse.data.length !== 1) {
        history.push(routesUser.ordersList);
      } else {
        setOrderDetail(getOrderDetailResponse.data[0]);
      }
    }
  }, [isGetOrderDetailSuccess]);

  // Handle modal
  const handleCloseModalReorderOrder = () => {
    setIsShowModalReorderOrder(false);
    history.push(
      pathname.concat(hash).replace(MODAL_KEY.MODAL_CONFIRM_REORDER_ORDER, ''),
    );
  };

  const handleCloseModalCancelOrder = () => {
    setIsShowModalCancelOrder(false);
    history.push(
      pathname.concat(hash).replace(MODAL_KEY.MODAL_CONFIRM_CANCEL_ORDER, ''),
    );
  };

  // Handle gift + voucher
  useEffect(() => {
    if (
      isGetAllVouchersSuccess
      && isGetOrderDetailSuccess
      && getOrderDetailResponse.data.length === 1
    ) {
      setVoucherSelected(
        getAllVouchersResponse.data.find(
          (item) => item.id === getOrderDetailResponse.data[0].detail.voucher_id,
        ),
      );
    }
  }, [isGetAllVouchersSuccess, isGetOrderDetailSuccess]);

  useEffect(() => {
    if (
      isGetAllGiftsSuccess
      && isGetOrderDetailSuccess
      && getOrderDetailResponse.data.length === 1
    ) {
      setGiftSelected(
        getAllGiftsResponse.data.find(
          (item) => item.id === getOrderDetailResponse.data[0].detail.gift_id,
        ),
      );
    }
  }, [isGetAllGiftsSuccess, isGetOrderDetailSuccess]);

  // Handle product item in order
  useEffect(() => {
    if (
      isGetConfigSuccess
      && isGetOrderDetailSuccess
      && getOrderDetailResponse.data.length === 1
    ) {
      const productsListTmp = [];
      getOrderDetailResponse.data[0].detail.items.map((item1) => {
        const checked = configResponse.data.products.find(
          (item2) => item2.id === item1.product_id,
        );
        if (checked) {
          productsListTmp.push({
            ...checked,
            newPrice: item1.price,
            quantity: item1.number,
            newBag: item1.bag,
          });
        }
      });
      setProductsList(productsListTmp);
    }
  }, [isGetConfigSuccess, isGetOrderDetailSuccess]);

  return (
    <HelmetCustom title={translate('helmet.title.order-detail')}>
      <div
        id="order-detail-page"
        className="order-detail-page--wrapper h-screen pt-4 d-flex flex-column"
      >
        <HeaderChildWithCoin
          title={translate('order-detail-page.title')}
        />
        <div className="order-detail-page--inner flex-grow-1 d-flex flex-column scroll-y">
          <div className="order-detail--wrapper flex-grow-1 mb-4">
            <div className="order-detail--header mb-3 pt-3">
              <p className="mk-text-center mk-fz-14 mk-text-secondary-black mk-fw-500 mb-0">
                {isGetOrderDetailRequest ? (
                  <Skeleton
                    count={1}
                    width="100px"
                    height="15px"
                    style={{ lineHeight: 'unset' }}
                  />
                ) : (
                  orderDetail?.code || orderDetail?.detail?.code || '---'
                )}
              </p>
              <p className="mk-text-center mk-fz-24 mk-text-brand-blue mk-fw-600 mb-0">
                {isGetOrderDetailRequest ? (
                  <Skeleton
                    count={1}
                    width="200px"
                    height="20px"
                    style={{ lineHeight: 'unset' }}
                  />
                ) : (
                  parseCurrency(orderDetail?.detail?.total)
                )}
              </p>
              <div className="flex-center-space mt-3">
                <p className="mk-fz-14 mb-0 mk-text-subtle-black mk-fw-400">
                  {isGetOrderDetailRequest ? (
                    <Skeleton
                      count={1}
                      width="170px"
                      style={{ lineHeight: 'unset' }}
                    />
                  ) : (
                    <>
                      {translate('order-detail-page.header.created.title')}{' '}
                      {moment(orderDetail?.created_at || new Date()).format(
                        'DD/MM/YYYY',
                      )}
                    </>
                  )}
                </p>
                <p className="mk-fz-14 mb-0 mk-fw-600">
                  {isGetOrderDetailRequest ? (
                    <Skeleton
                      count={1}
                      width="100px"
                      style={{ lineHeight: 'unset' }}
                    />
                  ) : (
                    renderStatus(orderDetail?.detail?.status)
                  )}
                </p>
              </div>
            </div>
            <div className="order-detail--list mb-4">
              {isGetConfigRequest || isGetOrderDetailRequest
                ? [...new Array(3)].map((_, index) => (
                  <div
                    key={index}
                    className={`product-item--wrapper${index !== 0 ? ' mt-3' : ''}`}
                  >
                    <Skeleton
                      count={1}
                      width="100%"
                      height="40px"
                      style={{ lineHeight: 'unset' }}
                    />
                  </div>
                ))
                : productsList.map((item, index) => (
                  <div
                    key={index}
                    className={`product-item--wrapper${index !== 0 ? ' mt-3' : ''}`}
                  >
                    <p className="product-item--name mb-0 mk-fz-14 mk-fw-500 mk-text-subtle-blue">
                      {item.name}
                    </p>
                    <div className="flex-center-space">
                      <p className="mb-0 mk-fz-12 mk-text-subtle-black mk-fw-400">
                        {item.quantity} {item.unit} x {parseCurrency(item.newPrice)}
                      </p>
                      <p className="mb-0 mk-fz-14 mk-text-black mk-fw-500">
                        {parseCurrency(item.quantity * item.newPrice)}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
            <div className="order-detail--extra mb-5">
              <p className="flex-center-space mk-fz-14 mk-fw-400">
                <span className="mk-fw-500">
                  {translate('order-detail-page.extra.voucher.title')}
                </span>
                <span className="mk-text-subtle-black">
                  {isGetOrderDetailRequest
                    || isGetAllVouchersRequest
                    ? (
                      <Skeleton
                        count={1}
                        width="70px"
                        style={{ lineHeight: 'unset' }}
                      />
                    ) : (
                      voucherSelected?.title
                      || translate('order-detail-page.extra.none.title')
                    )}
                </span>
              </p>
              <p className="flex-center-space mk-fz-14 mk-fw-400">
                <span className="mk-fw-500">
                  {translate('order-detail-page.extra.gift.title')}
                </span>
                <span className="mk-text-subtle-black">
                  {isGetOrderDetailRequest
                    || isGetAllGiftsRequest
                    ? (
                      <Skeleton
                        count={1}
                        width="70px"
                        style={{ lineHeight: 'unset' }}
                      />
                    ) : (
                      giftSelected?.title
                      || translate('order-detail-page.extra.none.title')
                    )}
                </span>
              </p>
              <p className="flex-center-space mk-fz-14 mk-fw-400">
                <span className="mk-fw-500">
                  {translate('order-detail-page.extra.coin.title')}
                </span>
                <span className="mk-text-brand-orange flex-center-center">
                  {isGetOrderDetailRequest ? (
                    <Skeleton
                      count={1}
                      width="70px"
                      style={{ lineHeight: 'unset' }}
                    />
                  ) : (orderDetail?.detail?.status !== 'canceled' && orderDetail?.detail?.status !== 'refunded') ? (
                    <>
                      +{orderDetail?.point || 0}{' '}
                      <img
                        src={EggCoinUrl}
                        alt="egg-coin"
                        style={{ width: '15px', height: '15px' }}
                      />
                    </>
                  ) : (
                    <>
                      -{orderDetail?.point ? orderDetail?.point * (configResponse?.data?.exchange?.cancel || 1.5) : 0}{' '}
                      <img
                        src={EggCoinUrl}
                        alt="egg-coin"
                        style={{ width: '15px', height: '15px' }}
                      />
                    </>
                  )}
                </span>
              </p>
              <p className="flex-center-space mk-fz-14 mk-fw-400">
                <span className="mk-fw-500">
                  {translate('order-detail-page.extra.ship.title')}
                </span>
                <span className="mk-text-subtle-black">
                  {isGetOrderDetailRequest ? (
                    <Skeleton
                      count={1}
                      width="70px"
                      style={{ lineHeight: 'unset' }}
                    />
                  ) : (
                    parseCurrency(orderDetail?.detail?.fee || 0)
                  )}
                </span>
              </p>
              <p className="flex-center-space mk-fz-14 mk-fw-400">
                <span className="mk-fw-500">
                  {translate('order-detail-page.extra.sale.title')}
                </span>
                <span className="mk-text-subtle-black">
                  {isGetOrderDetailRequest ? (
                    <Skeleton
                      count={1}
                      width="70px"
                      style={{ lineHeight: 'unset' }}
                    />
                  ) : (
                    parseCurrency(orderDetail?.detail?.discount || 0)
                  )}
                </span>
              </p>
            </div>
            <div className="order-detail--control flex-center-space w-100 gap-3">
              {(orderDetail?.detail?.status === 'delivered' || orderDetail?.detail?.status === 'paid') && (
                <ButtonCustom
                  disabled={
                    isGetAllGiftsRequest
                    || isGetAllVouchersRequest
                    || isGetConfigRequest
                    || isGetOrderDetailRequest
                  }
                  className="w-100"
                  color="brand-orange"
                  onClick={() => history.push(MODAL_KEY.MODAL_CONFIRM_REORDER_ORDER)}
                >
                  {translate('order-detail-page.btn.reorder-order')}
                </ButtonCustom>
              )}
              {orderDetail?.detail?.status === 'pending' && (
                <ButtonCustom
                  disabled={
                    isGetAllGiftsRequest
                    || isGetAllVouchersRequest
                    || isGetConfigRequest
                    || isGetOrderDetailRequest
                  }
                  className="w-100"
                  color="brand-orange"
                  variant="line"
                  onClick={() => history.push(MODAL_KEY.MODAL_CONFIRM_CANCEL_ORDER)}
                >
                  {translate('order-detail-page.btn.cancel-order')}
                </ButtonCustom>
              )}
            </div>
          </div>
          <FooterInfo />
        </div>
      </div>

      {isShowModalReorderOrder && (
        <ModalReorderOrder handleClose={handleCloseModalReorderOrder} orderDetail={orderDetail} productsList={productsList} />
      )}

      {isShowModalCancelOrder && (
        <ModalCancelOrder handleClose={handleCloseModalCancelOrder} orderDetail={orderDetail} productsList={productsList} />
      )}
    </HelmetCustom>
  );
};
