import { memo, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { routesUser } from '~/configs';
import { translate } from '~/helpers/utils';

export const PostsList = memo(() => {
  const { isGetAllBlogsRequest, isGetAllBlogsSuccess, getAllBlogsResponse } = useSelector((store) => store.blog);

  const [blogsList, setBlogsList] = useState([]);

  useEffect(() => {
    if (isGetAllBlogsSuccess) {
      let blogsListTmp = [];
      if (getAllBlogsResponse?.data.length > 0) {
        blogsListTmp = [...getAllBlogsResponse.data];
      }
      setBlogsList(blogsListTmp);
    }
  }, [isGetAllBlogsSuccess, getAllBlogsResponse]);

  return (
    <section className="home-page--posts-list">
      <div className="posts-list--inner mt-4 mb-5">
        <div className="flex-center-space mb-3">
          <h3 className="section-title">{translate('home-page.post.title')}</h3>
          <p className="section-sub-title">
            <Link
              to={routesUser.blogs}
              className="mk-text-hover-secondary-brand-blue"
            >
              {translate('button.see-all.title')}
            </Link>
          </p>
        </div>
        <div className="posts-list">
          {isGetAllBlogsRequest
            ? [...new Array(5)].map((_, index) => (
              <div key={index} className="post-item">
                <Skeleton
                  count={1}
                  width="100%"
                  style={{ lineHeight: 'unset', aspectRatio: '1/1' }}
                />
                <Skeleton
                  count={1}
                  width="100%"
                  height="20px"
                  style={{ lineHeight: 'unset', marginTop: '8px' }}
                />
              </div>
            ))
            : blogsList.map((item, index) => (
              <div key={index} className="post-item">
                <img src={item.image} alt={item.title} draggable={false} />
                <p className="mt-2 mb-0 mk-fz-14 mk-fw-500 mk-truncate-2">
                  {item.title}
                </p>
                <p className="flex-center-start mk-fz-14 mk-fw-500 mk-text-secondary-brand-blue mk-fs-i mt-2">
                  <Link
                    to={routesUser.blogDetail.replace(':blogId', item.id)}
                    className="mk-text-hover-brand-blue"
                  >
                    {translate('button.view-detail.title')}
                  </Link>
                </p>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
});
