import vnButtonLangLib from './button';
import vnFooterLangLib from './footer';
import vnHeaderLangLib from './header';
import vnHetmetLangLib from './hetmet';
import vnInputLangLib from './input';
import vnModalAddProductLangLib from './modal-add-product';

const vnCommonLangLib = {
  ...vnButtonLangLib,
  ...vnInputLangLib,
  ...vnFooterLangLib,
  ...vnHetmetLangLib,
  ...vnHeaderLangLib,
  ...vnModalAddProductLangLib,
};

export default vnCommonLangLib;
