// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  isGetRegionsRequest: false,
  isGetRegionsSuccess: false,
  isGetRegionsFailure: false,
  regionsResponse: {},
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get Regions
    [Actions.getRegionsRequest]: (state) => ({
      ...state,
      isGetRegionsRequest: true,
      isGetRegionsSuccess: false,
      isGetRegionsFailure: false,
    }),
    [Actions.getRegionsSuccess]: (state, { payload }) => ({
      ...state,
      isGetRegionsRequest: false,
      isGetRegionsSuccess: true,
      isGetRegionsFailure: false,
      regionsResponse: payload,
    }),
    [Actions.getRegionsFailure]: (state, { payload }) => ({
      ...state,
      isGetRegionsRequest: false,
      isGetRegionsSuccess: false,
      isGetRegionsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Local
    [Actions.resetRegionState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
