// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  isGetAllBlogsRequest: false,
  isGetAllBlogsSuccess: false,
  isGetAllBlogsFailure: false,
  getAllBlogsResponse: {},
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Blogs
    [Actions.getAllBlogsRequest]: (state) => ({
      ...state,
      isGetAllBlogsRequest: true,
      isGetAllBlogsSuccess: false,
      isGetAllBlogsFailure: false,
    }),
    [Actions.getAllBlogsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllBlogsRequest: false,
      isGetAllBlogsSuccess: true,
      isGetAllBlogsFailure: false,
      getAllBlogsResponse: payload,
    }),
    [Actions.getAllBlogsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllBlogsRequest: false,
      isGetAllBlogsSuccess: false,
      isGetAllBlogsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Local
    [Actions.resetBlogState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
