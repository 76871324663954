import { memo } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import {
  CSHome2Outline,
  CSNotificationsOutline,
  CSOrderOutline,
  CSShoppingBag2Outline,
  CSUser2Outline,
} from '~/components/iconography/Outline';
import { routesAuth, routesUser } from '~/configs';
import { STORAGE_KEY } from '~/constants';
import { translate } from '~/helpers/utils';

const footerActionList = [
  {
    icon: (
      <CSHome2Outline
        style={{ width: '18px', height: '18px' }}
        className="fill-secondary-black"
      />
    ),
    label: translate('footer-action.home.label'),
    slug: routesUser.home,
  },
  {
    icon: (
      <CSOrderOutline
        style={{ width: '18px', height: '18px' }}
        className="fill-secondary-black"
      />
    ),
    label: translate('footer-action.order.label'),
    slug: routesUser.ordersList,
    toCustom: routesAuth.login,
  },
  {
    icon: (
      <CSShoppingBag2Outline
        style={{ width: '18px', height: '18px' }}
        className="fill-secondary-black"
      />
    ),
    label: translate('footer-action.cart.label'),
    slug: routesUser.cart,
    toCustom: routesAuth.login,
  },
  {
    icon: (
      <CSNotificationsOutline
        style={{ width: '18px', height: '18px' }}
        className="fill-secondary-black"
      />
    ),
    label: translate('footer-action.notification.label'),
    slug: routesUser.notifications,
  },
  {
    icon: (
      <CSUser2Outline
        style={{ width: '18px', height: '18px' }}
        className="fill-secondary-black"
      />
    ),
    label: translate('footer-action.profile.label'),
    slug: routesUser.profile,
    toCustom: routesAuth.login,
  },
];

export const FooterAction = memo(() => {
  const { profileResponse } = useSelector((store) => store.user);
  const { getAllNotificationsResponse } = useSelector(
    (store) => store.notification,
  );

  const cartState = localStorage.getItem(
    STORAGE_KEY.CART_STATE.concat('_').concat(profileResponse?.data?.id),
  )
    ? JSON.parse(
      localStorage.getItem(
        STORAGE_KEY.CART_STATE.concat('_').concat(profileResponse?.data?.id),
      ),
    )
    : [];
  return (
    <section id="footer-action" className="footer-action--wrapper">
      <div className="footer-action--inner flex-center-space h-full">
        {
          footerActionList.map((item, index) => (
            <NavLink
              exact={item.slug === routesUser.home}
              key={index}
              to={(!profileResponse?.data && item.toCustom) ? item.toCustom : item.slug}
              className="flex-grow-1 flex-center-center flex-column h-full"
              activeClassName="active"
            >
              <span
                className="flex-center-center relative"
                style={{ height: '20px' }}
              >
                {item.icon}{' '}
                {item.slug === routesUser.cart && cartState.length > 0 && (
                  <span className="noti-point" />
                )}
                {item.slug === routesUser.notifications && getAllNotificationsResponse?.data?.filter((item) => item.read === null)?.length > 0 && (
                  <span className="noti-point" />
                )}
              </span>
              <span className="d-inline-block mk-fz-12 mk-fw-500 mk-text-secondary-black">
                {item.label}
              </span>
            </NavLink>
          ))
        }
      </div>
    </section>
  );
});
