import { memo } from 'react';

import { ReactComponent as AddTag } from '~/assets/images/iconography/Outline/AddTag.svg';
import { ReactComponent as Adjustments } from '~/assets/images/iconography/Outline/Adjustments.svg';
import { ReactComponent as Archive } from '~/assets/images/iconography/Outline/Archive.svg';
import { ReactComponent as Backspace } from '~/assets/images/iconography/Outline/Backspace.svg';
import { ReactComponent as BarChart } from '~/assets/images/iconography/Outline/BarChart.svg';
import { ReactComponent as Bell } from '~/assets/images/iconography/Outline/Bell.svg';
import { ReactComponent as Bill } from '~/assets/images/iconography/Outline/Bill.svg';
import { ReactComponent as Bookmark } from '~/assets/images/iconography/Outline/Bookmark.svg';
import { ReactComponent as BookOpen } from '~/assets/images/iconography/Outline/BookOpen.svg';
import { ReactComponent as Briefcase } from '~/assets/images/iconography/Outline/Briefcase.svg';
import { ReactComponent as Building } from '~/assets/images/iconography/Outline/Building.svg';
import { ReactComponent as Button } from '~/assets/images/iconography/Outline/Button.svg';
import { ReactComponent as Calendar } from '~/assets/images/iconography/Outline/Calendar.svg';
import { ReactComponent as Camera } from '~/assets/images/iconography/Outline/Camera.svg';
import { ReactComponent as Cards } from '~/assets/images/iconography/Outline/Cards.svg';
import { ReactComponent as Cash } from '~/assets/images/iconography/Outline/Cash.svg';
import { ReactComponent as Chat } from '~/assets/images/iconography/Outline/Chat.svg';
import { ReactComponent as Check } from '~/assets/images/iconography/Outline/Check.svg';
import { ReactComponent as Checkbox } from '~/assets/images/iconography/Outline/Checkbox.svg';
import { ReactComponent as CheckCircle } from '~/assets/images/iconography/Outline/CheckCircle.svg';
import { ReactComponent as Clock } from '~/assets/images/iconography/Outline/Clock.svg';
import { ReactComponent as Coupon } from '~/assets/images/iconography/Outline/Coupon.svg';
import { ReactComponent as CovidDocument } from '~/assets/images/iconography/Outline/CovidDocument.svg';
import { ReactComponent as Cube } from '~/assets/images/iconography/Outline/Cube.svg';
import { ReactComponent as DocSettings } from '~/assets/images/iconography/Outline/DocSettings.svg';
import { ReactComponent as DocumentAdd } from '~/assets/images/iconography/Outline/DocumentAdd.svg';
import { ReactComponent as DocumentText } from '~/assets/images/iconography/Outline/DocumentText.svg';
import { ReactComponent as Dollar } from '~/assets/images/iconography/Outline/Dollar.svg';
import { ReactComponent as DoubleChevron } from '~/assets/images/iconography/Outline/DoubleChevron.svg';
import { ReactComponent as Download } from '~/assets/images/iconography/Outline/Download.svg';
import { ReactComponent as Dropdown } from '~/assets/images/iconography/Outline/Dropdown.svg';
import { ReactComponent as Duplicate } from '~/assets/images/iconography/Outline/Duplicate.svg';
import { ReactComponent as Edit } from '~/assets/images/iconography/Outline/Edit.svg';
import { ReactComponent as EmojiHappy } from '~/assets/images/iconography/Outline/EmojiHappy.svg';
import { ReactComponent as ExternalLink } from '~/assets/images/iconography/Outline/ExternalLink.svg';
import { ReactComponent as Eye } from '~/assets/images/iconography/Outline/Eye.svg';
import { ReactComponent as EyeOff } from '~/assets/images/iconography/Outline/EyeOff.svg';
import { ReactComponent as Flag } from '~/assets/images/iconography/Outline/Flag.svg';
import { ReactComponent as Folder } from '~/assets/images/iconography/Outline/Folder.svg';
import { ReactComponent as FolderOpen } from '~/assets/images/iconography/Outline/FolderOpen.svg';
import { ReactComponent as Form } from '~/assets/images/iconography/Outline/Form.svg';
import { ReactComponent as FullSize } from '~/assets/images/iconography/Outline/FullSize.svg';
import { ReactComponent as Globe } from '~/assets/images/iconography/Outline/Globe.svg';
import { ReactComponent as GlobeAlt } from '~/assets/images/iconography/Outline/GlobeAlt.svg';
import { ReactComponent as Grid } from '~/assets/images/iconography/Outline/Grid.svg';
import { ReactComponent as Hand } from '~/assets/images/iconography/Outline/Hand.svg';
import { ReactComponent as Heart } from '~/assets/images/iconography/Outline/Heart.svg';
import { ReactComponent as Home } from '~/assets/images/iconography/Outline/Home.svg';
import { ReactComponent as IconPuzzle } from '~/assets/images/iconography/Outline/IconPuzzle.svg';
import { ReactComponent as Inbox } from '~/assets/images/iconography/Outline/Inbox.svg';
import { ReactComponent as Info } from '~/assets/images/iconography/Outline/Info.svg';
import { ReactComponent as InfoCircle } from '~/assets/images/iconography/Outline/InfoCircle.svg';
import { ReactComponent as InputForm } from '~/assets/images/iconography/Outline/InputForm.svg';
import { ReactComponent as Key } from '~/assets/images/iconography/Outline/Key.svg';
import { ReactComponent as LargeGrid } from '~/assets/images/iconography/Outline/LargeGrid.svg';
import { ReactComponent as LargeList } from '~/assets/images/iconography/Outline/LargeList.svg';
import { ReactComponent as Layer } from '~/assets/images/iconography/Outline/Layer.svg';
import { ReactComponent as LightningBolt } from '~/assets/images/iconography/Outline/LightningBolt.svg';
import { ReactComponent as LineChart } from '~/assets/images/iconography/Outline/LineChart.svg';
import { ReactComponent as Linked } from '~/assets/images/iconography/Outline/Linked.svg';
import { ReactComponent as List } from '~/assets/images/iconography/Outline/List.svg';
import { ReactComponent as LocationMarker } from '~/assets/images/iconography/Outline/LocationMarker.svg';
import { ReactComponent as LockClosed } from '~/assets/images/iconography/Outline/LockClosed.svg';
import { ReactComponent as LockOpen } from '~/assets/images/iconography/Outline/LockOpen.svg';
import { ReactComponent as Logout } from '~/assets/images/iconography/Outline/Logout.svg';
import { ReactComponent as Mail } from '~/assets/images/iconography/Outline/Mail.svg';
import { ReactComponent as MailOpen } from '~/assets/images/iconography/Outline/MailOpen.svg';
import { ReactComponent as Map } from '~/assets/images/iconography/Outline/Map.svg';
import { ReactComponent as Microphone } from '~/assets/images/iconography/Outline/Microphone.svg';
import { ReactComponent as MinusCircle } from '~/assets/images/iconography/Outline/MinusCircle.svg';
import { ReactComponent as Modal } from '~/assets/images/iconography/Outline/Modal.svg';
import { ReactComponent as MoreHorizontal } from '~/assets/images/iconography/Outline/MoreHorizontal.svg';
import { ReactComponent as MoreVertical } from '~/assets/images/iconography/Outline/MoreVertical.svg';
import { ReactComponent as Navigational } from '~/assets/images/iconography/Outline/Navigational.svg';
import { ReactComponent as Newspaper } from '~/assets/images/iconography/Outline/Newspaper.svg';
import { ReactComponent as OfficeBuilding } from '~/assets/images/iconography/Outline/OfficeBuilding.svg';
import { ReactComponent as Pagination } from '~/assets/images/iconography/Outline/Pagination.svg';
import { ReactComponent as PaperAirplane } from '~/assets/images/iconography/Outline/PaperAirplane.svg';
import { ReactComponent as PencilAlt } from '~/assets/images/iconography/Outline/PencilAlt.svg';
import { ReactComponent as Phone } from '~/assets/images/iconography/Outline/Phone.svg';
import { ReactComponent as Photograph } from '~/assets/images/iconography/Outline/Photograph.svg';
import { ReactComponent as PieChart } from '~/assets/images/iconography/Outline/PieChart.svg';
import { ReactComponent as Police } from '~/assets/images/iconography/Outline/Police.svg';
import { ReactComponent as Printer } from '~/assets/images/iconography/Outline/Printer.svg';
import { ReactComponent as Progress } from '~/assets/images/iconography/Outline/Progress.svg';
import { ReactComponent as QuestionLined } from '~/assets/images/iconography/Outline/QuestionLined.svg';
import { ReactComponent as Radio } from '~/assets/images/iconography/Outline/Radio.svg';
import { ReactComponent as Refresh } from '~/assets/images/iconography/Outline/Refresh.svg';
import { ReactComponent as Request } from '~/assets/images/iconography/Outline/Request.svg';
import { ReactComponent as Search } from '~/assets/images/iconography/Outline/Search.svg';
import { ReactComponent as Selector } from '~/assets/images/iconography/Outline/Selector.svg';
import { ReactComponent as Send } from '~/assets/images/iconography/Outline/Send.svg';
import { ReactComponent as Setting } from '~/assets/images/iconography/Outline/Setting.svg';
import { ReactComponent as Share } from '~/assets/images/iconography/Outline/Share.svg';
import { ReactComponent as ShieldCheck } from '~/assets/images/iconography/Outline/ShieldCheck.svg';
import { ReactComponent as ShoppingBag } from '~/assets/images/iconography/Outline/ShoppingBag.svg';
import { ReactComponent as Stepper } from '~/assets/images/iconography/Outline/Stepper.svg';
import { ReactComponent as Submit } from '~/assets/images/iconography/Outline/Submit.svg';
import { ReactComponent as Table } from '~/assets/images/iconography/Outline/Table.svg';
import { ReactComponent as Tag } from '~/assets/images/iconography/Outline/Tag.svg';
import { ReactComponent as Template } from '~/assets/images/iconography/Outline/Template.svg';
import { ReactComponent as Toggle } from '~/assets/images/iconography/Outline/Toggle.svg';
import { ReactComponent as Trash } from '~/assets/images/iconography/Outline/Trash.svg';
import { ReactComponent as Truck } from '~/assets/images/iconography/Outline/Truck.svg';
import { ReactComponent as Undo } from '~/assets/images/iconography/Outline/Undo.svg';
import { ReactComponent as Unlinked } from '~/assets/images/iconography/Outline/Unlinked.svg';
import { ReactComponent as Upload } from '~/assets/images/iconography/Outline/Upload.svg';
import { ReactComponent as UploadPicture } from '~/assets/images/iconography/Outline/UploadPicture.svg';
import { ReactComponent as User } from '~/assets/images/iconography/Outline/User.svg';
import { ReactComponent as UserAdd } from '~/assets/images/iconography/Outline/UserAdd.svg';
import { ReactComponent as UserCircle } from '~/assets/images/iconography/Outline/UserCircle.svg';
import { ReactComponent as UserGroup } from '~/assets/images/iconography/Outline/UserGroup.svg';
import { ReactComponent as UserRemove } from '~/assets/images/iconography/Outline/UserRemove.svg';
import { ReactComponent as Users } from '~/assets/images/iconography/Outline/Users.svg';
import { ReactComponent as Vaccine } from '~/assets/images/iconography/Outline/Vaccine.svg';
import { ReactComponent as Airplane } from '~/assets/images/iconography/Outline/Airplane.svg';
import { ReactComponent as Basket } from '~/assets/images/iconography/Outline/Basket.svg';
import { ReactComponent as Calendar2 } from '~/assets/images/iconography/Outline/Calendar2.svg';
import { ReactComponent as Coin } from '~/assets/images/iconography/Outline/Coin.svg';
import { ReactComponent as Coins } from '~/assets/images/iconography/Outline/Coins.svg';
import { ReactComponent as Copy } from '~/assets/images/iconography/Outline/Copy.svg';
import { ReactComponent as Customization } from '~/assets/images/iconography/Outline/Customization.svg';
import { ReactComponent as Favorites } from '~/assets/images/iconography/Outline/Favorites.svg';
import { ReactComponent as Feed } from '~/assets/images/iconography/Outline/Feed.svg';
import { ReactComponent as Filter } from '~/assets/images/iconography/Outline/Filter.svg';
import { ReactComponent as Home2 } from '~/assets/images/iconography/Outline/Home2.svg';
import { ReactComponent as Load } from '~/assets/images/iconography/Outline/Load.svg';
import { ReactComponent as Location } from '~/assets/images/iconography/Outline/Location.svg';
import { ReactComponent as MailBox } from '~/assets/images/iconography/Outline/MailBox.svg';
import { ReactComponent as Map2 } from '~/assets/images/iconography/Outline/Map2.svg';
import { ReactComponent as Money } from '~/assets/images/iconography/Outline/Money.svg';
import { ReactComponent as Moon } from '~/assets/images/iconography/Outline/Moon.svg';
import { ReactComponent as Notifications } from '~/assets/images/iconography/Outline/Notifications.svg';
import { ReactComponent as Pencil } from '~/assets/images/iconography/Outline/Pencil.svg';
import { ReactComponent as Pipette } from '~/assets/images/iconography/Outline/Pipette.svg';
import { ReactComponent as Print } from '~/assets/images/iconography/Outline/Print.svg';
import { ReactComponent as Receipt } from '~/assets/images/iconography/Outline/Receipt.svg';
import { ReactComponent as ShoppingBag2 } from '~/assets/images/iconography/Outline/ShoppingBag2.svg';
import { ReactComponent as Store } from '~/assets/images/iconography/Outline/Store.svg';
import { ReactComponent as Sun } from '~/assets/images/iconography/Outline/Sun.svg';
import { ReactComponent as Time } from '~/assets/images/iconography/Outline/Time.svg';
import { ReactComponent as Trash2 } from '~/assets/images/iconography/Outline/Trash2.svg';
import { ReactComponent as User2 } from '~/assets/images/iconography/Outline/User2.svg';
import { ReactComponent as Wallet } from '~/assets/images/iconography/Outline/Wallet.svg';
import { ReactComponent as Order } from '~/assets/images/iconography/Outline/Order.svg';
import { ReactComponent as Call } from '~/assets/images/iconography/Outline/Call.svg';
import { ReactComponent as Pen } from '~/assets/images/iconography/Outline/Pen.svg';
import { ReactComponent as Upload2 } from '~/assets/images/iconography/Outline/Upload2.svg';

export const CSUpload2Outline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Upload2
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSPenOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Pen
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSCallOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Call
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSOrderOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Order
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSAirplaneOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Airplane
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSBasketOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Basket
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSCalendar2Outline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Calendar2
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSCoinOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Coin
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSCoinsOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Coins
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSCopyOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Copy
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSCustomizationOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Customization
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSFavoritesOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Favorites
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSFeedOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Feed
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSFilterOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Filter
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSHome2Outline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Home2
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSLoadOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Load
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSLocationOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Location
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSMailBoxOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <MailBox
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSMap2Outline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Map2
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSMoneyOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Money
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSMoonOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Moon
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSNotificationsOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Notifications
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSPencilOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Pencil
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSPipetteOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Pipette
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSPrintOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Print
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSReceiptOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Receipt
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSShoppingBag2Outline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <ShoppingBag2
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSStoreOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Store
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSSunOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Sun
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSTimeOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Time
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSTrash2Outline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Trash2
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSUser2Outline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <User2
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSWalletOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Wallet
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSAddTagOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <AddTag
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSAdjustmentsOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Adjustments
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSArchiveOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Archive
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSBackspaceOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Backspace
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSBellOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Bell
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSBillOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Bill
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSBookmarkOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Bookmark
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSBookOpenOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <BookOpen
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSBriefcaseOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Briefcase
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSBuildingOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Building
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSButtonOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Button
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSCalendarOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Calendar
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSCameraOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Camera
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSCardsOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Cards
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSCashOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Cash
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSLineChartOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <LineChart
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSBarChartOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <BarChart
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSChatOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Chat
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSCheckOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Check
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSCheckboxOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Checkbox
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSCheckCircleOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <CheckCircle
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSClockOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Clock
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSCouponOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Coupon
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSCovidDocumentOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <CovidDocument
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSCubeOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Cube
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSDollarOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Dollar
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSDocSettingsOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <DocSettings
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSDocumentAddOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <DocumentAdd
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSDocumentTextOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <DocumentText
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSDoubleChevronOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <DoubleChevron
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSDownloadOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Download
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSDropdownOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Dropdown
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSDuplicateOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Duplicate
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSEditOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Edit
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSEmojiHappyOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <EmojiHappy
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSExternalLinkOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <ExternalLink
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSEyeOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Eye
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSEyeOffOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <EyeOff
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSFlagOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Flag
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSFolderOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Folder
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSFolderOpenOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <FolderOpen
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSFormOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Form
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSFullSizeOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <FullSize
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSGlobeOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Globe
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSGlobeAltOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <GlobeAlt
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSGridOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Grid
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSHandOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Hand
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSHeartOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Heart
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSHomeOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Home
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSIconPuzzleOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <IconPuzzle
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSInboxOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Inbox
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSInfoOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Info
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSInfoCircleOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <InfoCircle
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSInputFormOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <InputForm
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSKeyOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Key
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSLargeGridOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <LargeGrid
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSLargeListOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <LargeList
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSLayerOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Layer
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSLightningBoltOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <LightningBolt
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSLinkedOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Linked
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSListOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <List
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSLocationMarkerOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <LocationMarker
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSLockClosedOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <LockClosed
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSLockOpenOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <LockOpen
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSLogoutOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Logout
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSMailOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Mail
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSMailOpenOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <MailOpen
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSMapOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Map
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSMicrophoneOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Microphone
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSMinusCircleOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <MinusCircle
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSModalOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Modal
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSMoreHorizontalOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <MoreHorizontal
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSMoreVerticalOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <MoreVertical
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSNavigationalOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Navigational
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSNewspaperOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Newspaper
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSOfficeBuildingOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <OfficeBuilding
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSPaginationOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Pagination
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSPaperAirplaneOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <PaperAirplane
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSPencilAltOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <PencilAlt
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSPhoneOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Phone
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSPhotographOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Photograph
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSPieChartOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <PieChart
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSPoliceOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Police
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSPrinterOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Printer
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSProgressOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Progress
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSQuestionLinedOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <QuestionLined
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSRadioOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Radio
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSRefreshOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Refresh
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSRequestOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Request
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSSearchOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Search
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSSelectorOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Selector
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSSendOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Send
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSSettingOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Setting
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSShareOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Share
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSShieldCheckOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <ShieldCheck
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSShoppingBagOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <ShoppingBag
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSStepperOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Stepper
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSSubmitOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Submit
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSTableOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Table
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSTagOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Tag
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSTemplateOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Template
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSToggleOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Toggle
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSTrashOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Trash
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSTruckOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Truck
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSUndoOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Undo
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSUnlinkedOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Unlinked
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSUploadOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Upload
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSUploadPictureOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <UploadPicture
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSUserOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <User
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSUserAddOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <UserAdd
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSUserCircleOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <UserCircle
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSUserGroupOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <UserGroup
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSUserRemoveOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <UserRemove
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSUsersOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Users
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);

export const CSVaccineOutline = memo(
  ({ className = '', style = {}, onClick = null }) => (
    <Vaccine
      className={className}
      style={style}
      onClick={onClick ? () => onClick() : null}
    />
  ),
);
