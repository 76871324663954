import { memo, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { STORAGE_KEY } from '~/constants';

import { translate } from '~/helpers/utils';
import { openModalAddProductRequest } from '~/redux/modal/actions';

export const TrendingProducts = memo(() => {
  const {
    isGetConfigRequest, isGetConfigSuccess, configResponse,
  } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  const [productsList, setProductsList] = useState([]);

  useEffect(() => {
    if (isGetConfigSuccess) {
      const productsListTmp = [];
      const productsBuyManyList = configResponse.data.product_buy_many.map((item) => parseInt(item));
      const productsListAll = configResponse.data.products;
      productsListAll.map((item) => {
        if (productsBuyManyList.includes(parseInt(item.id))) {
          productsListTmp.push(item);
        }
      });
      setProductsList(productsListTmp);
    }
  }, [isGetConfigSuccess, configResponse]);

  const handleAddProductItem = (data) => {
    const cartState = localStorage.getItem(STORAGE_KEY.CART_STATE) ? JSON.parse(localStorage.getItem(STORAGE_KEY.CART_STATE)) : [];
    if (cartState.find((item) => parseInt(item.id) === data.id)) {
      dispatch(
        openModalAddProductRequest({
          data,
          type: 'edit',
          from: 'trending-product',
        }),
      );
    } else {
      dispatch(
        openModalAddProductRequest({
          data,
          from: 'trending-product',
        }),
      );
    }
  };

  return (
    <section className="home-page--trending-products">
      <div className="trending-products--inner mb-4">
        <div className="flex-center-space mb-3">
          <h3 className="section-title">
            {translate('home-page.trending-products.title')}
          </h3>
        </div>
        <div className="products-list">
          {isGetConfigRequest
            ? [...new Array(3)].map((_, index) => (
              <div key={index} className="product-item">
                <Skeleton
                  count={1}
                  width="100%"
                  style={{ lineHeight: 'unset', aspectRatio: '1/1' }}
                />
                <Skeleton
                  count={1}
                  width="100%"
                  height="20px"
                  style={{ lineHeight: 'unset', marginTop: '8px' }}
                />
              </div>
            ))
            : productsList.map((item, index) => (
              <div
                key={index}
                className="product-item cursor-pointer mk-text-hover-secondary-brand-blue"
                onClick={() => handleAddProductItem(item)}
              >
                <img src={item.image} alt={item.name} draggable={false} />
                <p className="mt-2 mb-0 mk-fz-14 mk-fw-500 cursor-pointer mk-truncate-2">
                  {item.name}
                </p>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
});
