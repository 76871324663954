import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import EggCoinUrl from '~/assets/images/common/egg-coin.png';
import { routesAuth, routesUser } from '~/configs';
import { translate } from '~/helpers/utils';

export const HeaderWelcome = memo(() => {
  const { profileResponse } = useSelector((store) => store.user);

  const history = useHistory();

  return (
    <header id="header-welcome" className="header-welcome--wrapper">
      <div className="header-welcome--inner flex-start-space mb-3">
        <div>
          <h4 className="mk-fz-14 mk-fw-500 mb-0">
            {translate('header.header-welcome.title-1')}{' '}
            <span className="mk-text-brand-orange mk-fw-600 mk-text-hover-orange cursor-pointer" onClick={() => history.push(routesUser.information)}>
              {profileResponse?.data?.name || 'bạn'}
            </span>
            ,
          </h4>
          <h4 className="mk-fz-14 mk-fw-500 mb-0">
            {translate('header.header-welcome.title-2')}
          </h4>
        </div>
        {
          profileResponse?.data ? (
            <span
              className="flex-center-end mk-fz-20 mk-fw-500 mk-text-brand-orange mk-text-hover-orange cursor-pointer"
              onClick={() => history.push(routesUser.managerCoin)}
            >
              <img
                src={EggCoinUrl}
                alt="egg-coin"
                style={{ width: '25px', height: '25px', marginRight: '3px' }}
              />{' '}
              {profileResponse?.data?.point}
            </span>
          ) : (
            <span
              className="flex-center-end mk-fz-16 mk-fw-500 mk-text-brand-orange mk-text-hover-orange cursor-pointer"
              onClick={() => history.push(routesAuth.login)}
            >
              {translate('button.login.title')}
            </span>
          )
        }
      </div>
    </header>
  );
});
