/* eslint-disable import/no-extraneous-dependencies */
// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import ProductImg1Url from '~/assets/images/products/long-do.jpg';
import ProductImg2Url from '~/assets/images/products/bot-trung.jpg';
import ProductImg3Url from '~/assets/images/products/long-trang.png';
import ProductImg4Url from '~/assets/images/products/vo-trung.jpg';
import * as Actions from './actions';

const PRODUCT_IMAGE_LIST = [
  ProductImg1Url,
  ProductImg2Url,
  ProductImg3Url,
  ProductImg4Url,
];

const initialState = {
  //
  isGetOrdersRecentRequest: false,
  isGetOrdersRecentSuccess: false,
  isGetOrdersRecentFailure: false,
  getOrdersRecentResponse: {},
  //
  isGetOrdersPendingRequest: false,
  isGetOrdersPendingSuccess: false,
  isGetOrdersPendingFailure: false,
  getOrdersPendingResponse: {},
  //
  isGetOrdersSuccessRequest: false,
  isGetOrdersSuccessSuccess: false,
  isGetOrdersSuccessFailure: false,
  getOrdersSuccessResponse: {},
  //
  isGetOrdersCancelRequest: false,
  isGetOrdersCancelSuccess: false,
  isGetOrdersCancelFailure: false,
  getOrdersCancelResponse: {},
  //
  isGetOrderDetailRequest: false,
  isGetOrderDetailSuccess: false,
  isGetOrderDetailFailure: false,
  getOrderDetailResponse: {},
  //
  isGetAllOrdersRequest: false,
  isGetAllOrdersSuccess: false,
  isGetAllOrdersFailure: false,
  getAllOrdersResponse: {},
  //
  isCreateOrderRequest: false,
  isCreateOrderSuccess: false,
  isCreateOrderFailure: false,
  createOrderResponse: {},
  //
  isCancelOrderRequest: false,
  isCancelOrderSuccess: false,
  isCancelOrderFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get Orders Recent
    [Actions.getOrdersRecentRequest]: (state) => ({
      ...state,
      isGetOrdersRecentRequest: true,
      isGetOrdersRecentSuccess: false,
      isGetOrdersRecentFailure: false,
    }),
    [Actions.getOrdersRecentSuccess]: (state, { payload }) => ({
      ...state,
      isGetOrdersRecentRequest: false,
      isGetOrdersRecentSuccess: true,
      isGetOrdersRecentFailure: false,
      getOrdersRecentResponse: payload,
    }),
    [Actions.getOrdersRecentFailure]: (state, { payload }) => ({
      ...state,
      isGetOrdersRecentRequest: false,
      isGetOrdersRecentSuccess: false,
      isGetOrdersRecentFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get Orders Pending
    [Actions.getOrdersPendingRequest]: (state) => ({
      ...state,
      isGetOrdersPendingRequest: true,
      isGetOrdersPendingSuccess: false,
      isGetOrdersPendingFailure: false,
    }),
    [Actions.getOrdersPendingSuccess]: (state, { payload }) => ({
      ...state,
      isGetOrdersPendingRequest: false,
      isGetOrdersPendingSuccess: true,
      isGetOrdersPendingFailure: false,
      getOrdersPendingResponse: {
        ...payload,
        data: [...payload.data.map((item) => ({ ...item, imageCustom: PRODUCT_IMAGE_LIST[Math.floor(Math.random() * PRODUCT_IMAGE_LIST.length)] }))],
      },
    }),
    [Actions.getOrdersPendingFailure]: (state, { payload }) => ({
      ...state,
      isGetOrdersPendingRequest: false,
      isGetOrdersPendingSuccess: false,
      isGetOrdersPendingFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get Orders Success
    [Actions.getOrdersSuccessRequest]: (state) => ({
      ...state,
      isGetOrdersSuccessRequest: true,
      isGetOrdersSuccessSuccess: false,
      isGetOrdersSuccessFailure: false,
    }),
    [Actions.getOrdersSuccessSuccess]: (state, { payload }) => ({
      ...state,
      isGetOrdersSuccessRequest: false,
      isGetOrdersSuccessSuccess: true,
      isGetOrdersSuccessFailure: false,
      getOrdersSuccessResponse: {
        ...payload,
        data: [...payload.data.map((item) => ({ ...item, imageCustom: PRODUCT_IMAGE_LIST[Math.floor(Math.random() * PRODUCT_IMAGE_LIST.length)] }))],
      },
    }),
    [Actions.getOrdersSuccessFailure]: (state, { payload }) => ({
      ...state,
      isGetOrdersSuccessRequest: false,
      isGetOrdersSuccessSuccess: false,
      isGetOrdersSuccessFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get Orders Cancel
    [Actions.getOrdersCancelRequest]: (state) => ({
      ...state,
      isGetOrdersCancelRequest: true,
      isGetOrdersCancelSuccess: false,
      isGetOrdersCancelFailure: false,
    }),
    [Actions.getOrdersCancelSuccess]: (state, { payload }) => ({
      ...state,
      isGetOrdersCancelRequest: false,
      isGetOrdersCancelSuccess: true,
      isGetOrdersCancelFailure: false,
      getOrdersCancelResponse: {
        ...payload,
        data: [...payload.data.map((item) => ({ ...item, imageCustom: PRODUCT_IMAGE_LIST[Math.floor(Math.random() * PRODUCT_IMAGE_LIST.length)] }))],
      },
    }),
    [Actions.getOrdersCancelFailure]: (state, { payload }) => ({
      ...state,
      isGetOrdersCancelRequest: false,
      isGetOrdersCancelSuccess: false,
      isGetOrdersCancelFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get Order Detail
    [Actions.getOrderDetailRequest]: (state) => ({
      ...state,
      isGetOrderDetailRequest: true,
      isGetOrderDetailSuccess: false,
      isGetOrderDetailFailure: false,
    }),
    [Actions.getOrderDetailSuccess]: (state, { payload }) => ({
      ...state,
      isGetOrderDetailRequest: false,
      isGetOrderDetailSuccess: true,
      isGetOrderDetailFailure: false,
      getOrderDetailResponse: payload,
    }),
    [Actions.getOrderDetailFailure]: (state, { payload }) => ({
      ...state,
      isGetOrderDetailRequest: false,
      isGetOrderDetailSuccess: false,
      isGetOrderDetailFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetOrderDetailState]: (state) => ({
      ...state,
      isGetOrderDetailRequest: false,
      isGetOrderDetailSuccess: false,
      isGetOrderDetailFailure: false,
      getOrderDetailResponse: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Get All Orders
    [Actions.getAllOrdersRequest]: (state) => ({
      ...state,
      isGetAllOrdersRequest: true,
      isGetAllOrdersSuccess: false,
      isGetAllOrdersFailure: false,
    }),
    [Actions.getAllOrdersSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllOrdersRequest: false,
      isGetAllOrdersSuccess: true,
      isGetAllOrdersFailure: false,
      getAllOrdersResponse: {
        ...payload,
        data: [...payload.data.map((item) => ({ ...item, imageCustom: PRODUCT_IMAGE_LIST[Math.floor(Math.random() * PRODUCT_IMAGE_LIST.length)] }))],
      },
    }),
    [Actions.getAllOrdersFailure]: (state, { payload }) => ({
      ...state,
      isGetAllOrdersRequest: false,
      isGetAllOrdersSuccess: false,
      isGetAllOrdersFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create Order
    [Actions.createOrderRequest]: (state) => ({
      ...state,
      isCreateOrderRequest: true,
      isCreateOrderSuccess: false,
      isCreateOrderFailure: false,
    }),
    [Actions.createOrderSuccess]: (state, { payload }) => ({
      ...state,
      isCreateOrderRequest: false,
      isCreateOrderSuccess: true,
      isCreateOrderFailure: false,
      createOrderResponse: payload,
      getOrdersRecentResponse: {
        ...state.getOrdersRecentResponse,
        data: [{ ...payload.data, imageCustom: PRODUCT_IMAGE_LIST[Math.floor(Math.random() * PRODUCT_IMAGE_LIST.length)] }, ...state.getOrdersRecentResponse.data],
      },
      getOrdersPendingResponse: {
        ...state.getOrdersPendingResponse,
        data: [{ ...payload.data, imageCustom: PRODUCT_IMAGE_LIST[Math.floor(Math.random() * PRODUCT_IMAGE_LIST.length)] }, ...state.getOrdersPendingResponse.data],
      },
    }),
    [Actions.createOrderFailure]: (state, { payload }) => ({
      ...state,
      isCreateOrderRequest: false,
      isCreateOrderSuccess: false,
      isCreateOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateOrderState]: (state) => ({
      ...state,
      isCreateOrderRequest: false,
      isCreateOrderSuccess: false,
      isCreateOrderFailure: false,
      createOrderResponse: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Cancel Order
    [Actions.cancelOrderRequest]: (state) => ({
      ...state,
      isCancelOrderRequest: true,
      isCancelOrderSuccess: false,
      isCancelOrderFailure: false,
    }),
    [Actions.cancelOrderSuccess]: (state, { payload }) => ({
      ...state,
      isCancelOrderRequest: false,
      isCancelOrderSuccess: true,
      isCancelOrderFailure: false,
      getOrdersPendingResponse: {
        ...state.getOrdersPendingResponse,
        data: [...state.getOrdersPendingResponse.data.filter((item) => item.id !== payload.data.id)],
      },
      getOrdersCancelResponse: {
        ...state.getOrdersCancelResponse,
        data: [{ ...payload.data, imageCustom: PRODUCT_IMAGE_LIST[Math.floor(Math.random() * PRODUCT_IMAGE_LIST.length)] }, ...state.getOrdersCancelResponse.data],
      },
    }),
    [Actions.cancelOrderFailure]: (state, { payload }) => ({
      ...state,
      isCancelOrderRequest: false,
      isCancelOrderSuccess: false,
      isCancelOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCancelOrderState]: (state) => ({
      ...state,
      isCancelOrderRequest: false,
      isCancelOrderSuccess: false,
      isCancelOrderFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetOrderState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
