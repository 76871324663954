/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { routesAuth } from '~/configs';
import { STORAGE_KEY } from '~/constants';
import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* loginRequest({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/clients/web/login', payload));
    const {
      token_type, access_token, refresh_token, expires_in,
    } = response.data;
    localStorage.setItem(
      STORAGE_KEY.ACCESS_TOKEN,
      `${token_type} ${access_token}`,
    );
    localStorage.setItem(STORAGE_KEY.REFRESH_TOKEN, refresh_token);
    localStorage.setItem(STORAGE_KEY.EXPIRES_IN, expires_in);
    localStorage.setItem(STORAGE_KEY.IS_LOGIN, true);
    yield put(Actions.loginSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.loginFailure(messages));
    }
  }
}

function* logoutRequest() {
  try {
    localStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
    localStorage.removeItem(STORAGE_KEY.REFRESH_TOKEN);
    localStorage.removeItem(STORAGE_KEY.EXPIRES_IN);
    localStorage.removeItem(STORAGE_KEY.IS_LOGIN);
    localStorage.removeItem(STORAGE_KEY.ID_LOGIN);
    window.location.replace(routesAuth.login);
    yield call(() => axiosMicro.delete('/logout'));
  } catch {
    //
  }
}

function* checkUserExists({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/authentications/check', { params: payload }));
    yield put(Actions.checkUserExistsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.checkUserExistsFailure(messages));
    }
  }
}

function* register({ payload }) {
  try {
    const form = new FormData();
    form.append('phone', payload.phoneNumber);
    form.append('username', payload.phoneNumber);
    form.append('password', payload.password);
    form.append('name', payload.name);
    form.append('address', payload.address);
    form.append('province', payload.province);
    form.append('district', payload.district);
    form.append('ward', payload.ward);
    if (payload.referralCode) {
      form.append('presenter', payload.referralCode);
    }
    if (payload.email) {
      form.append('email', payload.email);
    }
    if (payload.companyName) {
      form.append('company', payload.companyName);
    }

    const response = yield call(() => axiosMicro.post('/register', form));
    yield put(Actions.registerSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.registerFailure(messages));
    }
  }
}

function* updateUser({ payload }) {
  try {
    const { type, data } = payload;
    const form = new FormData();
    if (type === 'forgot-password') {
      form.append('password', data.password);
    } else if (type === 'update-information') {
      form.append('name', data.name);
      form.append('gender', data.gender);
      if (data.company) {
        form.append('company', data.company);
      } if (data.email) {
        form.append('email', data.email);
      } if (data.birthday) {
        form.append('birthday', data.birthday);
      }
    } else if (type === 'update-avatar') {
      form.append('avatar', data.avatar);
    }
    const response = yield call(() => axiosMicro.post(`/users/${data.id}?_method=PATCH`, form));
    yield put(Actions.updateUserSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateUserFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.loginRequest, loginRequest);
  yield takeLatest(Actions.logoutRequest, logoutRequest);
  yield takeLatest(Actions.checkUserExistsRequest, checkUserExists);
  yield takeLatest(Actions.registerRequest, register);
  yield takeLatest(Actions.updateUserRequest, updateUser);
}
