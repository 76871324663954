// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const callApiFirstTimeRequest = createAction('CALL_API_FIRST_TIME_REQUEST');

export const callApiDefaultRequest = createAction('CALL_API_DEFAULT_REQUEST');

export const getProfileRequest = createAction('GET_PROFILE_REQUEST');
export const getProfileSuccess = createAction('GET_PROFILE_SUCCESS');
export const getProfileFailure = createAction('GET_PROFILE_FAILURE');

export const getConfigRequest = createAction('GET_CONFIG_REQUEST');
export const getConfigSuccess = createAction('GET_CONFIG_SUCCESS');
export const getConfigFailure = createAction('GET_CONFIG_FAILURE');

export const updateAddressesConfig = createAction('UPDATE_ADDRESSES_CONFIG');
export const updateAddressesProfile = createAction('UPDATE_ADDRESSES_PROFILE');

export const updateInformationUserRequest = createAction('UPDATE_INFORMATION_USER_REQUEST');

export const changePointRequest = createAction('CHANGE_POINT_REQUEST');

export const removeUserRequest = createAction('REMOVE_USER_REQUEST');
export const removeUserSuccess = createAction('REMOVE_USER_SUCCESS');
export const removeUserFailure = createAction('REMOVE_USER_FAILURE');
export const resetRemoveUserState = createAction('RESET_REMOVE_USER_STATE');

export const resetUserState = createAction('RESET_USER_STATE');
