import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { List } from './List';

export const HistoryTab = () => {
  const { isGetAllPointsRequest, isGetAllPointsSuccess, getAllPointsResponse } = useSelector((store) => store.point);

  const [pointsData, setPointsData] = useState([]);

  useEffect(() => {
    if (isGetAllPointsSuccess) {
      setPointsData(getAllPointsResponse.data || []);
    }
  }, [isGetAllPointsSuccess]);

  return (
    <div className="gift-tab--wrapper">
      <div className="gift-tab--main">
        <List
          data={pointsData}
          isLoading={isGetAllPointsRequest}
          type="history"
        />
      </div>
    </div>
  );
};
