// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  isGetAllGiftsRequest: false,
  isGetAllGiftsSuccess: false,
  isGetAllGiftsFailure: false,
  getAllGiftsResponse: {},
  //
  isGetAllVouchersRequest: false,
  isGetAllVouchersSuccess: false,
  isGetAllVouchersFailure: false,
  getAllVouchersResponse: {},
  //
  isGetAllMyGiftsAndVouchersRequest: false,
  isGetAllMyGiftsAndVouchersSuccess: false,
  isGetAllMyGiftsAndVouchersFailure: false,
  getAllMyGiftsAndVouchersResponse: {},
  //
  isExchangeMyGiftAndVoucherRequest: false,
  isExchangeMyGiftAndVoucherSuccess: false,
  isExchangeMyGiftAndVoucherFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Gifts
    [Actions.getAllGiftsRequest]: (state) => ({
      ...state,
      isGetAllGiftsRequest: true,
      isGetAllGiftsSuccess: false,
      isGetAllGiftsFailure: false,
    }),
    [Actions.getAllGiftsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllGiftsRequest: false,
      isGetAllGiftsSuccess: true,
      isGetAllGiftsFailure: false,
      getAllGiftsResponse: {
        ...payload,
        data: payload.data.filter((item) => item.active === true),
      },
    }),
    [Actions.getAllGiftsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllGiftsRequest: false,
      isGetAllGiftsSuccess: false,
      isGetAllGiftsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get All Vouchers
    [Actions.getAllVouchersRequest]: (state) => ({
      ...state,
      isGetAllVouchersRequest: true,
      isGetAllVouchersSuccess: false,
      isGetAllVouchersFailure: false,
    }),
    [Actions.getAllVouchersSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllVouchersRequest: false,
      isGetAllVouchersSuccess: true,
      isGetAllVouchersFailure: false,
      getAllVouchersResponse: {
        ...payload,
        data: payload.data.filter((item) => item.active === true),
      },
    }),
    [Actions.getAllVouchersFailure]: (state, { payload }) => ({
      ...state,
      isGetAllVouchersRequest: false,
      isGetAllVouchersSuccess: false,
      isGetAllVouchersFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get All My Gift And Vouchers
    [Actions.getAllMyGiftsAndVouchersRequest]: (state) => ({
      ...state,
      isGetAllMyGiftsAndVouchersRequest: true,
      isGetAllMyGiftsAndVouchersSuccess: false,
      isGetAllMyGiftsAndVouchersFailure: false,
    }),
    [Actions.getAllMyGiftsAndVouchersSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllMyGiftsAndVouchersRequest: false,
      isGetAllMyGiftsAndVouchersSuccess: true,
      isGetAllMyGiftsAndVouchersFailure: false,
      getAllMyGiftsAndVouchersResponse: payload,
    }),
    [Actions.getAllMyGiftsAndVouchersFailure]: (state, { payload }) => ({
      ...state,
      isGetAllMyGiftsAndVouchersRequest: false,
      isGetAllMyGiftsAndVouchersSuccess: false,
      isGetAllMyGiftsAndVouchersFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Exchange My Gift And Voucher
    [Actions.exchangeMyGiftAndVoucherRequest]: (state) => ({
      ...state,
      isExchangeMyGiftAndVoucherRequest: true,
      isExchangeMyGiftAndVoucherSuccess: false,
      isExchangeMyGiftAndVoucherFailure: false,
    }),
    [Actions.exchangeMyGiftAndVoucherSuccess]: (state, { payload }) => ({
      ...state,
      isExchangeMyGiftAndVoucherRequest: false,
      isExchangeMyGiftAndVoucherSuccess: true,
      isExchangeMyGiftAndVoucherFailure: false,
      getAllMyGiftsAndVouchersResponse: {
        ...state.getAllMyGiftsAndVouchersResponse,
        data: [payload.data, ...state.getAllMyGiftsAndVouchersResponse.data],
      },
    }),
    [Actions.exchangeMyGiftAndVoucherFailure]: (state, { payload }) => ({
      ...state,
      isExchangeMyGiftAndVoucherRequest: false,
      isExchangeMyGiftAndVoucherSuccess: false,
      isExchangeMyGiftAndVoucherFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetExchangeMyGiftAndVoucherState]: (state) => ({
      ...state,
      isExchangeMyGiftAndVoucherRequest: false,
      isExchangeMyGiftAndVoucherSuccess: false,
      isExchangeMyGiftAndVoucherFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.updateListMyGiftsAndVouchers]: (state, { payload }) => ({
      ...state,
      getAllMyGiftsAndVouchersResponse: {
        ...state.getAllMyGiftsAndVouchersResponse,
        data: [...state.getAllMyGiftsAndVouchersResponse.data.map((item) => {
          if (payload.ids.includes(item.id)) {
            return ({
              ...item, apply: true,
            });
          } else {
            return item;
          }
        })],
      },
    }),
    [Actions.resetGiftVoucherState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
