import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  ContactButton,
  FooterInfo,
  HeaderChildWithoutCoin,
  ReferralCodeSection,
} from '~/components/common';
import { HelmetCustom } from '~/components/helmetCustom';
import { routesUser } from '~/configs';
import { translate } from '~/helpers/utils';
import { logoutRequest } from '~/redux/auth/actions';
import { removeUserRequest } from '~/redux/user/actions';
import { ToastError } from '~/helpers/funcs';
import { ModalConfirmRemove } from './components';

const navList = [
  {
    label: translate('profile-page.nav.link.update-information'),
    slug: routesUser.information,
    type: 'link',
  },
  {
    label: translate('profile-page.nav.link.addresses-manager'),
    slug: routesUser.addressesManager,
    type: 'link',
  },
  {
    label: translate('profile-page.nav.link.exchange-coin'),
    slug: routesUser.managerCoin,
    type: 'link',
  },
  {
    label: translate('profile-page.nav.link.order-manager'),
    slug: routesUser.ordersList,
    type: 'link',
  },
  // {
  //   label: translate('profile-page.nav.link.tutorial'),
  //   slug: '#',
  //   type: 'link',
  // },
  // {
  //   label: translate('profile-page.nav.link.policy'),
  //   slug: '#',
  //   type: 'link',
  // },
];

export const UserProfile = () => {
  const {
    profileResponse,
    isRemoveUserSuccess,
    isRemoveUserFailure,
  } = useSelector((store) => store.user);
  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutRequest());
  };

  const handleRemoveAccount = () => {
    if (profileResponse?.data && profileResponse?.data?.id) {
      dispatch(removeUserRequest(profileResponse.data.id));
    }
  };

  useEffect(() => {
    if (isRemoveUserSuccess) {
      handleLogout();
    }
  }, [isRemoveUserSuccess]);

  return (
    <>
      <HelmetCustom title={translate('helmet.title.profile')}>
        <div
          id="profile-page"
          className="profile-page--wrapper h-screen pt-4 d-flex flex-column"
        >
          <HeaderChildWithoutCoin
            title={translate('profile-page.title')}
          />
          <div className="profile-page--inner flex-grow-1 d-flex flex-column scroll-y">
            <div className="product-type--img mb-2 flex-grow-1">
              <div className="box-content mk-text-center">
                <ReferralCodeSection />
                <div className="mb-4" />
                {profileResponse?.data?.company && (
                  <p className="mb-0 mk-text-white mk-fz-14 mk-fw-400 mb-2 mk-text-uppercase">
                    {profileResponse.data.company}
                  </p>
                )}
                <p className="mb-0 mk-text-white mk-fz-14 mk-fw-400 mb-2 mk-text-uppercase">
                  {profileResponse?.data?.name}
                </p>
                <p className="mb-0 mk-text-white mk-fz-14 mk-fw-400 mb-2">
                  {profileResponse?.data?.phone}
                </p>
                <p className="mb-0 mk-text-white mk-fz-14 mk-fw-400 mb-4">
                  {profileResponse?.data?.address?.data?.address}
                </p>
                <p className="mb-0 mk-text-white mk-fz-14 mk-fw-400 mk-fs-i">
                  {translate('profile-page.coin-title')}{' '}
                  {profileResponse?.data?.point}{' '}
                  {translate('profile-page.coin-sub-title')}
                </p>
              </div>

              <ul className="profile-page--nav mt-5">
                {navList.map((item, index) => (
                  <li key={index} className="mb-3">
                    {item.type === 'link' ? (
                      <Link
                        to={item.slug}
                        className="mk-fz-16 mk-fw-500 mk-text-hover-secondary-brand-blue"
                      >
                        {item.label}
                      </Link>
                    ) : (
                      <a
                        href={item.slug}
                        className="mk-fz-16 mk-fw-500 mk-text-hover-secondary-brand-blue"
                      >
                        {item.label}
                      </a>
                    )}
                  </li>
                ))}
                <li
                  onClick={() => setIsShowModalConfirm(!isShowModalConfirm)}
                  className="mk-fz-16 mk-fw-500 mk-text-hover-secondary-brand-blue cursor-pointer mb-3"
                >
                  {translate('profile-page.nav.link.remove-account')}
                </li>
                <li
                  onClick={handleLogout}
                  className="mk-fz-16 mk-fw-500 mk-text-hover-secondary-brand-blue cursor-pointer"
                >
                  {translate('profile-page.nav.link.logout')}
                </li>
              </ul>
            </div>
            <ContactButton />
            <div className="mb-4" />
            <FooterInfo />
          </div>
        </div>
      </HelmetCustom>
      {
        isShowModalConfirm && (
          <ModalConfirmRemove
            isOpen={isShowModalConfirm}
            handleClose={() => setIsShowModalConfirm(false)}
            handleRemove={handleRemoveAccount}
          />
        )
      }

      {
        isRemoveUserFailure && (
          <ToastError
            show
            content={translate(
              'profile-page.nav.link.remove-account.failure',
            )}
          />
        )
      }
    </>
  );
};
