export const routesUser = {
  home: '/',
  ordersList: '/orders-list',
  orderDetail: '/orders-list/:orderId',
  productType: '/product-type/:productTypeId',
  blogDetail: '/blogs/:blogId',
  blogs: '/blogs',
  giftsList: '/gifts-list',
  giftDetail: '/gifts-list/:giftId',
  vouchersList: '/vouchers-list',
  voucherDetail: '/vouchers-list/:voucherId',
  profile: '/profile',
  cart: '/cart',
  notifications: '/notifications',
  managerCoin: '/manager-coin',
  information: '/information',
  addressesManager: '/addresses-manager',
};
