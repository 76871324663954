import { memo, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ButtonCustom } from '~/components/form';
import { CSPlusNavigational } from '~/components/iconography/Navigational';
import { CSPenOutline } from '~/components/iconography/Outline';
import { routesAuth } from '~/configs';
import { parseCurrency, translate } from '~/helpers/utils';
import { openModalAddProductRequest } from '~/redux/modal/actions';

export const ProductItem = memo(({ data, isLoadingPrice, productPricesList }) => {
  const { profileResponse } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  const [dataPrice, setDataPrice] = useState(null);
  useEffect(() => {
    if (productPricesList.length > 0) {
      setDataPrice(
        productPricesList.find((item) => item.id === data.id) || null,
      );
    } else {
      setDataPrice(null);
    }
  }, [productPricesList]);

  const handleClickAddItem = (type) => {
    dispatch(openModalAddProductRequest({ data, type }));
  };

  return (
    <div className="product-item--wrapper">
      <div className="product-item--inner flex-end-space">
        <div className="flex-grow-1">
          <p className="mb-0 mk-fz-14 mk-fw-500">{data.name}</p>
          <p className="mb-0 mt-1 mk-text-subtle-black mk-fz-14 mk-fw-400 flex-center-start">
            {isLoadingPrice ? (
              <Skeleton
                count={1}
                width="70px"
                style={{ lineHeight: 'unset' }}
              />
            ) : dataPrice !== null ? (
              <>
                {dataPrice?.price === data.price ? (
                  profileResponse?.data ? parseCurrency(data.price) : <div>{translate('button.required.login')}</div>
                ) : (
                  <>
                    <span
                      style={{ textDecoration: 'line-through', opacity: 0.5 }}
                    >
                      {parseCurrency(data.price)
                        .replace('vnđ', '')
                        .slice(0, -1)}
                    </span>
                    &nbsp;{profileResponse?.data ? parseCurrency(dataPrice.price) : <div>{translate('button.required.login')}</div>}
                  </>
                )}{' '}
                / {data.unit}
              </>
            ) : (
              profileResponse?.data ? parseCurrency(data.price) : <Link to={routesAuth.login}>{translate('button.required.login')}</Link>
            )}

            {isLoadingPrice ? (
              <ButtonCustom
                disabled
                rounded={false}
                size="s"
                color="secondary-brand-blue"
                className="flex-center-center p-0 btn-add-item"
                style={{ width: '20px', height: '20px', marginLeft: '20px' }}
              >
                <CSPlusNavigational className="stroke-white" />
              </ButtonCustom>
            ) : dataPrice === null ? (
              <ButtonCustom
                rounded={false}
                size="s"
                color="secondary-brand-blue"
                className="flex-center-center p-0 btn-add-item"
                style={{ width: '20px', height: '20px', marginLeft: '20px' }}
                onClick={() => handleClickAddItem()}
              >
                <CSPlusNavigational className="stroke-white" />
              </ButtonCustom>
            ) : (
              <>
                <span
                  className="mk-fw-500 mk-text-black"
                  style={{ marginLeft: '10px' }}
                >
                  x {dataPrice.number}
                </span>
                <span
                  style={{ marginLeft: '20px', marginTop: '-5px' }}
                  onClick={() => handleClickAddItem('edit')}
                >
                  <CSPenOutline
                    style={{ width: '15px', height: '15px' }}
                    className="fill-subtle-black hover-fill-brand-blue cursor-pointer"
                  />
                </span>
              </>
            )}
          </p>
        </div>
        {!isLoadingPrice && dataPrice !== null && (
          <p className="mb-0 mk-text-secondary-brand-blue mk-fz-14 mk-fw-500">
            {parseCurrency(dataPrice.price * dataPrice.number)}
          </p>
        )}
      </div>
    </div>
  );
});
