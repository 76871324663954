import LogoWithTextUrl from '~/assets/images/logo/logo-with-text.png';

export const FirstScreenLogin = ({ isShowFirstScreen }) => (
  <div
    className={`login-page-first-screen--wrapper ${isShowFirstScreen ? 'show' : 'hidden'}`}
  >
    <div className="image-wrapper">
      <img src={LogoWithTextUrl} alt="Meko Trading" />
    </div>
  </div>
);
