import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { InputSelect } from '~/components/form';
import { translate } from '~/helpers/utils';

export const AddressCustom = memo(
  ({
    addressCustom,
    setAddressCustom,
    setErrMsgAddressCustom,
    isDisabled,
    isEditFirstRender,
    setIsEditFirstRender,
  }) => {
    const { isGetRegionsSuccess, regionsResponse } = useSelector(
      (store) => store.region,
    );

    const [provincesList, setProvincesList] = useState([]);
    const [districtsList, setDistrictsList] = useState([]);
    const [wardsList, setWardsList] = useState([]);

    useEffect(() => {
      if (isGetRegionsSuccess) {
        setProvincesList(
          regionsResponse.data.filter((item) => item.parent_id === 0),
        );
        setDistrictsList(
          regionsResponse.data.filter((item) => item.parent_id !== 0),
        );
      }
    }, [isGetRegionsSuccess]);

    useEffect(() => {
      if (isGetRegionsSuccess) {
        if (addressCustom.province) {
          setDistrictsList(
            regionsResponse.data.filter(
              (item) => item.parent_id === parseInt(addressCustom.province),
            ),
          );
        } else {
          setDistrictsList(
            regionsResponse.data.filter((item) => item.parent_id !== 0),
          );
        }
        if (!isEditFirstRender) {
          setAddressCustom((currentAddressCustom) => ({
            ...currentAddressCustom,
            district: '',
          }));
        }
      }
    }, [addressCustom.province]);

    useEffect(() => {
      if (isGetRegionsSuccess) {
        if (addressCustom.district) {
          setWardsList(
            regionsResponse.data.filter(
              (item) => item.parent_id === parseInt(addressCustom.district),
            ),
          );
        } else {
          setWardsList([]);
        }
        if (!isEditFirstRender) {
          setAddressCustom((currentAddressCustom) => ({
            ...currentAddressCustom,
            ward: '',
          }));
        } else {
          setIsEditFirstRender(false);
        }
      }
    }, [addressCustom.district]);

    const handleSelect = (value, type) => {
      setAddressCustom((currentAddressCustom) => ({
        ...currentAddressCustom,
        [type]: value,
      }));
    };

    return (
      <>
        <InputSelect
          id="province"
          name="province"
          className={addressCustom.province === '' ? 'color-placeholder' : ''}
          value={addressCustom.province}
          onChange={(e) => handleSelect(e.target.value, 'province')}
          onBlur={() => setErrMsgAddressCustom((currentErrMsgAddressCustom) => ({
            ...currentErrMsgAddressCustom,
            province: { ...currentErrMsgAddressCustom.province, touch: true },
          }))}
          disabled={isDisabled}
        >
          <option value="">
            {translate('input.address-custom-2.placeholder')}
          </option>
          {provincesList.map((item, index) => (
            <option key={index} value={item.id}>
              {item.name}
            </option>
          ))}
        </InputSelect>
        <div className="flex-center-center">
          <InputSelect
            id="district"
            name="district"
            className={addressCustom.district === '' ? 'color-placeholder' : ''}
            value={addressCustom.district}
            onChange={(e) => handleSelect(e.target.value, 'district')}
            onBlur={() => setErrMsgAddressCustom((currentErrMsgAddressCustom) => ({
              ...currentErrMsgAddressCustom,
              district: {
                ...currentErrMsgAddressCustom.district,
                touch: true,
              },
            }))}
            disabled={isDisabled}
          >
            <option value="">
              {translate('input.address-custom-3.placeholder')}
            </option>
            {districtsList.map((item, index) => (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            ))}
          </InputSelect>
          <InputSelect
            id="ward"
            name="ward"
            className={addressCustom.ward === '' ? 'color-placeholder' : ''}
            value={addressCustom.ward}
            onChange={(e) => handleSelect(e.target.value, 'ward')}
            onBlur={() => setErrMsgAddressCustom((currentErrMsgAddressCustom) => ({
              ...currentErrMsgAddressCustom,
              ward: { ...currentErrMsgAddressCustom.ward, touch: true },
            }))}
            disabled={isDisabled}
          >
            <option value="">
              {translate('input.address-custom-4.placeholder')}
            </option>
            {wardsList.map((item, index) => (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            ))}
          </InputSelect>
        </div>
      </>
    );
  },
);
