const vnManagerCoinPageLangLib = {
  'manager-coin-page.title': 'Quản lý trứng vàng',
  'manager-coin-page.sub-title-1': 'Tích lũy:',
  'manager-coin-page.sub-title-2': 'Ngày quy đổi:',
  'manager-coin-page.nav-1': 'Lịch sử',
  'manager-coin-page.nav-2': 'Quà tặng',
  'manager-coin-page.nav-3': 'Voucher',
  'manager-coin-page.nav-4': 'Chưa sử dụng',
  'manager-coin-page.nav-5': 'Đã sử dụng',
  'manager-coin-page.nav-6': 'Tất cả',
};

export default vnManagerCoinPageLangLib;
