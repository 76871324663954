/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllBlogs({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/blogs', {
      params: payload,
    }));
    yield put(Actions.getAllBlogsSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllBlogsFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllBlogsRequest, getAllBlogs);
}
