import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { useFormik } from 'formik';
import { memo, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { ButtonCustom, InputText } from '~/components/form';
import { auth } from '~/firebase.config';
import { translate } from '~/helpers/utils';
import { checkUserExistsRequest } from '~/redux/auth/actions';

export const ForgotPasswordFirstScreen = memo(
  ({ setIsShowFirstScreen, isLoadingSendOtp, setIsLoadingSendOtp }) => {
    const {
      isCheckUserExistsRequest,
      isCheckUserExistsSuccess,
      checkUserExistsResponse,
    } = useSelector((store) => store.auth);
    const dispatch = useDispatch();

    const [isShowErrMsgSendOtp, setIsShowErrMsgSendOtp] = useState(false);
    const [errMsgSendOtp, setErrMsgSendOtp] = useState('');

    const formik = useFormik({
      initialValues: {
        phoneNumber: '',
      },
      validationSchema: Yup.object({
        phoneNumber: Yup.string()
          .trim()
          .required(translate('input.phone-number.error.required'))
          .min(10, translate('input.phone-number.error.invalid'))
          .max(10, translate('input.phone-number.error.invalid')),
      }),
      onSubmit: (values) => {
        setIsShowErrMsgSendOtp(false);
        setErrMsgSendOtp('');
        dispatch(
          checkUserExistsRequest({ type: 'phone', phone: values.phoneNumber }),
        );
      },
    });

    useEffect(() => {
      if (
        isCheckUserExistsSuccess
        && checkUserExistsResponse.data.id !== null
      ) {
        onSendOtp();
      }
    }, [isCheckUserExistsSuccess, checkUserExistsResponse]);

    const onCaptchaVerify = () => {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          'recaptcha-container',
          {
            size: 'invisible',
            callback: () => {
              onSendOtp();
            },
            'expired-callback': () => { },
          },
          auth,
        );
      }
    };

    const onSendOtp = () => {
      setIsLoadingSendOtp(true);
      onCaptchaVerify();

      const appVerifier = window.recaptchaVerifier;

      const phoneFormat = '+84'.concat(formik.values.phoneNumber.substring(1));
      signInWithPhoneNumber(auth, phoneFormat, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setIsLoadingSendOtp(false);
          setIsShowFirstScreen(false);
        })
        .catch((error) => {
          console.error('error: ', error);
          setIsLoadingSendOtp(false);
          setIsShowErrMsgSendOtp(true);
          setErrMsgSendOtp(
            error.message.includes('verification failed') ? '' : error.message.includes('many-requests')
              ? translate('input.otp.error.many-requests')
              : translate('input.otp.error.send-failure'),
          );
        });
    };

    return (
      <Form noValidate onSubmit={formik.handleSubmit}>
        <div className="forgot-password-page--first-screen">
          <div id="recaptcha-container" />
          <h3 className="mb-3 mk-fz-16 mk-fw-500">
            {translate('forgot-password-page.first-screen.title')}
          </h3>
          <InputText
            id="phoneNumber"
            name="phoneNumber"
            type="number"
            placeholder={translate('input.phone-number.placeholder')}
            isRequired
            {...formik.getFieldProps('phoneNumber')}
            onChange={(e) => formik.setFieldValue('phoneNumber', e.target.value.toString())}
            isValid={formik.touched.phoneNumber && !formik.errors.phoneNumber}
            isInvalid={
              isShowErrMsgSendOtp
              || (formik.touched.phoneNumber && formik.errors.phoneNumber)
            }
            isError={
              isShowErrMsgSendOtp
              || (formik.touched.phoneNumber && formik.errors.phoneNumber)
            }
            msgError={errMsgSendOtp || formik.errors.phoneNumber}
            disabled={isCheckUserExistsRequest || isLoadingSendOtp}
          />
          <div className="mb-3" />
          <ButtonCustom
            disabled={isCheckUserExistsRequest || isLoadingSendOtp}
            isLoading={isCheckUserExistsRequest || isLoadingSendOtp}
            type="submit"
            className="w-100"
          >
            {translate('button.send-otp.title')}
          </ButtonCustom>
          <div className="mb-5" />
          <p className="mk-text-center mk-fz-16 mk-fw-500 mb-1">
            {translate('forgot-password-page.first-screen.sub-title-1')}
          </p>
          <p
            className="mk-fz-16 mk-fw-500"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: translate(
                'forgot-password-page.first-screen.sub-title-2',
              ).replace(
                '$x',
                `<a href="${isCheckUserExistsRequest || isLoadingSendOtp
                  ? '#'
                  : 'tel:0988434937'
                }" class="mk-text-link mk-text-brand-blue mk-text-hover-secondary-brand-blue mk-fw-600">0988.434.937 - Lâm</a>`,
              ),
            }}
          />
        </div>
      </Form>
    );
  },
);
