import { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import Skeleton from 'react-loading-skeleton';
import BannerHomepageUrl1 from '~/assets/images/banner/banner-homepage.png';
import EggCoinUrl from '~/assets/images/common/egg-coin.png';
import { Banner, HeaderChildWithoutCoin } from '~/components/common';
import { HelmetCustom } from '~/components/helmetCustom';
import { translate } from '~/helpers/utils';
import { GiftTab, HistoryTab, VoucherTab } from './components';

export const UserManagerCoin = () => {
  const { isGetProfileRequest, profileResponse, configResponse } = useSelector(
    (store) => store.user,
  );

  const [eventKey, setEventKey] = useState('2');

  const changeEventKey = (key) => {
    setEventKey(key);
  };

  return (
    <HelmetCustom title={translate('helmet.title.manager-coin')}>
      <div
        id="user-manager-coin-page"
        className="user-manager-coin-page--wrapper pt-4"
      >
        <HeaderChildWithoutCoin
          title={translate('manager-coin-page.title')}
        />
        <Banner src={configResponse?.data?.banner?.banner_point || BannerHomepageUrl1} alt="Meko-Banner-Main" />
        <p
          className="flex-end-start mt-3 mk-fz-14 mk-fw-500"
          style={{ lineHeight: '28px' }}
        >
          {translate('manager-coin-page.sub-title-1')}&nbsp;
          <span className="flex-center-center mk-fz-28 mk-fw-700 mk-text-brand-orange">
            {isGetProfileRequest ? (
              <Skeleton
                count={1}
                width="150px"
                height="32px"
                style={{ lineHeight: 'unset' }}
              />
            ) : (
              <>
                {profileResponse?.data?.point}{' '}
                <img
                  src={EggCoinUrl}
                  alt="egg-coin"
                  style={{ width: '23px', height: '23px', marginRight: '3px' }}
                />
              </>
            )}
          </span>
        </p>
        <Tab.Container
          className="manager-coin-page--tab-container"
          defaultActiveKey="2"
          activeKey={eventKey}
          onSelect={changeEventKey}
        >
          <div className="manager-coin-page--tab-nav">
            <Nav variant="pills" className="flex-center-space">
              <Nav.Item className="flex-grow-1">
                <Nav.Link
                  eventKey="1"
                  className="flex-center-center mk-text-subtle-black mk-fz-14 mk-fw-500 mk-text-hover-brand-orange"
                >
                  {translate('manager-coin-page.nav-1')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="flex-grow-1">
                <Nav.Link
                  eventKey="2"
                  className="flex-center-center mk-text-subtle-black mk-fz-14 mk-fw-500 mk-text-hover-brand-orange"
                >
                  {translate('manager-coin-page.nav-2')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="flex-grow-1">
                <Nav.Link
                  eventKey="3"
                  className="flex-center-center mk-text-subtle-black mk-fz-14 mk-fw-500 mk-text-hover-brand-orange"
                >
                  {translate('manager-coin-page.nav-3')}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <div className="manager-coin-page--tab-content">
            <Tab.Content
              className="tab-content-personal mt-0 pr0"
              style={{ height: '100%' }}
            >
              {String(eventKey) === '1' && (
                <Tab.Pane eventKey="1">
                  <HistoryTab />
                </Tab.Pane>
              )}
              {String(eventKey) === '2' && (
                <Tab.Pane eventKey="2">
                  <GiftTab />
                </Tab.Pane>
              )}
              {String(eventKey) === '3' && (
                <Tab.Pane eventKey="3">
                  <VoucherTab />
                </Tab.Pane>
              )}
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    </HelmetCustom>
  );
};
