const vnRegisterPageLangLib = {
  'register-page.first-screen.title': 'Tạo tài khoản mới',
  'register-page.second-screen.title': 'Thông tin tài khoản',
  'register-page.dont-have-account.title': 'Bạn đã có tài khoản?',
  'register-page.register-success.title': 'Đăng ký thành công',
  'register-page.register-success.description': 'Chúc mừng bạn $x đã đăng ký tài khoản thành công.',
  'register-page.register-success.sub-title': '$x - Chuyên viên chăm sóc khách hàng sẽ đồng hành cùng bạn.',
  'register-page.auto-return-login.title': 'Đi đến trang đăng nhập ($xs)',
  'register-page.register-failure.title': 'Đăng ký tài khoản không thành công! Vui lòng thử lại sau.',
};

export default vnRegisterPageLangLib;
