const vnButtonLangLib = {
  'button.login.title': 'Đăng nhập',
  'button.sign-up.title': 'Đăng ký',
  'button.forgot-password.title': 'Quên mật khẩu?',
  'button.update.title': 'Cập nhật',
  'button.next.title': 'Tiếp theo',
  'button.back.title': 'Quay lại',
  'button.send-otp.title': 'Gửi mã OTP',
  'button.update-new-password.title': 'Cập nhật mật khẩu mới',
  'button.create-account.title': 'Tạo tài khoản',
  'button.purchase.title': 'Mua hàng',
  'button.order.title': 'Giỏ hàng',
  'button.add.title': 'Thêm',
  'button.cancel.title': 'Hủy',
  'button.see-all.title': 'Xem tất cả',
  'button.view-detail.title': 'Xem chi tiết',
  'button.save.title': 'Lưu',
  'button.required.login': 'Vui lòng đăng nhập để xem chi tiết',
  'button.remove-account': 'Xóa tài khoản',
};

export default vnButtonLangLib;
