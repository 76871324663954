import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import DuckLogoUrl from '~/assets/images/logo/duck.png';
import LogoWithTextUrl from '~/assets/images/logo/logo-with-text.png';
import { Pyro } from '~/components/common';
import { routesAuth } from '~/configs';
import { formattedPhoneNumber, translate } from '~/helpers/utils';

export const RegisterSuccessScreen = memo(
  ({ isShowRegisterSuccessScreen, dataFormRegister }) => {
    const history = useHistory();
    const {
      registerResponse,
    } = useSelector((state) => state.auth);
    const { configResponse } = useSelector((store) => store.user);

    const [seconds, setSeconds] = useState(5);

    useEffect(() => {
      let interval;
      if (isShowRegisterSuccessScreen) {
        interval = setInterval(() => {
          const newSeconds = seconds - 1;
          if (newSeconds >= 0) {
            setSeconds((currentSeconds) => currentSeconds - 1);
          } else {
            clearInterval(interval);
            history.push(routesAuth.login);
          }
        }, 1000);
      }

      return () => clearInterval(interval);
    }, [isShowRegisterSuccessScreen, seconds]);

    return (
      <div
        className={`register-success-screen--wrapper${isShowRegisterSuccessScreen ? ' show' : ''}`}
      >
        <div className="register-success-screen--inner">
          <div className="register-success-screen--header">
            <img src={LogoWithTextUrl} alt="Meko Trading" />
          </div>
          <div className="register-success-screen--main mk-text-center">
            <h2 className="mk-fz-20 mk-fw-700 mk-text-uppercase mk-text-brand-blue">
              {translate('register-page.register-success.title')}
            </h2>
            <p
              className="mk-fz-16 mk-fw-500 mb-1"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: translate(
                  'register-page.register-success.description',
                ).replace(
                  '$x',
                  `<span class="mk-text-brand-blue mk-fw-600">${dataFormRegister.name}</span>`,
                ),
              }}
            />
            <p
              className="mk-fz-16 mk-fw-500"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: translate(
                  'register-page.register-success.sub-title',
                ).replace(
                  '$x',
                  `<a href={tel:${registerResponse?.data?.staff?.data?.phone || '0988434237'}} class="mk-text-link mk-text-brand-blue mk-fw-600 mk-text-hover-secondary-brand-blue">${formattedPhoneNumber(registerResponse?.data?.staff?.data?.phone) || formattedPhoneNumber(configResponse?.data?.staff?.phone)} - ${registerResponse?.data?.staff?.data?.name || configResponse?.data?.staff?.name}</a>`,
                ),
              }}
            />
            <img
              src={DuckLogoUrl}
              alt="Meko Trading"
              className="m-auto"
              style={{ width: '200px' }}
            />
            <p className="mk-fz-14 mk-fw-600 mk-text-brand-blue mk-fs-i mt-5">
              {translate('register-page.auto-return-login.title').replace(
                '$x',
                seconds,
              )}
            </p>
          </div>
          <Pyro isShow={isShowRegisterSuccessScreen} isHide={seconds < 4} />
        </div>
      </div>
    );
  },
);
