/* eslint-disable react/no-danger */
import { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DuckLogoUrl from '~/assets/images/logo/duck.png';
import LogoWithTextUrl from '~/assets/images/logo/logo-with-text.png';
import { Pyro } from '~/components/common';
import { routesAuth } from '~/configs';
import { translate } from '~/helpers/utils';

export const ChangePasswordSuccessScreen = memo(
  ({ isShowChangePasswordSuccessScreen, checkUserExistsResponse }) => {
    const history = useHistory();

    const [seconds, setSeconds] = useState(5);

    useEffect(() => {
      let interval;
      if (isShowChangePasswordSuccessScreen) {
        interval = setInterval(() => {
          const newSeconds = seconds - 1;
          if (newSeconds >= 0) {
            setSeconds((currentSeconds) => currentSeconds - 1);
          } else {
            clearInterval(interval);
            history.push(routesAuth.login);
          }
        }, 1000);
      }

      return () => clearInterval(interval);
    }, [isShowChangePasswordSuccessScreen, seconds]);

    return (
      <div
        className={`change-password-success-screen--wrapper${isShowChangePasswordSuccessScreen ? ' show' : ''}`}
      >
        <div className="change-password-success-screen--inner">
          <div className="change-password-success-screen--header">
            <img src={LogoWithTextUrl} alt="Meko Trading" />
          </div>
          <div className="change-password-success-screen--main mk-text-center">
            <h2 className="mk-fz-20 mk-fw-700 mk-text-uppercase mk-text-brand-blue">
              {translate('forgot-password-page.change-password-success.title')}
            </h2>
            <p
              className="mk-fz-16 mk-fw-500"
              dangerouslySetInnerHTML={{
                __html: translate(
                  'forgot-password-page.change-password-success.description',
                ).replace(
                  '$x',
                  `<span class="mk-text-brand-blue mk-fw-600">${checkUserExistsResponse.data
                    ? checkUserExistsResponse?.data.name
                    || checkUserExistsResponse?.data.phone
                    : ''
                  }</span>`,
                ),
              }}
            />
            <img
              src={DuckLogoUrl}
              alt="Meko Trading"
              className="m-auto"
              style={{ width: '200px' }}
            />
            <p className="mk-fz-14 mk-fw-600 mk-text-brand-blue mk-fs-i mt-5">
              {translate(
                'forgot-password-page.auto-return-login.title',
              ).replace('$x', seconds)}
            </p>
          </div>
          <Pyro
            isShow={isShowChangePasswordSuccessScreen}
            isHide={seconds < 4}
          />
        </div>
      </div>
    );
  },
);
