import { memo, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { routesUser } from '~/configs';
import { translate } from '~/helpers/utils';

export const CategoriesList = memo(() => {
  const { isGetConfigRequest, isGetConfigSuccess, configResponse } = useSelector((store) => store.user);

  const [productTypesList, setProductTypesList] = useState([]);

  useEffect(() => {
    if (isGetConfigSuccess) {
      const productTypesListTmp = configResponse.data.types.filter(
        (item) => item.name !== 'Mẫu' && item.note !== 'Mẫu',
      );
      setProductTypesList(productTypesListTmp);
    }
  }, [isGetConfigSuccess, configResponse]);
  return (
    <section className="home-page--categories-list">
      <div className="categories-list--inner mt-5 mb-4">
        <div className="flex-center-space mb-3">
          <h3 className="section-title">
            {translate('home-page.categories-list.title')}
          </h3>
        </div>
        <div className="categories-list">
          {isGetConfigRequest
            ? [...new Array(2)].map((_, index) => (
              <div key={index} className="category-item">
                <Skeleton
                  count={1}
                  width="100%"
                  style={{ lineHeight: 'unset', aspectRatio: '1/1' }}
                />
                <Skeleton
                  count={1}
                  width="100%"
                  height="20px"
                  style={{ lineHeight: 'unset', marginTop: '8px' }}
                />
              </div>
            ))
            : productTypesList.map((item, index) => (
              <div key={index} className="category-item">
                <Link
                  to={routesUser.productType.replace(
                    ':productTypeId',
                    item.id,
                  )}
                  className="mk-text-hover-secondary-brand-blue"
                >
                  <img src={item.image} alt={item.name} draggable={false} />
                  <p className="mt-2 mb-0 mk-fz-14 mk-fw-500 mk-truncate-2">
                    {item.name}
                  </p>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
});
