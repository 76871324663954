import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import LogoWithTextUrl from '~/assets/images/logo/logo-with-text.png';
import { ButtonCustom, InputPassword, InputText } from '~/components/form';
import { routesAuth, routesUser } from '~/configs';
import { ToastError } from '~/helpers/funcs';
import { translate } from '~/helpers/utils';
import {
  checkUserExistsRequest,
  loginRequest,
  resetAuthState,
} from '~/redux/auth/actions';
import { HelmetCustom } from '~/components/helmetCustom';
import { CSArrowLeftNavigational } from '~/components/iconography/Navigational';
import { FirstScreenLogin } from './FirstScreenLogin';

export const LoginPage = () => {
  const {
    isLoginRequest,
    isLoginSuccess,
    isLoginFailure,
    isCheckUserExistsRequest,
    isCheckUserExistsSuccess,
    checkUserExistsResponse,
  } = useSelector((store) => store.auth);
  const dispatch = useDispatch();

  const history = useHistory();

  const [isShowFirstScreen, setIsShowFirstScreen] = useState(true);

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      password: '',
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .trim()
        .required(translate('input.phone-number.error.required')),
      password: Yup.string()
        .trim()
        .required(translate('input.password.error.required')),
    }),
    onSubmit: (values) => {
      dispatch(resetAuthState());
      dispatch(
        checkUserExistsRequest({ type: 'phone', phone: values.phoneNumber }),
      );
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setIsShowFirstScreen(false);
    }, 500);

    return () => {
      dispatch(resetAuthState());
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (isCheckUserExistsSuccess && checkUserExistsResponse.data.id !== null) {
      const payload = {
        username: formik.values.phoneNumber,
        password: formik.values.password,
      };
      dispatch(loginRequest(payload));
    }
  }, [isCheckUserExistsSuccess, checkUserExistsResponse]);

  useEffect(() => {
    if (isLoginSuccess) {
      history.push(routesUser.home);
    }
  }, [isLoginSuccess]);
  return (
    <HelmetCustom title={translate('helmet.title.login-page')}>
      <div id="login-page" className="login-page--wrapper">
        <FirstScreenLogin isShowFirstScreen={isShowFirstScreen} />
        <div className="header-child-without-coin--inner flex-center-space" style={{ position: 'relative', top: '5%' }} onClick={() => history.push(routesUser.home)}>
          <span
            className="mk-fz-14 mk-fw-400 mk-text-subtle-black mk-text-link flex-center-center gap-2 hover-stroke-brand-blue mk-text-hover-brand-blue mt-4"
          >
            <CSArrowLeftNavigational
              className="mk-fz-12 stroke-subtle-black"
              style={{ width: '18px', height: '18px' }}
            />
            {translate('button.back.title')}
          </span>
        </div>
        <div
          className={`login-page--inner${!isShowFirstScreen ? ' show' : ''}`}
        >
          <Link to={routesUser.home} className="login-page--header">
            <img src={LogoWithTextUrl} alt="Meko Trading" />
          </Link>
          <div className="login-page--form">
            <Form noValidate onSubmit={formik.handleSubmit}>
              <h3 className="mb-3 mk-fz-16 mk-fw-500">
                {translate('login-page.title')}
              </h3>
              <InputText
                id="phoneNumber"
                name="phoneNumber"
                type="number"
                placeholder={translate('input.phone-number.placeholder')}
                isRequired
                {...formik.getFieldProps('phoneNumber')}
                onChange={(e) => formik.setFieldValue('phoneNumber', e.target.value.toString())}
                isValid={
                  formik.touched.phoneNumber && !formik.errors.phoneNumber
                }
                isInvalid={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
                isError={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
                msgError={formik.errors.phoneNumber}
                disabled={isCheckUserExistsRequest || isLoginRequest}
              />
              <div className="mb-3" />
              <InputPassword
                id="password"
                name="password"
                placeholder={translate('input.password.placeholder')}
                isRequired
                {...formik.getFieldProps('password')}
                isValid={formik.touched.password && !formik.errors.password}
                isInvalid={formik.touched.password && formik.errors.password}
                isError={formik.touched.password && formik.errors.password}
                msgError={formik.errors.password}
                disabled={isCheckUserExistsRequest || isLoginRequest}
              />
              <div className="mb-3" />
              <div className="mk-text-right mk-text-secondary-brand-blue mk-fw-600 mk-fz-14">
                <Link
                  to={
                    isCheckUserExistsRequest || isLoginRequest
                      ? '#'
                      : routesAuth.forgotPassword
                  }
                  className={`mk-text-link mk-text-hover-brand-blue${isCheckUserExistsRequest || isLoginRequest ? ' disabled' : ''}`}
                >
                  {translate('button.forgot-password.title')}
                </Link>
              </div>
              <div className="mb-5" />
              <ButtonCustom
                disabled={isCheckUserExistsRequest || isLoginRequest}
                isLoading={isCheckUserExistsRequest || isLoginRequest}
                type={
                  isCheckUserExistsRequest || isLoginRequest
                    ? 'button'
                    : 'submit'
                }
                className="w-100"
              >
                {translate('button.login.title')}
              </ButtonCustom>
            </Form>
          </div>
          <div className="login-page--footer">
            <p className="mk-text-center mk-fz-14 mk-fw-500 mb-0">
              {translate('login-page.dont-have-account.title')}{' '}
              <Link
                to={
                  isCheckUserExistsRequest || isLoginRequest
                    ? '#'
                    : routesAuth.register
                }
                className={`mk-fw-600 mk-text-secondary-brand-blue mk-text-link mk-text-hover-brand-blue${isCheckUserExistsRequest || isLoginRequest ? ' disabled' : ''}`}
              >
                {translate('button.sign-up.title')}
              </Link>
            </p>
          </div>
        </div>
      </div>
      {isCheckUserExistsSuccess && checkUserExistsResponse.data.id === null && (
        <ToastError
          show
          content={translate(
            'login-page.login-failure.dont-have-account.title',
          )}
        />
      )}
      {isLoginFailure && (
        <ToastError
          show
          content={translate('login-page.login-failure.wrong-password.title')}
        />
      )}
    </HelmetCustom>
  );
};
