import { memo } from 'react';
import { Form } from 'react-bootstrap';

export const InputCheckbox = memo(
  ({
    labelFor, labelContent, isError, msgError, ...rest
  }) => (
    <div className="input-custom-checkbox">
      <input className="form-check-input" type="checkbox" {...rest} />
      {labelContent && (
        <Form.Label htmlFor={labelFor}>{labelContent}</Form.Label>
      )}
      {isError && (
        <div className="cs-text-red cs-fz-14 cs-fw-400 w-100">{msgError}</div>
      )}
    </div>
  ),
);
