const vnVoucherDetailPageLangLib = {
  'voucher-detail-page.title': 'Đổi voucher',
  'voucher-detail-page.btn-unqualified': 'Bạn chưa đủ số trứng tích lũy',
  'voucher-detail-page.btn-exchange': 'Đổi voucher',
  'voucher-detail-page.success-screen.title': 'Chúc mừng bạn đổi voucher thành công',
  'voucher-detail-page.success-screen.description': 'Voucher của bạn đã được đổi thành công và sẵn sằng để sử dụng.',
  'voucher-detail-page.success-screen.btn-again': 'Tiếp tục đổi voucher',
  'voucher-detail-page.success-screen.btn-cancel': 'Trang chủ ($x)',
  'voucher-detail-page.exchange-error-msg': 'Đổi voucher không thành công! Vui lòng thử lại sau.',
  'voucher-detail-page.detail-title-1': 'Trị giá:',
  'voucher-detail-page.detail-title-2': 'Quy định đổi voucher / quà tặng',
  'voucher-detail-page.detail-title-3': 'Quy định chung:',
  'voucher-detail-page.detail-title-4': '- Sau khi đổi Trứng Vàng thành voucher / quà tặng sẽ không được chuyển đổi lại.',
  'voucher-detail-page.detail-title-5': 'Voucher:',
  'voucher-detail-page.detail-title-6': '- Voucher sẽ được áp dụng cho đơn hàng gần nhất.',
  'voucher-detail-page.detail-title-7': 'Quà tặng:',
  'voucher-detail-page.detail-title-8': '- Sẽ được gửi quà đến tay quý khách hàng trong thời gian sớm nhất sau khi được quy đổi.',
};

export default vnVoucherDetailPageLangLib;
