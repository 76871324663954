import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { InputSelect } from '~/components/form';
import { translate } from '~/helpers/utils';

export const AddressCustomSection = memo(
  ({
    touched, setTouched, addressCustom, setAddressCustom, isAutoMapDistrict,
    setIsAutoMapDistrict,
    isAutoMapWard,
    setIsAutoMapWard,
  }) => {
    const { isGetRegionsRequest, isGetRegionsSuccess, regionsResponse } = useSelector((store) => store.region);
    const { isRegisterRequest } = useSelector((store) => store.auth);

    const [provincesList, setProvincesList] = useState([]);
    const [districtsList, setDistrictsList] = useState([]);
    const [wardsList, setWardsList] = useState([]);

    useEffect(() => {
      if (isGetRegionsSuccess) {
        setProvincesList(
          regionsResponse.data.filter((item) => item.parent_id === 0),
        );
        setDistrictsList(
          regionsResponse.data.filter((item) => item.parent_id !== 0),
        );
      }
    }, [isGetRegionsSuccess]);

    useEffect(() => {
      if (isGetRegionsSuccess) {
        if (addressCustom.province) {
          setDistrictsList(
            regionsResponse.data.filter(
              (item) => item.parent_id === parseInt(addressCustom.province),
            ),
          );
        } else {
          setDistrictsList(
            regionsResponse.data.filter((item) => item.parent_id !== 0),
          );
        }
        if (isAutoMapDistrict) {
          setIsAutoMapDistrict(false);
        } else {
          setAddressCustom((currentAddressCustom) => ({
            ...currentAddressCustom,
            district: '',
          }));
        }
      }
    }, [addressCustom.province]);

    useEffect(() => {
      if (isGetRegionsSuccess) {
        if (addressCustom.district) {
          setWardsList(
            regionsResponse.data.filter(
              (item) => item.parent_id === parseInt(addressCustom.district),
            ),
          );
        } else {
          setWardsList([]);
        }
        if (isAutoMapWard) {
          setIsAutoMapWard(false);
        } else {
          setAddressCustom((currentAddressCustom) => ({
            ...currentAddressCustom,
            ward: '',
          }));
        }
      }
    }, [addressCustom.district]);

    const handleSelect = (value, type) => {
      setAddressCustom((currentAddressCustom) => ({
        ...currentAddressCustom,
        [type]: value,
      }));
    };

    const handleOnBlur = (type) => {
      setTouched((currentTouched) => ({
        ...currentTouched,
        [type]: {
          ...currentTouched[type],
          touch: true,
        },
      }));
    };

    return (
      <>
        <InputSelect
          id="province"
          name="province"
          className={addressCustom.province === '' ? 'color-placeholder' : {}}
          isRequired
          value={addressCustom.province}
          onChange={(e) => handleSelect(e.target.value, 'province')}
          onBlur={() => handleOnBlur('province')}
          disabled={isGetRegionsRequest || isRegisterRequest}
          isValid={addressCustom.province !== ''}
          isInvalid={touched?.province?.touch && addressCustom.province === ''}
          isError={touched?.province?.touch && addressCustom.province === ''}
          msgError={touched.province.errorMsg}
        >
          <option value="">{translate('input.address-custom-2.placeholder')}</option>
          {provincesList.map((data, index) => (
            <option key={index} value={data.id}>
              {data.name}
            </option>
          ))}
        </InputSelect>
        <div className="mb-3" />
        <div className="flex-start-center gap-2">
          <InputSelect
            id="district"
            name="district"
            className={addressCustom.district === '' ? 'color-placeholder' : {}}
            isRequired
            value={addressCustom.district}
            onChange={(e) => handleSelect(e.target.value, 'district')}
            onBlur={() => handleOnBlur('district')}
            disabled={isGetRegionsRequest || isRegisterRequest}
            isValid={addressCustom.district !== ''}
            isInvalid={touched.district.touch && addressCustom.district === ''}
            isError={touched.district.touch && addressCustom.district === ''}
            msgError={touched.district.errorMsg}
          >
            <option value="">{translate('input.address-custom-3.placeholder')}</option>
            {districtsList.map((data, index) => (
              <option key={index} value={data.id}>
                {data.name}
              </option>
            ))}
          </InputSelect>
          <InputSelect
            id="ward"
            name="ward"
            className={addressCustom.ward === '' ? 'color-placeholder' : {}}
            isRequired
            value={addressCustom.ward}
            onChange={(e) => handleSelect(e.target.value, 'ward')}
            onBlur={() => handleOnBlur('ward')}
            disabled={isGetRegionsRequest || isRegisterRequest}
            isValid={addressCustom.ward !== ''}
            isInvalid={touched.ward.touch && addressCustom.ward === ''}
            isError={touched.ward.touch && addressCustom.ward === ''}
            msgError={touched.ward.errorMsg}
          >
            <option value="">{translate('input.address-custom-4.placeholder')}</option>
            {wardsList.map((data, index) => (
              <option key={index} value={data.id}>
                {data.name}
              </option>
            ))}
          </InputSelect>
        </div>
      </>
    );
  },
);
