import { useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';

import { HeaderChildWithCoin } from '~/components/common';
import { HelmetCustom } from '~/components/helmetCustom';
import { translate } from '~/helpers/utils';
import {
  OrdersListCancel,
  OrdersListPending,
  OrdersListSuccess,
} from './components';

export const UserOrdersList = () => {
  const [eventKey, setEventKey] = useState('1');

  const changeEventKey = (key) => {
    setEventKey(key);
  };

  return (
    <HelmetCustom title={translate('helmet.title.orders-list')}>
      <div
        id="user-orders-list-page"
        className="user-orders-list-page--wrapper pt-4"
      >
        <HeaderChildWithCoin
          title={translate('orders-list-page.title')}
        />
        <Tab.Container
          className="orders-list-page--tab-container"
          defaultActiveKey="1"
          activeKey={eventKey}
          onSelect={changeEventKey}
        >
          <div className="orders-list-page--tab-nav">
            <Nav variant="pills" className="flex-center-space">
              <Nav.Item className="flex-grow-1">
                <Nav.Link
                  eventKey="1"
                  className="flex-center-center mk-text-subtle-black mk-fz-16 mk-fw-500 mk-text-hover-brand-orange"
                >
                  {translate('orders-list-page.tab.nav.current')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="flex-grow-1">
                <Nav.Link
                  eventKey="2"
                  className="flex-center-center mk-text-subtle-black mk-fz-16 mk-fw-500 mk-text-hover-brand-orange"
                >
                  {translate('orders-list-page.tab.nav.success')}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="flex-grow-1">
                <Nav.Link
                  eventKey="3"
                  className="flex-center-center mk-text-subtle-black mk-fz-16 mk-fw-500 mk-text-hover-brand-orange"
                >
                  {translate('orders-list-page.tab.nav.cancel')}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <div className="orders-list-page--tab-content">
            <Tab.Content
              className="tab-content-personal mt-0 pr0"
              style={{ height: '100%' }}
            >
              {String(eventKey) === '1' && (
                <Tab.Pane eventKey="1">
                  <OrdersListPending />
                </Tab.Pane>
              )}
              {String(eventKey) === '2' && (
                <Tab.Pane eventKey="2">
                  <OrdersListSuccess />
                </Tab.Pane>
              )}
              {String(eventKey) === '3' && (
                <Tab.Pane eventKey="3">
                  <OrdersListCancel />
                </Tab.Pane>
              )}
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    </HelmetCustom>
  );
};
