// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  isGetProductsListRequest: false,
  isGetProductsListSuccess: false,
  isGetProductsListFailure: false,
  productsListResponse: {},
  //
  isGetProductTypesListRequest: false,
  isGetProductTypesListSuccess: false,
  isGetProductTypesListFailure: false,
  productTypesListResponse: {},
  //
  isGetProductPricesRequest: false,
  isGetProductPricesSuccess: false,
  isGetProductPricesFailure: false,
  productPricesResponse: {},
  //
  isGetProductsListPricesRequest: false,
  isGetProductsListPricesSuccess: false,
  isGetProductsListPricesFailure: false,
  productsListPricesResponse: {},
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get Products
    [Actions.getProductsListRequest]: (state) => ({
      ...state,
      isGetProductsListRequest: true,
      isGetProductsListSuccess: false,
      isGetProductsListFailure: false,
    }),
    [Actions.getProductsListSuccess]: (state, { payload }) => ({
      ...state,
      isGetProductsListRequest: false,
      isGetProductsListSuccess: true,
      isGetProductsListFailure: false,
      productsListResponse: {
        ...payload,
        data: payload.data.filter((item) => !item.name.includes('Mẫu')),
      },
    }),
    [Actions.getProductsListFailure]: (state, { payload }) => ({
      ...state,
      isGetProductsListRequest: false,
      isGetProductsListSuccess: false,
      isGetProductsListFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get Product Types
    [Actions.getProductTypesListRequest]: (state) => ({
      ...state,
      isGetProductTypesListRequest: true,
      isGetProductTypesListSuccess: false,
      isGetProductTypesListFailure: false,
    }),
    [Actions.getProductTypesListSuccess]: (state, { payload }) => ({
      ...state,
      isGetProductTypesListRequest: false,
      isGetProductTypesListSuccess: true,
      isGetProductTypesListFailure: false,
      productTypesListResponse: {
        ...payload,
        data: payload.data.filter((item) => item.name !== 'Mẫu'),
      },
    }),
    [Actions.getProductTypesListFailure]: (state, { payload }) => ({
      ...state,
      isGetProductTypesListRequest: false,
      isGetProductTypesListSuccess: false,
      isGetProductTypesListFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get Prices
    [Actions.getProductPricesRequest]: (state) => ({
      ...state,
      isGetProductPricesRequest: true,
      isGetProductPricesSuccess: false,
      isGetProductPricesFailure: false,
    }),
    [Actions.getProductPricesSuccess]: (state, { payload }) => ({
      ...state,
      isGetProductPricesRequest: false,
      isGetProductPricesSuccess: true,
      isGetProductPricesFailure: false,
      productPricesResponse: payload,
    }),
    [Actions.getProductPricesFailure]: (state, { payload }) => ({
      ...state,
      isGetProductPricesRequest: false,
      isGetProductPricesSuccess: false,
      isGetProductPricesFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetProductPricesState]: (state) => ({
      ...state,
      isGetProductPricesRequest: false,
      isGetProductPricesSuccess: false,
      isGetProductPricesFailure: false,
      productPricesResponse: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Get Products List Prices
    [Actions.getProductsListPricesRequest]: (state) => ({
      ...state,
      isGetProductsListPricesRequest: true,
      isGetProductsListPricesSuccess: false,
      isGetProductsListPricesFailure: false,
    }),
    [Actions.getProductsListPricesSuccess]: (state, { payload }) => ({
      ...state,
      isGetProductsListPricesRequest: false,
      isGetProductsListPricesSuccess: true,
      isGetProductsListPricesFailure: false,
      productsListPricesResponse: payload,
    }),
    [Actions.getProductsListPricesFailure]: (state, { payload }) => ({
      ...state,
      isGetProductsListPricesRequest: false,
      isGetProductsListPricesSuccess: false,
      isGetProductsListPricesFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetProductsListPricesState]: (state) => ({
      ...state,
      isGetProductsListPricesRequest: false,
      isGetProductsListPricesSuccess: false,
      isGetProductsListPricesFailure: false,
      productsListPricesResponse: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetProductState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
