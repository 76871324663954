// import LogoWithTextUrl from '~/assets/images/logo/logo-with-text.png';
// import { ReactComponent as LogoMekoFood } from '~/assets/images/logo/LOGO_MEKO_FOOD.svg';
import { ReactComponent as LogoMekoTrading } from '~/assets/images/logo/LOGO_MEKO_TRADING.svg';
import { HelmetCustom } from '~/components/helmetCustom';
import { translate } from '~/helpers/utils';

export const LoadingPage = () => (
  <HelmetCustom title={translate('helmet.title.loading-page')}>
    <div id="loading">
      <div className="loading-inner">
        <LogoMekoTrading />
        {/* <div className="loading-progress" /> */}
      </div>
    </div>
    {/* <div className="loader">
        <div className="loader__bar" />
        <div className="loader__bar loader__bar--delay-1" />
        <div className="loader__bar loader__bar--delay-2" />
        <div className="loader__bar loader__bar--delay-3" />
        <div className="loader__bar loader__bar--delay-4" />
        <div className="loader__bar loader__bar--delay-5" />
        <img src={LogoWithTextUrl} alt="Meko Trading" />
      </div> */}
  </HelmetCustom>
);
