import BannerHomepageUrl2 from '~/assets/images/banner/banner-2.avif';
import BannerHomepageUrl1 from '~/assets/images/banner/banner-homepage.png';
import {
  Banner, ContactButton, FooterInfo, HeaderWelcome, ReferralCodeSection,
} from '~/components/common';
import { HelmetCustom } from '~/components/helmetCustom';
import { translate } from '~/helpers/utils';
import { useSelector } from 'react-redux';
import {
  CategoriesList,
  GiftsList,
  PostsList,
  RecentOrders,
  TrendingProducts,
  VouchersList,
} from './components';

export const UserHomePage = () => {
  const {
    configResponse, profileResponse,
  } = useSelector((store) => store.user);

  return (
    <HelmetCustom title={translate('helmet.title.homepage')}>
      <div id="user-home-page" className="user-home-page--wrapper pt-4">
        <HeaderWelcome />
        <Banner
          src={configResponse?.data?.banner?.banner_home_top || BannerHomepageUrl1}
          alt="Meko-Banner-Main"
        />
        <div style={{ marginTop: '-20px', marginBottom: '40px' }}>
          {
            profileResponse?.data && (
              <ReferralCodeSection />
            )
          }
        </div>
        {
          profileResponse?.data
              && (
              <RecentOrders />
              )
        }
        <TrendingProducts />
        <ContactButton />
        <CategoriesList />
        <Banner
          src={configResponse?.data?.banner?.banner_home_bottom || BannerHomepageUrl2}
          alt="Meko-Banner-Main"
          classNameInner="mb-5"
        />
        <VouchersList />
        <ContactButton>
          <p className="mk-fs-i mk-fw-500 mk-fz-14 mb-3">
            {translate('home-page.button-contact.title')}
          </p>
        </ContactButton>
        <GiftsList />
        <PostsList />
        <FooterInfo />
      </div>
    </HelmetCustom>
  );
};
