import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import EggCoinUrl from '~/assets/images/common/egg-coin.png';
import {
  ContactButton,
  FooterInfo,
  HeaderChildWithoutCoin,
  NoData,
} from '~/components/common';
import { ButtonCustom, InputTextArea } from '~/components/form';
import { HelmetCustom } from '~/components/helmetCustom';
import { CSChevronDownNavigational } from '~/components/iconography/Navigational';
import {
  CSPenOutline,
  CSTrash2Outline,
} from '~/components/iconography/Outline';
import { routesUser } from '~/configs';
import { MODAL_KEY, STORAGE_KEY } from '~/constants';
import { ToastError } from '~/helpers/funcs';
import { parseCurrency, translate } from '~/helpers/utils';
import {
  openModalAddProductRequest,
  resetAddProductToCartRequest,
} from '~/redux/modal/actions';
import {
  createOrderRequest,
  resetCreateOrderState,
} from '~/redux/order/actions';
import {
  getProductsListPricesRequest,
  resetGetProductsListPricesState,
} from '~/redux/product/actions';
import { getFeeRequest, resetFeeState } from '~/redux/shipping/actions';
import { resetExchangeMyGiftAndVoucherState, updateListMyGiftsAndVouchers } from '~/redux/giftVoucher/actions';
import { useOnClickOutside } from '~/helpers/hooks';
import {
  ModalExchangeGift,
  ModalExchangeVoucher,
  ModalSuccess,
} from './components';

export const UserCart = () => {
  const {
    isGetConfigRequest, isGetConfigSuccess, configResponse, profileResponse,
  } = useSelector(
    (store) => store.user,
  );
  const {
    isGetProductsListPricesSuccess,
    productsListPricesResponse,
  } = useSelector((store) => store.product);
  const { isGetFeeRequest, isGetFeeSuccess, feeResponse } = useSelector(
    (store) => store.shipping,
  );
  const {
    isGetAllMyGiftsAndVouchersRequest,
    isGetAllMyGiftsAndVouchersSuccess,
    getAllMyGiftsAndVouchersResponse,
    isExchangeMyGiftAndVoucherSuccess,
    isExchangeMyGiftAndVoucherFailure,
  } = useSelector((store) => store.giftVoucher);
  const {
    isCreateOrderRequest, isCreateOrderSuccess, isCreateOrderFailure,
  } = useSelector((store) => store.order);
  const { isAddProductToCart } = useSelector((store) => store.modal);
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const { pathname, hash } = location;

  const requireQuantityNumberProductRef = useRef(null);

  const [cartState, setCartState] = useState(
    localStorage.getItem(STORAGE_KEY.CART_STATE)
      ? JSON.parse(localStorage.getItem(STORAGE_KEY.CART_STATE))
      : [],
  );
  const [cartItemList, setCartItemList] = useState([]);
  const [cartItemPriceList, setCartItemPriceList] = useState([]);
  const [total, setTotal] = useState(0);
  const [fee, setFee] = useState(0);
  const [isLoadingPriceTotal, setIsLoadingPriceTotal] = useState(true);
  const [isLoadingPriceProducts, setIsLoadingPriceProducts] = useState(true);
  const [voucherList, setVoucherList] = useState([]);
  const [voucherSelected, setVoucherSelected] = useState();
  const [giftList, setGiftList] = useState([]);
  const [giftSelected, setGiftSelected] = useState();
  const [note, setNote] = useState('');
  const [addressesList, setAddressList] = useState([]);
  const [addressSelected, setAddressSelected] = useState();
  const [isShowFirstScreen, setIsShowFirstScreen] = useState(true);
  const [isShowModalExchangeGift, setIsShowModalExchangeGift] = useState(false);
  const [isShowModalExchangeVoucher, setIsShowModalExchangeVoucher] = useState(false);
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false);
  const [isShowRequireQuantityNumberProduct, setIsShowRequireQuantityNumberProduct] = useState(false);

  useOnClickOutside(requireQuantityNumberProductRef, () => {
    setIsShowRequireQuantityNumberProduct(false);
  });

  useEffect(() => {
    if (isCreateOrderFailure) {
      dispatch(resetCreateOrderState());
    }
    if (isExchangeMyGiftAndVoucherFailure) {
      dispatch(resetExchangeMyGiftAndVoucherState());
    }
    if (hash.includes(MODAL_KEY.MODAL_EXCHANGE_GIFT_IN_CART) || hash.includes(MODAL_KEY.MODAL_EXCHANGE_VOUCHER_IN_CART)) {
      history.replace(pathname);
      setIsShowModalExchangeGift(false);
      setIsShowModalExchangeVoucher(false);
    }
  }, []);

  // Handle open/close popup
  useEffect(() => {
    if (!isShowFirstScreen) {
      if (hash.includes(MODAL_KEY.MODAL_EXCHANGE_GIFT_IN_CART)) {
        setIsShowModalExchangeGift(true);
      } else if (hash.includes(MODAL_KEY.MODAL_EXCHANGE_VOUCHER_IN_CART)) {
        setIsShowModalExchangeVoucher(true);
      } else {
        setIsShowModalExchangeGift(false);
        setIsShowModalExchangeVoucher(false);
      }
    }
  }, [hash]);

  const handleCloseModalExchangeGift = () => {
    history.goBack();
    setIsShowModalExchangeGift(false);
  };

  const handleCloseModalExchangeVoucher = () => {
    history.goBack();
    setIsShowModalExchangeVoucher(false);
  };

  // Handle items from cart
  const handleDelete = (id) => {
    setIsLoadingPriceTotal(true);
    setIsLoadingPriceProducts(true);
    const cartStateClone = cartState.filter((item) => item.id !== id);
    setCartState(cartStateClone);
    localStorage.setItem(
      STORAGE_KEY.CART_STATE,
      JSON.stringify(cartStateClone),
    );
    localStorage.setItem(
      STORAGE_KEY.CART_STATE_TMP,
      JSON.stringify(cartStateClone),
    );
    dispatch(resetGetProductsListPricesState());
  };

  const handleClickAddItem = (data, type, from) => {
    dispatch(openModalAddProductRequest({ data, type, from }));
  };

  useEffect(() => {
    if (isAddProductToCart) {
      setIsLoadingPriceTotal(true);
      setIsLoadingPriceProducts(true);
      setCartState(
        localStorage.getItem(STORAGE_KEY.CART_STATE)
          ? JSON.parse(localStorage.getItem(STORAGE_KEY.CART_STATE))
          : [],
      );
      dispatch(resetAddProductToCartRequest());
    }
  }, [isAddProductToCart]);

  // Handle check product in cart have exist
  useEffect(() => {
    if (isGetConfigSuccess) {
      const cartItemListTmp = [];
      if (cartState.length > 0) {
        cartState.map((item1) => {
          const checked = configResponse.data.products.filter(
            (item2) => item2.id === item1.id,
          );
          if (checked.length > 0) {
            cartItemListTmp.push({
              ...checked[0],
              packNumber: item1.packNumber,
              packNumberType: item1.type,
            });
          }
        });
        setCartItemList(cartItemListTmp);
      } else {
        setTotal(0);
        setCartItemList(cartItemListTmp);
        setIsLoadingPriceTotal(false);
        setIsLoadingPriceProducts(false);
      }
    }
  }, [cartState, isGetConfigSuccess]);

  useEffect(() => {
    if (cartItemList.length > 0 && !isGetProductsListPricesSuccess && profileResponse?.data) {
      const payload = {
        ids: cartItemList.map((item) => parseInt(item.id)).join(),
        numbers: cartItemList
          .map((item) => item.packNumberType === 'pack' ? parseInt(item.packNumber) * parseInt(item.bag) : parseInt(item.packNumber))
          .join(),
      };
      dispatch(getProductsListPricesRequest(payload));
      dispatch(
        getFeeRequest({
          number: payload.numbers
            .split(',')
            .reduce((total, item) => total + parseInt(item), 0),
        }),
      );
    }
  }, [cartItemList, profileResponse]);

  useEffect(() => {
    if (isGetProductsListPricesSuccess) {
      setCartItemPriceList(productsListPricesResponse?.data?.products || []);
      setTotal(productsListPricesResponse?.data?.total || 0);
      setIsLoadingPriceTotal(false);
      setIsLoadingPriceProducts(false);
    }
  }, [isGetProductsListPricesSuccess]);

  // Handle shipping
  useEffect(() => {
    if (
      !isShowFirstScreen
      && isGetFeeSuccess
      && isGetProductsListPricesSuccess
    ) {
      setFee(feeResponse.data?.cost || 0);
    }
  }, [isGetFeeSuccess, isShowFirstScreen, isGetProductsListPricesSuccess]);

  // Handle gift + voucher
  useEffect(() => {
    if (!isShowFirstScreen && isGetAllMyGiftsAndVouchersSuccess) {
      if (getAllMyGiftsAndVouchersResponse.data?.length > 0) {
        const voucherListTmp = getAllMyGiftsAndVouchersResponse.data.filter(
          (item) => item.type === 'voucher' && item.apply === false,
        );
        const giftListTmp = getAllMyGiftsAndVouchersResponse.data.filter(
          (item) => item.type === 'gift' && item.apply === false,
        );
        setVoucherList(voucherListTmp);
        setGiftList(giftListTmp);
        setVoucherSelected(voucherListTmp[0]);
        setGiftSelected(giftListTmp[0]);
      } else {
        setVoucherList([]);
        setGiftList([]);
        setVoucherSelected();
        setGiftSelected();
      }
    }
  }, [
    isGetAllMyGiftsAndVouchersSuccess,
    isExchangeMyGiftAndVoucherSuccess,
    isShowFirstScreen,
  ]);

  // Handle address
  useEffect(() => {
    if (isGetConfigSuccess) {
      if (configResponse?.data?.profile?.addresses?.data?.length > 0) {
        const addressesListTmp = [...configResponse.data.profile.addresses.data];
        setAddressList(addressesListTmp);
        setAddressSelected(addressesListTmp.find((item) => item.apply));
      } else {
        setAddressList([]);
        setAddressSelected();
      }
    }
  }, [isGetConfigSuccess]);

  // Handle create order
  const handleCreateOrder = () => {
    const numberEgg = cartItemList?.filter((item) => item.type_id === 1).reduce((total, current) => {
      let n = +current.packNumber;
      if (current.packNumberType === 'pack') {
        n *= current.bag;
      }

      return total + n;
    }, 0);

    if (cartItemList?.filter((item) => item.type_id === 2)?.length === 0 && numberEgg < 100) {
      setIsShowRequireQuantityNumberProduct(true);
      return;
    }
    const payload = {
      address_id: parseInt(addressSelected.id),
    };
    if (giftSelected) {
      payload.gift_id = parseInt(giftSelected.id);
    }
    if (voucherSelected) {
      payload.voucher_id = parseInt(voucherSelected.id);
    }
    if (note.trim().length > 0) {
      payload.note = note.trim();
    }
    payload.products = cartItemList.map((item) => ({
      id: item.id,
      number: item.packNumberType === 'pack' ? parseInt(item.packNumber) * parseInt(item.bag) : parseInt(item.packNumber),
      bag: item.bag,
    }));
    dispatch(createOrderRequest(payload));
  };

  useEffect(() => {
    if (isCreateOrderSuccess && profileResponse?.data) {
      setIsShowModalSuccess(true);
      dispatch(resetFeeState());
      dispatch(resetGetProductsListPricesState());
      const idsMyGiftsAndVouchers = [];
      if (voucherSelected) {
        idsMyGiftsAndVouchers.push(voucherSelected.id);
      }
      if (giftSelected) {
        idsMyGiftsAndVouchers.push(giftSelected.id);
      }
      dispatch(updateListMyGiftsAndVouchers({ ids: idsMyGiftsAndVouchers }));
      dispatch(resetCreateOrderState());
      localStorage.setItem(STORAGE_KEY.CART_STATE, JSON.stringify([]));
      localStorage.setItem(STORAGE_KEY.CART_STATE_TMP, JSON.stringify([]));
    }
  }, [isCreateOrderSuccess, profileResponse]);

  return (
    <HelmetCustom title={translate('helmet.title.cart')}>
      <div
        id="cart-page"
        className="cart-page--wrapper h-screen pt-4 d-flex flex-column"
      >
        <HeaderChildWithoutCoin
          title={translate('cart-page.title')}
        />
        <div className="cart-page--inner flex-grow-1 d-flex flex-column scroll-y">
          <div className="cart--wrapper flex-grow-1">
            <div className="cart--header mb-3 pt-3">
              <p className="mk-text-center mk-fz-14 mk-text-secondary-black mk-fw-500 mb-0 mk-text-uppercase">
                {translate('cart-page.total')}
              </p>
              <div className="mk-text-center mk-fz-24 mk-text-brand-blue mk-fw-600 mb-0">
                {isLoadingPriceTotal || isGetFeeRequest ? (
                  <Skeleton
                    count={1}
                    width="100px"
                    height="20px"
                    style={{ lineHeight: 'unset' }}
                  />
                ) : (
                  parseCurrency(
                    isShowFirstScreen
                      ? parseInt(total)
                      : voucherSelected
                        ? (voucherSelected.voucher.data.type === 'fee' ? parseInt(total) : (parseInt(total) + parseInt(feeResponse.data?.cost || 0) - parseInt(voucherSelected.voucher.data.discount)))
                        : parseInt(total) + parseInt(feeResponse.data?.cost || 0),
                  )
                )}
              </div>
              <div className="flex-center-space mt-3">
                <p className="mk-fz-14 mb-0 mk-text-subtle-black mk-fw-400">
                  {translate('cart-page.header.created.title')}{' '}
                  {moment().format('DD/MM/YYYY')}
                </p>
                <p className="mk-fz-14 mb-0 mk-text-brand-blue mk-fw-600">
                  {translate('cart-page.header.order-status.delivering.title')}
                </p>
              </div>
            </div>
            <div className="cart--list">
              {isGetConfigRequest
                ? [...new Array(4)].map((_, index) => (
                  <div
                    key={index}
                    className={`product-item--wrapper${index !== 0 ? ' mt-3' : ''}`}
                  >
                    <Skeleton
                      count={1}
                      width="100%"
                      style={{ lineHeight: 'unset' }}
                    />
                  </div>
                ))
                : cartItemList.length > 0 ? cartItemList.map((item, index) => (
                  <div
                    key={index}
                    className={`product-item--wrapper${index !== 0 ? ' mt-3' : ''}`}
                  >
                    <p className="product-item--name mb-0 mk-fz-14 mk-fw-500 mk-text-subtle-blue">
                      {item.name}
                    </p>
                    <div className="flex-center-space">
                      <div className="mb-0 mk-fz-12 mk-text-subtle-black mk-fw-400 flex-center-start">
                        {
                          item.packNumberType === 'pack' ? (
                            <>
                              {parseInt(item.packNumber)}{' '}
                              {translate('modal-add-product.unit')} x {item.bag}{' '}
                            </>
                          ) : (
                            <>
                              {parseInt(item.packNumber)}{' '}
                            </>
                          )
                        }
                        {item.unit} x{' '}
                        {isLoadingPriceProducts ? (
                          <Skeleton
                            count={1}
                            width="50px"
                            style={{ lineHeight: 'unset', marginLeft: '5px' }}
                          />
                        ) : (
                          parseCurrency(
                            cartItemPriceList.find(
                              (item1) => item1.id === item.id,
                            ).price,
                          )
                        )}{' '}
                        <span
                          className="flex-center-center cursor-pointer"
                          style={{ marginLeft: '10px' }}
                          onClick={() => handleClickAddItem(item, 'edit', routesUser.cart)}
                        >
                          <CSPenOutline
                            className="fill-subtle-black hover-fill-secondary-brand-blue"
                            style={{ width: '15px', height: '15px' }}
                          />
                        </span>
                        <span
                          className="flex-center-center cursor-pointer"
                          style={{ marginLeft: '10px' }}
                          onClick={() => handleDelete(item.id)}
                        >
                          <CSTrash2Outline
                            className="fill-subtle-black hover-fill-red"
                            style={{ width: '15px', height: '15px' }}
                          />
                        </span>
                      </div>
                      <div className="mb-0 mk-fz-14 mk-text-black mk-fw-500">
                        {isLoadingPriceProducts ? (
                          <Skeleton
                            count={1}
                            width="60px"
                            style={{ lineHeight: 'unset' }}
                          />
                        ) : (
                          parseCurrency(item.packNumberType === 'pack'
                            ? parseInt(item.packNumber)
                            * parseInt(item.bag)
                            * cartItemPriceList.find(
                              (item1) => item1.id === item.id,
                            ).price : parseInt(item.packNumber)
                            * cartItemPriceList.find(
                              (item1) => item1.id === item.id,
                            ).price)
                        )}
                      </div>
                    </div>
                  </div>
                )) : <NoData title={translate('cart-page.modal.no-data.title')} />}
            </div>
            {cartItemList.length > 0
              && (isShowFirstScreen ? (
                <div className="flex-center-center gap-3 mt-5 mb-4">
                  <ButtonCustom
                    disabled={isLoadingPriceTotal || isLoadingPriceProducts}
                    className="w-100 p-0"
                    color="brand-orange"
                    variant="line"
                    onClick={() => history.push(routesUser.home)}
                  >
                    {translate('cart-page.first-screen.btn-1')}
                  </ButtonCustom>
                  <ButtonCustom
                    disabled={isLoadingPriceTotal || isLoadingPriceProducts}
                    isLoading={isLoadingPriceTotal || isLoadingPriceProducts}
                    className="w-100 p-0"
                    color="brand-orange"
                    onClick={() => setIsShowFirstScreen(false)}
                  >
                    {translate('cart-page.first-screen.btn-2')}
                  </ButtonCustom>
                </div>
              ) : (
                <>
                  <div className="order-detail--extra mt-4 mb-5">
                    <div
                      className="flex-center-space mk-fz-14 mk-fw-400"
                      style={{ height: '30px' }}
                    >
                      <span className="mk-fw-500">
                        {translate('order-detail-page.extra.voucher.title')}
                      </span>
                      {isGetAllMyGiftsAndVouchersRequest ? (
                        <span className="mk-text-subtle-black flex-center-center cursor-pointer mk-text-hover-secondary-brand-blue hover-fill-secondary-brand-blue">
                          <Skeleton
                            count={1}
                            width="100px"
                            height="20px"
                            style={{ lineHeight: 'unset' }}
                          />
                        </span>
                      ) : voucherList.length > 0 ? (
                        <Dropdown className="dropdown-select-voucher">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            <span className="mk-text-subtle-black flex-center-center cursor-pointer mk-text-hover-secondary-brand-blue hover-fill-secondary-brand-blue">
                              {voucherSelected
                                ? voucherSelected.voucher.data.title
                                : translate(
                                  'order-detail-page.extra.none.title',
                                )}{' '}
                              <CSChevronDownNavigational
                                className="fill-subtle-black stroke-white"
                                style={{ width: '25px', height: '25px' }}
                              />
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {voucherList.map((item, index) => (
                              <Dropdown.Item
                                className={
                                  voucherSelected.id === item.id ? 'active' : ''
                                }
                                key={index}
                                onClick={() => setVoucherSelected(item)}
                              >
                                {item.voucher.data.title}
                              </Dropdown.Item>
                            ))}
                            <Dropdown.Item className="pointer-none">
                              ---
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="btn-exchange-voucher"
                              onClick={() => history.push(
                                MODAL_KEY.MODAL_EXCHANGE_VOUCHER_IN_CART,
                              )}
                            >
                              {translate(
                                'cart-page.modal.exchange-voucher.title',
                              )}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        <span
                          className="mk-text-subtle-black flex-center-center cursor-pointer mk-text-hover-secondary-brand-blue hover-fill-secondary-brand-blue"
                          onClick={() => history.push(
                            MODAL_KEY.MODAL_EXCHANGE_VOUCHER_IN_CART,
                          )}
                        >
                          {translate('order-detail-page.extra.none.title')}{' '}
                          <CSChevronDownNavigational
                            className="fill-subtle-black stroke-white"
                            style={{ width: '25px', height: '25px' }}
                          />
                        </span>
                      )}
                    </div>
                    <div
                      className="flex-center-space mk-fz-14 mk-fw-400"
                      style={{ height: '30px' }}
                    >
                      <span className="mk-fw-500">
                        {translate('order-detail-page.extra.gift.title')}
                      </span>
                      {isGetAllMyGiftsAndVouchersRequest ? (
                        <span className="mk-text-subtle-black flex-center-center cursor-pointer mk-text-hover-secondary-brand-blue hover-fill-secondary-brand-blue">
                          <Skeleton
                            count={1}
                            width="100px"
                            height="20px"
                            style={{ lineHeight: 'unset' }}
                          />
                        </span>
                      ) : giftList.length > 0 ? (
                        <Dropdown className="dropdown-select-voucher">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            <span className="mk-text-subtle-black flex-center-center cursor-pointer mk-text-hover-secondary-brand-blue hover-fill-secondary-brand-blue">
                              {giftSelected
                                ? giftSelected.gift.data.title
                                : translate(
                                  'order-detail-page.extra.none.title',
                                )}{' '}
                              <CSChevronDownNavigational
                                className="fill-subtle-black stroke-white"
                                style={{ width: '25px', height: '25px' }}
                              />
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {giftList.map((item, index) => (
                              <Dropdown.Item
                                className={
                                  giftSelected.id === item.id ? 'active' : ''
                                }
                                key={index}
                                onClick={() => setGiftSelected(item)}
                              >
                                {item.gift.data.title}
                              </Dropdown.Item>
                            ))}
                            <Dropdown.Item className="pointer-none">
                              ---
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="btn-exchange-voucher"
                              onClick={() => history.push(
                                MODAL_KEY.MODAL_EXCHANGE_GIFT_IN_CART,
                              )}
                            >
                              {translate('cart-page.modal.exchange-gift.title')}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      ) : (
                        <span
                          className="mk-text-subtle-black flex-center-center cursor-pointer mk-text-hover-secondary-brand-blue hover-fill-secondary-brand-blue"
                          onClick={() => history.push(MODAL_KEY.MODAL_EXCHANGE_GIFT_IN_CART)}
                        >
                          {translate('order-detail-page.extra.none.title')}{' '}
                          <CSChevronDownNavigational
                            className="fill-subtle-black stroke-white"
                            style={{ width: '25px', height: '25px' }}
                          />
                        </span>
                      )}
                    </div>
                    <div
                      className="flex-center-space mk-fz-14 mk-fw-400"
                      style={{ height: '30px' }}
                    >
                      <span className="mk-fw-500">
                        {translate('order-detail-page.extra.coin.title')}
                      </span>
                      <span className="mk-text-brand-orange flex-center-center">
                        {isGetConfigRequest ? (
                          <Skeleton
                            count={1}
                            width="100px"
                            height="20px"
                            style={{ lineHeight: 'unset' }}
                          />
                        ) : (
                          <>
                            +
                            {parseInt(
                              parseInt(total)
                              / parseInt(
                                configResponse?.data?.exchange?.money
                                || 100000,
                              ),
                            )
                              <= parseInt(configResponse?.data?.exchange?.max || 30)
                              ? parseInt(
                                parseInt(total)
                                / parseInt(
                                  configResponse?.data?.exchange?.money
                                  || 100000,
                                ),
                              )
                              : parseInt(
                                configResponse?.data?.exchange?.max || 30,
                              )}
                            &nbsp;
                            <img
                              src={EggCoinUrl}
                              alt="egg-coin"
                              style={{ width: '15px', height: '15px' }}
                            />
                          </>
                        )}
                      </span>
                    </div>
                    <div
                      className="flex-center-space mk-fz-14 mk-fw-400"
                      style={{ height: '30px' }}
                    >
                      <span className="mk-fw-500">
                        {translate('order-detail-page.extra.ship.title')}
                      </span>
                      <span className="mk-text-subtle-black">
                        {isGetFeeRequest ? (
                          <Skeleton
                            count={1}
                            width="50px"
                            style={{ lineHeight: 'unset', marginLeft: '5px' }}
                          />
                        ) : (
                          parseCurrency(fee)
                        )}
                      </span>
                    </div>
                    <div
                      className="flex-center-space mk-fz-14 mk-fw-400"
                      style={{ height: '30px' }}
                    >
                      <span className="mk-fw-500">
                        {translate('order-detail-page.extra.sale.title')}
                      </span>
                      <span className="mk-text-subtle-black">
                        {parseCurrency(voucherSelected?.voucher?.data?.type === 'price' ? voucherSelected?.voucher?.data?.discount : 0)}
                      </span>
                    </div>
                    <div
                      className="flex-center-start"
                      style={{ height: '30px' }}
                    >
                      <span className="mk-fz-14 mk-fw-400 mk-fw-500">
                        {translate('order-detail-page.extra.address.title')}
                      </span>
                    </div>
                    <div className="mt-1 mb-1">
                      {isGetConfigRequest ? (
                        <span className="mk-text-subtle-black flex-center-center cursor-pointer mk-text-hover-secondary-brand-blue hover-fill-secondary-brand-blue">
                          <Skeleton
                            count={1}
                            width="100px"
                            height="20px"
                            style={{ lineHeight: 'unset' }}
                          />
                        </span>
                      ) : (
                        <Dropdown className="dropdown-select-voucher">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            <span className="mk-text-subtle-black flex-start-space cursor-pointer mk-text-hover-secondary-brand-blue hover-fill-secondary-brand-blue mk-text-left">
                              {addressSelected
                                ? addressSelected.address
                                : translate(
                                  'order-detail-page.extra.none.title',
                                )}{' '}
                              <CSChevronDownNavigational
                                className="fill-subtle-black stroke-white"
                                style={{
                                  minWidth: '25px',
                                  width: '25px',
                                  height: '25px',
                                }}
                              />
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {addressesList.map((item, index) => (
                              <Dropdown.Item
                                className={
                                  addressSelected.id === item.id ? 'active' : ''
                                }
                                key={index}
                                onClick={() => setAddressSelected(item)}
                              >
                                {item.address}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                    <div
                      style={{ height: '30px' }}
                      className="flex-center-start"
                    >
                      <span className="mk-fz-14 mk-fw-500">
                        {translate('order-detail-page.extra.note.title')}
                      </span>
                    </div>
                    <div className="mt-1">
                      <InputTextArea
                        id="note"
                        name="note"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="flex-center-center gap-3 mb-4">
                    <ButtonCustom
                      disabled={isCreateOrderRequest}
                      isLoading={isCreateOrderRequest}
                      className="w-100 p-0"
                      color="brand-orange"
                      onClick={handleCreateOrder}
                    >
                      {translate('cart-page.second-screen.btn-1')}
                    </ButtonCustom>
                    <ButtonCustom
                      disabled={isCreateOrderRequest}
                      className="w-100 p-0"
                      color="red"
                      variant="outline-secondary"
                      onClick={() => history.push(routesUser.home)}
                    >
                      {translate('cart-page.second-screen.btn-2')}
                    </ButtonCustom>
                  </div>
                </>
              ))}
          </div>
          <div className="mb-4" />
          <ContactButton />
          <div className="mb-4" />
          <FooterInfo />
        </div>
      </div>
      {isShowModalExchangeGift && (
        <ModalExchangeGift handleClose={handleCloseModalExchangeGift} />
      )}
      {isShowModalExchangeVoucher && (
        <ModalExchangeVoucher handleClose={handleCloseModalExchangeVoucher} />
      )}
      {isShowModalSuccess && <ModalSuccess />}
      {isCreateOrderFailure && (
        <ToastError
          show
          content={translate('cart-page.modal.create-order-failure.title')}
        />
      )}
      {isShowRequireQuantityNumberProduct && (
        <div ref={requireQuantityNumberProductRef}>
          <ToastError
            show
            content={translate('cart-page.require-number-product-title')}
          />
        </div>
      )}
    </HelmetCustom>
  );
};
