// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getOrdersRecentRequest = createAction('GET_ORDERS_RECENT_REQUEST');
export const getOrdersRecentSuccess = createAction('GET_ORDERS_RECENT_SUCCESS');
export const getOrdersRecentFailure = createAction('GET_ORDERS_RECENT_FAILURE');

export const getOrdersPendingRequest = createAction('GET_ORDERS_PENDING_REQUEST');
export const getOrdersPendingSuccess = createAction('GET_ORDERS_PENDING_SUCCESS');
export const getOrdersPendingFailure = createAction('GET_ORDERS_PENDING_FAILURE');

export const getOrdersSuccessRequest = createAction('GET_ORDERS_SUCCESS_REQUEST');
export const getOrdersSuccessSuccess = createAction('GET_ORDERS_SUCCESS_SUCCESS');
export const getOrdersSuccessFailure = createAction('GET_ORDERS_SUCCESS_FAILURE');

export const getOrdersCancelRequest = createAction('GET_ORDERS_CANCEL_REQUEST');
export const getOrdersCancelSuccess = createAction('GET_ORDERS_CANCEL_SUCCESS');
export const getOrdersCancelFailure = createAction('GET_ORDERS_CANCEL_FAILURE');

export const getOrderDetailRequest = createAction('GET_ORDER_DETAIL_REQUEST');
export const getOrderDetailSuccess = createAction('GET_ORDER_DETAIL_SUCCESS');
export const getOrderDetailFailure = createAction('GET_ORDER_DETAIL_FAILURE');
export const resetOrderDetailState = createAction('RESET_ORDER_DETAIL_STATE');

export const getAllOrdersRequest = createAction('GET_ALL_ORDERS_REQUEST');
export const getAllOrdersSuccess = createAction('GET_ALL_ORDERS_SUCCESS');
export const getAllOrdersFailure = createAction('GET_ALL_ORDERS_FAILURE');

export const createOrderRequest = createAction('CREATE_ORDER_REQUEST');
export const createOrderSuccess = createAction('CREATE_ORDER_SUCCESS');
export const createOrderFailure = createAction('CREATE_ORDER_FAILURE');
export const resetCreateOrderState = createAction('RESET_CREATE_ORDER_STATE');

export const cancelOrderRequest = createAction('CANCEL_ORDER_REQUEST');
export const cancelOrderSuccess = createAction('CANCEL_ORDER_SUCCESS');
export const cancelOrderFailure = createAction('CANCEL_ORDER_FAILURE');
export const resetCancelOrderState = createAction('RESET_CANCEL_ORDER_STATE');

export const resetOrderState = createAction('RESET_ORDER_STATE');
