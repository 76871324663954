import React from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { translate } from '~/helpers/utils';

export const ModalConfirmRemove = ({
  isOpen,
  handleClose,
  handleRemove,
}) => {
  const { isRemoveUserRequest } = useSelector((store) => store.user);
  return (
    <>
      <Modal
        show={isOpen}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="modal-add-product--wrapper"
      >
        <Modal.Header>
          <Modal.Title> {translate('profile-page.nav.link.remove-account')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {translate('profile-page.nav.link.remove-account.confirm')}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" className="btn-custom__rounded btn-custom__size--s" onClick={handleRemove}>
            {
              isRemoveUserRequest ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <p>{translate('button.remove-account')} </p>
              )
            }
          </Button>
          <Button variant="outline-secondary" className="btn-custom__rounded btn-custom__size--s" onClick={handleClose}>
            {translate('button.cancel.title')}
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
};
