const vnFooterLangLib = {
  'footer.title.company-name': 'Công ty TNHH TM Meko',
  'footer.title.hotline': 'Hotline:',
  'footer.title.address': 'Địa chỉ: 193 Phan Huy Chú Phường An Khánh Quận Ninh Kiều TP. Cần Thơ.',
  'footer.title.copyright': 'Design By Satek',
  'footer.title.email': 'Email: ',
  'footer.title.fanpages': 'Fanpage: ',
  'footer.title.concern': 'XÍ NGHIỆP CBTP MEKO',
  'footer.title.concern.address': 'Địa chỉ: Lô 25 KCN Trà Nóc 1 P. Trà NócQ. Bình Thủy TP. Cần Thơ',
  'footer-action.home.label': 'Trang chủ',
  'footer-action.order.label': 'Đơn hàng',
  'footer-action.cart.label': 'Giỏ hàng',
  'footer-action.notification.label': 'Thông báo',
  'footer-action.profile.label': 'Cá nhân',
};

export default vnFooterLangLib;
