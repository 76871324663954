import { useFormik } from 'formik';
import { memo, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { ButtonCustom, InputText } from '~/components/form';
import { convertToSlug, translate } from '~/helpers/utils';
import { registerRequest } from '~/redux/auth/actions';
import { AddressCustomSection } from './AddressCustomSection';

export const RegisterSecondScreen = memo(
  ({ dataFormRegister, setDataFormRegister }) => {
    const { isGetRegionsSuccess, regionsResponse } = useSelector((store) => store.region);
    const { isRegisterRequest } = useSelector((store) => store.auth);

    const dispatch = useDispatch();

    const [touched, setTouched] = useState({
      province: {
        touch: false,
        errorMsg: translate('input.province.error.required'),
      },
      district: {
        touch: false,
        errorMsg: translate('input.district.error.required'),
      },
      ward: {
        touch: false,
        errorMsg: translate('input.ward.error.required'),
      },
    });
    const [addressCustom, setAddressCustom] = useState({
      province: '',
      district: '',
      ward: '',
    });
    const [isAutoMapDistrict, setIsAutoMapDistrict] = useState(false);
    const [isAutoMapWard, setIsAutoMapWard] = useState(false);

    const formik = useFormik({
      initialValues: {
        name: '',
        companyName: '', // optional
        address: '',
        email: '', // optional
      },
      validationSchema: Yup.object({
        name: Yup.string()
          .trim()
          .required(translate('input.full-name.error.required')),
        address: Yup.string()
          .trim()
          .required(translate('input.address.error.required')),
        // province: Yup.string()
        //   .trim()
        //   .required(translate('input.province.error.required')),
        // district: Yup.string()
        //   .trim()
        //   .required(translate('input.district.error.required')),
        // ward: Yup.string()
        //   .trim()
        //   .required(translate('input.ward.error.required')),
        email: Yup.string()
          .trim()
          .email(translate('input.email.error.invalid')),
      }),
      onSubmit: (values) => {
        setTouched({
          ...touched,
          province: {
            ...touched.province,
            touch: true,
          },
          district: {
            ...touched.district,
            touch: true,
          },
          ward: {
            ...touched.ward,
            touch: true,
          },
        });
        if (addressCustom.province === '' || addressCustom.district === '' || addressCustom.ward === '') {
          return;
        }
        setDataFormRegister({
          ...dataFormRegister,
          ...values,
        });
        dispatch(
          registerRequest({
            ...dataFormRegister,
            ...values,
            ...addressCustom,
          }),
        );
      },
    });

    useEffect(() => {
      if (isGetRegionsSuccess && formik.values.address.trim().length > 0) {
        const provinceCheck = regionsResponse.data
          .filter((item) => item.parent_id === 0)
          .find((item) => convertToSlug(formik.values.address).includes(
            convertToSlug(item.name),
          ));
        if (provinceCheck) {
          setAddressCustom((currentAddressCustom) => ({
            ...currentAddressCustom,
            province: provinceCheck.id,
          }));
          const districtCheck = regionsResponse.data
            .filter((item) => item.parent_id === provinceCheck.id)
            .find((item) => convertToSlug(formik.values.address).includes(
              convertToSlug(item.name),
            ));
          if (districtCheck) {
            setAddressCustom((currentAddressCustom) => ({
              ...currentAddressCustom,
              district: districtCheck.id,
            }));
            const wardCheck = regionsResponse.data
              .filter((item) => item.parent_id === districtCheck.id).find((item) => convertToSlug(formik.values.address).includes(
                convertToSlug(item.name),
              ));
            setIsAutoMapDistrict(true);
            if (wardCheck) {
              setAddressCustom((currentAddressCustom) => ({
                ...currentAddressCustom,
                ward: wardCheck.id,
              }));
              setIsAutoMapWard(true);
            }
          }
        }
      }
    }, [formik.values.address, isGetRegionsSuccess]);

    return (
      <Form noValidate onSubmit={formik.handleSubmit}>
        <div className="register-page--second-screen">
          <h3 className="mb-3 mk-fz-16 mk-fw-500">
            {translate('register-page.second-screen.title')}
          </h3>
          <InputText
            id="name"
            name="name"
            placeholder={translate('input.full-name.placeholder')}
            isRequired
            {...formik.getFieldProps('name')}
            isValid={formik.touched.name && !formik.errors.name}
            isInvalid={formik.touched.name && formik.errors.name}
            isError={formik.touched.name && formik.errors.name}
            msgError={formik.errors.name}
            disabled={isRegisterRequest}
          />
          <div className="mb-3" />
          <InputText
            id="companyName"
            name="companyName"
            placeholder={translate('input.company-name-optional.placeholder')}
            {...formik.getFieldProps('companyName')}
            isValid={formik.touched.companyName && !formik.errors.companyName}
            isInvalid={formik.touched.companyName && formik.errors.companyName}
            isError={formik.touched.companyName && formik.errors.companyName}
            msgError={formik.errors.companyName}
            disabled={isRegisterRequest}
          />
          <div className="mb-3" />
          <InputText
            id="address"
            name="address"
            placeholder={translate('input.address-custom-1.placeholder')}
            isRequired
            {...formik.getFieldProps('address')}
            isValid={formik.touched.address && !formik.errors.address}
            isInvalid={formik.touched.address && formik.errors.address}
            isError={formik.touched.address && formik.errors.address}
            msgError={formik.errors.address}
            disabled={isRegisterRequest}
          />
          <div className="mb-3" />
          <AddressCustomSection
            touched={touched}
            setTouched={setTouched}
            addressCustom={addressCustom}
            setAddressCustom={setAddressCustom}
            isAutoMapDistrict={isAutoMapDistrict}
            setIsAutoMapDistrict={setIsAutoMapDistrict}
            isAutoMapWard={isAutoMapWard}
            setIsAutoMapWard={setIsAutoMapWard}
          />
          <div className="mb-3" />
          <InputText
            id="email"
            name="email"
            placeholder={translate('input.email-optional.placeholder')}
            isRequired
            {...formik.getFieldProps('email')}
            isValid={formik.touched.email && !formik.errors.email}
            isInvalid={formik.touched.email && formik.errors.email}
            isError={formik.touched.email && formik.errors.email}
            msgError={formik.errors.email}
            disabled={isRegisterRequest}
          />
          <div className="mb-5" />
          <ButtonCustom
            disabled={isRegisterRequest}
            isLoading={isRegisterRequest}
            type="submit"
            className="w-100"
          >
            {translate('button.sign-up.title')}
          </ButtonCustom>
        </div>
      </Form>
    );
  },
);
