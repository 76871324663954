import { memo } from 'react';
import { Link } from 'react-router-dom';

import { routesUser } from '~/configs';
import { translate } from '~/helpers/utils';

export const BlogItem = memo(({ data }) => (
  <div className="blog-item--wrapper w-50">
    <div className="blog-item--inner">
      <div className="blog-item--img mb-2">
        <img src={data.image} alt={data.title} className="img-square" />
      </div>
      <p className="blog-item--name mk-fz-14 mk-fw-500 mb-0 mk-truncate-2">
        {data.title}
      </p>
      <p className="btn-view-detail mk-fz-12 mk-fw-500 mk-text-secondary-brand-blue mk-fs-i mt-1 mb-4">
        <Link
          to={routesUser.blogDetail.replace(':blogId', data.id)}
          className="mk-text-hover-brand-blue"
        >
          {translate('button.view-detail.title')}
        </Link>
      </p>
    </div>
  </div>
));
