import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import OrderIconUrl from '~/assets/images/icon/clipboard.png';
import { routesUser } from '~/configs';
import { translate } from '~/helpers/utils';

export const RecentOrders = memo(() => {
  const {
    isGetOrdersRecentRequest,
    isGetOrdersRecentSuccess,
    getOrdersRecentResponse,
  } = useSelector((store) => store.order);
  const {
    profileResponse,
  } = useSelector((store) => store.user);

  const [isShow, setIsShow] = useState(true);
  const [recentOrdersList, setRecentOrdersList] = useState([]);

  useEffect(() => {
    if (isGetOrdersRecentSuccess) {
      let recentOrdersListTmp = [];
      if (getOrdersRecentResponse?.data.length > 0) {
        recentOrdersListTmp = [...getOrdersRecentResponse.data];
      } else {
        setIsShow(false);
      }
      setRecentOrdersList(recentOrdersListTmp);
    }
  }, [isGetOrdersRecentSuccess, getOrdersRecentResponse]);

  return (
    isShow && (
      <section className="home-page--recent-orders">
        <div className="recent-orders--inner mb-4">
          <div className="flex-center-space mb-3">
            <h3 className="section-title">
              {translate('home-page.recent-orders.title')}
            </h3>
            {
              profileResponse?.data && (
                <p className="section-sub-title">
                  <Link to={routesUser.ordersList}>
                    {translate('button.see-all.title')}
                  </Link>
                </p>
              )
            }
          </div>
          {
            profileResponse?.data
              ? (
                <div className="orders-list">
                  {isGetOrdersRecentRequest
                    ? [...new Array(6)].map((_, index) => (
                      <div
                        key={index}
                        className="order-item px-2"
                        style={{ minWidth: '72px', width: '72px' }}
                      >
                        <Skeleton
                          count={1}
                          width="100%"
                          height="56px"
                          style={{ lineHeight: 'unset' }}
                        />
                        <Skeleton
                          count={1}
                          width="100%"
                          height="15px"
                          style={{ lineHeight: 'unset', marginTop: '5px' }}
                        />
                      </div>
                    ))
                    : recentOrdersList.map((item, index) => (
                      <div
                        key={index}
                        className="order-item px-2"
                        style={{ minWidth: '72px', width: '72px' }}
                      >
                        <Link
                          to={routesUser.orderDetail.replace(':orderId', item.id)}
                          className="cursor-pointer"
                        >
                          <img src={OrderIconUrl} alt="Order-icon" />
                          <p className="mt-1 mb-0 mk-fz-14 mk-fw-400 mk-text-subtle-black">
                            {moment(item.created_at).format('DD/MM/YY')}
                          </p>
                        </Link>
                      </div>
                    ))}
                </div>
              )
              : <div className="">Vui lòng đăng nhập để hiển thị</div>
            }
        </div>
      </section>
    )
  );
});
