const vnForgotPasswordPageLangLib = {
  'forgot-password-page.footer.name': 'Công ty TNHH TM Meko',
  'forgot-password-page.footer.hotline': 'Hotline:',
  'forgot-password-page.footer.address': 'Địa chỉ: 193, Phan Huy Chú, Phường An Khánh, Quận Ninh Kiểu, TP. Cần Thơ.',
  'forgot-password-page.first-screen.title': 'Gửi mã OTP về số điện thoại',
  'forgot-password-page.first-screen.sub-title-1': 'Hoặc',
  'forgot-password-page.first-screen.sub-title-2': 'Liên hệ: $x để được hỗ trợ cấp mật khẩu mới.',
  'forgot-password-page.second-screen.title': 'Thay đổi mật khẩu',
  'forgot-password-page.change-password-success.title': 'Thay đổi thành công',
  'forgot-password-page.change-password-success.description': 'Chúc mừng bạn $x đã cập nhật mật khẩu thành công.',
  'forgot-password-page.auto-return-login.title': 'Đi đến trang đăng nhập ($xs)',
  'forgot-password-page.send-otp-failure.title': 'Gửi mã OTP thất bại',
  'forgot-password-page.send-otp-failure.description': 'Số điện thoại không tồn tại trên hệ thống. Liên hệ $x để được hỗ trợ.',
  'forgot-password-page.change-password-failure.title': 'Đổi mật khẩu mới không thành công! Vui lòng thử lại.',
  'forgot-password-page.send-otp-failure.sub-title': 'Hoặc',
  'forgot-password-page.dont-have-account.title': 'Số điện thoại của bạn chưa được đăng ký!',
};

export default vnForgotPasswordPageLangLib;
