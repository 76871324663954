import { memo, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import EggCoinUrl from '~/assets/images/common/egg-coin.png';
import { routesUser } from '~/configs';
import { translate } from '~/helpers/utils';

export const GiftsList = memo(() => {
  const { isGetAllGiftsRequest, isGetAllGiftsSuccess, getAllGiftsResponse } = useSelector((store) => store.giftVoucher);

  const [giftsList, setGiftsList] = useState([]);

  useEffect(() => {
    if (isGetAllGiftsSuccess) {
      let giftsListTmp = [];
      if (getAllGiftsResponse?.data.length > 0) {
        giftsListTmp = [...getAllGiftsResponse.data];
      }
      setGiftsList(giftsListTmp);
    }
  }, [isGetAllGiftsSuccess, getAllGiftsResponse]);

  return (
    <section className="home-page--gifts-list">
      <div className="gifts-list--inner mt-5 mb-4">
        <div className="flex-center-space mb-3">
          <h3 className="section-title">{translate('home-page.gift.title')}</h3>
          <p className="section-sub-title">
            <Link
              to={routesUser.giftsList}
              className="mk-text-hover-secondary-brand-blue"
            >
              {translate('button.see-all.title')}
            </Link>
          </p>
        </div>
        <div className="gifts-list">
          {isGetAllGiftsRequest
            ? [...new Array(5)].map((_, index) => (
              <div key={index} className="gift-item">
                <Skeleton
                  count={1}
                  width="100%"
                  style={{ lineHeight: 'unset', aspectRatio: '1/1' }}
                />
                <Skeleton
                  count={1}
                  width="100%"
                  height="20px"
                  style={{ lineHeight: 'unset', marginTop: '8px' }}
                />
              </div>
            ))
            : giftsList.map((item, index) => (
              <div key={index} className="gift-item">
                <Link to={routesUser.giftDetail.replace(':giftId', item.id)} className="mk-text-hover-secondary-brand-blue">
                  <img src={item.image} alt={item.title} draggable={false} />
                  <p className="mt-2 mb-0 mk-fz-14 mk-fw-500 mk-truncate-2">
                    {item.title}
                  </p>
                  <p className="flex-center-start mk-fz-16 mk-fw-500 mk-text-brand-orange mt-2">
                    {item.score}{' '}
                    <img
                      src={EggCoinUrl}
                      alt="egg-coin"
                      style={{
                        width: '19px',
                        height: '19px',
                        marginLeft: '3px',
                      }}
                    />
                  </p>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
});
