import { memo } from 'react';

export const Banner = memo(({
  src = '',
  alt = '',
  classNameWrapper = '',
  classNameInner = '',
}) => (
  <section className={`banner--wrapper ${classNameWrapper}`}>
    <div className={`banner--inner ${classNameInner}`}>
      <img src={src} alt={alt} />
    </div>
  </section>
));
