// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  isGetAllAddressesRequest: false,
  isGetAllAddressesSuccess: false,
  isGetAllAddressesFailure: false,
  getAllAddressesResponse: {},
  //
  isCreateAddressRequest: false,
  isCreateAddressSuccess: false,
  isCreateAddressFailure: false,
  createAddressResponse: {},
  //
  isUpdateAddressRequest: false,
  isUpdateAddressSuccess: false,
  isUpdateAddressFailure: false,
  updateAddressResponse: {},
  //
  isDeleteAddressRequest: false,
  isDeleteAddressSuccess: false,
  isDeleteAddressFailure: false,
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Addresses
    [Actions.getAllAddressesRequest]: (state) => ({
      ...state,
      isGetAllAddressesRequest: true,
      isGetAllAddressesSuccess: false,
      isGetAllAddressesFailure: false,
    }),
    [Actions.getAllAddressesSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllAddressesRequest: false,
      isGetAllAddressesSuccess: true,
      isGetAllAddressesFailure: false,
      getAllAddressesResponse: payload,
    }),
    [Actions.getAllAddressesFailure]: (state, { payload }) => ({
      ...state,
      isGetAllAddressesRequest: false,
      isGetAllAddressesSuccess: false,
      isGetAllAddressesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create Address
    [Actions.createAddressRequest]: (state) => ({
      ...state,
      isCreateAddressRequest: true,
      isCreateAddressSuccess: false,
      isCreateAddressFailure: false,
    }),
    [Actions.createAddressSuccess]: (state, { payload }) => ({
      ...state,
      isCreateAddressRequest: false,
      isCreateAddressSuccess: true,
      isCreateAddressFailure: false,
      createAddressResponse: payload,
    }),
    [Actions.createAddressFailure]: (state, { payload }) => ({
      ...state,
      isCreateAddressRequest: false,
      isCreateAddressSuccess: false,
      isCreateAddressFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateAddressState]: (state) => ({
      ...state,
      isCreateAddressRequest: false,
      isCreateAddressSuccess: false,
      isCreateAddressFailure: false,
      createAddressResponse: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Update Address
    [Actions.updateAddressRequest]: (state) => ({
      ...state,
      isUpdateAddressRequest: true,
      isUpdateAddressSuccess: false,
      isUpdateAddressFailure: false,
    }),
    [Actions.updateAddressSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateAddressRequest: false,
      isUpdateAddressSuccess: true,
      isUpdateAddressFailure: false,
      updateAddressResponse: payload,
    }),
    [Actions.updateAddressFailure]: (state, { payload }) => ({
      ...state,
      isUpdateAddressRequest: false,
      isUpdateAddressSuccess: false,
      isUpdateAddressFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateAddressState]: (state) => ({
      ...state,
      isUpdateAddressRequest: false,
      isUpdateAddressSuccess: false,
      isUpdateAddressFailure: false,
      updateAddressResponse: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete Address
    [Actions.deleteAddressRequest]: (state) => ({
      ...state,
      isDeleteAddressRequest: true,
      isDeleteAddressSuccess: false,
      isDeleteAddressFailure: false,
    }),
    [Actions.deleteAddressSuccess]: (state) => ({
      ...state,
      isDeleteAddressRequest: false,
      isDeleteAddressSuccess: true,
      isDeleteAddressFailure: false,
    }),
    [Actions.deleteAddressFailure]: (state, { payload }) => ({
      ...state,
      isDeleteAddressRequest: false,
      isDeleteAddressSuccess: false,
      isDeleteAddressFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteAddressState]: (state) => ({
      ...state,
      isDeleteAddressRequest: false,
      isDeleteAddressSuccess: false,
      isDeleteAddressFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetAddressState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
