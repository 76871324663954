/* eslint-disable jsx-a11y/media-has-caption */
import {
  memo, useEffect, useRef, useState,
} from 'react';

import successSound from '~/assets/sounds/success-noti.mp3';

export const Pyro = memo(({ isShow = false, isHide = false }) => {
  const audioRef = useRef(null);
  const [isPlayAudio, setIsPlayAudio] = useState(false);

  useEffect(() => {
    if (isShow) {
      if (!isPlayAudio) {
        audioRef.current.play();
        setIsPlayAudio(true);
      }
    }
  }, [isShow]);

  return (
    <>
      <div className={`pyro${isHide ? ' hidden' : ''}`}>
        <div className="before" />
        <div className="after" />
      </div>
      <audio ref={audioRef} controls id="success-sound-noti" className="d-none">
        <source src={successSound} type="audio/mpeg" />
      </audio>
    </>
  );
});
