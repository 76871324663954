import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Item } from './Item';

export const List = memo(({ type, data, isLoading }) => (
  <section className="list-wrapper">
    {isLoading
      ? [...new Array(5)].map((_, index) => (
        <div className="mb-3" key={index}>
          <Skeleton count={1} width="100%" height="110px" style={{ lineHeight: 'unset' }} />
        </div>
      ))
      : data.map((item, index) => (
        <Item key={index} type={type} data={item} />
      ))}
  </section>
));
