import { all } from 'redux-saga/effects';

import address from './address/saga';
import auth from './auth/saga';
import blog from './blog/saga';
import giftVoucher from './giftVoucher/saga';
import notification from './notification/saga';
import order from './order/saga';
import point from './point/saga';
import product from './product/saga';
import region from './region/saga';
import shipping from './shipping/saga';
import user from './user/saga';

export default function* rootSaga() {
  yield all([
    address(),
    auth(),
    blog(),
    giftVoucher(),
    notification(),
    order(),
    point(),
    product(),
    region(),
    shipping(),
    user(),
  ]);
}
