import clipboard from 'clipboard-js';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ToastSuccess } from '~/helpers/funcs';
import { translate } from '~/helpers/utils';
import { CSCopyOutline } from '../iconography/Outline';

export const ReferralCodeSection = memo(() => {
  const { profileResponse } = useSelector((store) => store.user);

  const [isClickCopy, setIsClickCopy] = useState(false);
  const [isShowToastCopySuccess, setIsShowToastCopySuccess] = useState(false);

  const handleClickCopy = () => {
    if (profileResponse?.data) {
      clipboard.copy(profileResponse?.data.referral);
      setIsShowToastCopySuccess(false);
      setIsClickCopy(true);
    }
  };

  useEffect(() => {
    if (isClickCopy) {
      setIsShowToastCopySuccess(true);
      setIsClickCopy(false);
    }
  }, [isClickCopy]);

  useEffect(() => {
    let timeout;
    if (isShowToastCopySuccess) {
      timeout = setTimeout(() => {
        setIsShowToastCopySuccess(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isShowToastCopySuccess]);

  return (
    <>
      <section
        className="referral-code-section--wrapper"
        onClick={handleClickCopy}
      >
        <p className="mb-0 mk-fz-16 mk-fw-400 flex-center-center gap-1">
          {translate('home-page.referral.title')} <CSCopyOutline className="fill-secondary-black" style={{ width: '15px' }} />
        </p>
        <h3 className="mb-0 mk-fz-28 mk-fw-600 mk-text-brand-orange">
          {profileResponse?.data?.referral}
        </h3>
      </section>

      {isShowToastCopySuccess && (
        <ToastSuccess
          show
          content={translate('home-page.copy-referral-success.title')}
          timeClose={3000}
        />
      )}
    </>
  );
});
