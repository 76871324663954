import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrdersSuccessRequest } from '~/redux/order/actions';
import { OrdersList } from './OrdersList';

export const OrdersListSuccess = () => {
  const {
    isGetOrdersSuccessRequest,
    isGetOrdersSuccessSuccess,
    getOrdersSuccessResponse,
  } = useSelector((store) => store.order);
  const dispatch = useDispatch();

  const [ordersList, setOrdersList] = useState([]);

  useEffect(() => {
    if (!isGetOrdersSuccessSuccess) {
      dispatch(getOrdersSuccessRequest());
    }
  }, []);

  useEffect(() => {
    if (isGetOrdersSuccessSuccess) {
      let ordersListTmp = [];
      if (getOrdersSuccessResponse?.data.length > 0) {
        ordersListTmp = [...getOrdersSuccessResponse.data];
      }
      setOrdersList(ordersListTmp);
    }
  }, [isGetOrdersSuccessSuccess, getOrdersSuccessResponse]);

  return (
    <div className="orders-list-success--tab">
      <OrdersList
        type="success"
        data={ordersList}
        isLoading={isGetOrdersSuccessRequest}
      />
    </div>
  );
};
