/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllAddresses({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/addresses', {
      params: payload,
    }));
    yield put(Actions.getAllAddressesSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllAddressesFailure(messages));
    }
  }
}

function* createAddress({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/addresses', payload));
    yield put(Actions.createAddressSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createAddressFailure(messages));
    }
  }
}

function* updateAddress({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/addresses/${payload.id}`, payload.data));
    yield put(Actions.updateAddressSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateAddressFailure(messages));
    }
  }
}

function* deleteAddress({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/addresses/${payload.id}`));
    yield put(Actions.deleteAddressSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteAddressFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllAddressesRequest, getAllAddresses);
  yield takeLatest(Actions.createAddressRequest, createAddress);
  yield takeLatest(Actions.updateAddressRequest, updateAddress);
  yield takeLatest(Actions.deleteAddressRequest, deleteAddress);
}
