import { memo, useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import ReactInputDateMask from 'react-input-date-mask';

export const InputDate = memo(
  ({
    labelFor,
    labelContent,
    isRequired,
    isError,
    msgError,
    fieldValue,
    ...rest
  }) => {
    const { onChange: handleChange, value, isValid } = rest;
    const [inputDate, setInputDate] = useState(value || '');

    useEffect(() => {
      if (inputDate) {
        handleChange(inputDate);
      }
    }, [inputDate]);

    return (
      <div className="input-custom input-custom-date">
        {labelContent && (
          <Form.Label htmlFor={labelFor}>
            {labelContent}{' '}
            {isRequired && <span className="is-required">*</span>}
          </Form.Label>
        )}
        <InputGroup>
          <ReactInputDateMask
            className={
              isError
                ? 'form-control is-invalid'
                : isValid
                  ? 'form-control is-valid'
                  : 'form-control'
            }
            value={inputDate}
            mask="dd/mm/yyyy"
            showMaskOnFocus={false}
            showMaskOnHover={false}
            onChange={(e) => setInputDate(e)}
          />
        </InputGroup>
        {isError && <div className="form-error-msg">{msgError}</div>}
      </div>
    );
  },
);
