import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { NoData } from '~/components/common';
import { translate } from '~/helpers/utils';
import { OrderItem } from './OrderItem';

export const OrdersList = memo(({ type, data, isLoading }) => (
  <section className="orders-list-wrapper">
    {isLoading ? (
      [...new Array(5)].map((_, index) => (
        <div className="mb-3" key={index}>
          <Skeleton
            count={1}
            width="100%"
            height="110px"
            style={{ lineHeight: 'unset' }}
          />
        </div>
      ))
    ) : data.length > 0 ? (
      data.map((item, index) => (
        <OrderItem key={index} type={type} data={item} />
      ))
    ) : (
      <NoData title={translate('orders-list-page.no-data.title')} />
    )}
  </section>
));
