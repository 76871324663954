import { memo } from 'react';

import { translate } from '~/helpers/utils';
import { ReactComponent as LogoMekoFood } from '~/assets/images/logo/LOGO_MEKO_FOOD.svg';
import { ReactComponent as LogoMekoTrading } from '~/assets/images/logo/LOGO_MEKO_TRADING.svg';

export const FooterInfo = memo(() => (
  <footer id="footer-info" className="footer-info--wrapper">
    <div className="footer-info--inner">
      <div className="flex-center-space gap-2 mb-4" style={{ height: '30px' }}>
        <LogoMekoFood />
        <LogoMekoTrading />
      </div>
      <h4 className="mb-0 mk-fz-16 mk-fw-600 mk-text-uppercase">
        {translate('footer.title.company-name')}
      </h4>
      <p className="mb-0 mk-fz-14 mk-fw-400">
        {translate('footer.title.hotline')}{' '}
        <a href="tel:0939010193" className="mk-text-link mk-text-hover-secondary-brand-blue">
          093 901 01 93
        </a>
      </p>
      <p className="mb-0 mk-fz-14 mk-fw-400">
        {translate('footer.title.address')}
      </p>
      <p className="mb-0 mk-fz-14 mk-fw-400">
        {translate('footer.title.email')}{' '}
        <a href="mailto:info@mekotrading.vn" className="mk-text-link mk-text-hover-secondary-brand-blue">
          info@mekotrading.vn
        </a>
      </p>
      <p className="mb-0 mk-fz-14 mk-fw-400">
        {translate('footer.title.fanpages')}{' '}
        <a href="https://www.facebook.com/mekotradingcantho" className="mk-text-link mk-text-hover-secondary-brand-blue">
          fb.com/mekotradingcantho
        </a>
      </p>
      <h4 className="mb-0 mk-fz-16 mk-fw-600 mk-text-uppercase">
        {translate('footer.title.concern')}
      </h4>
      <p className="mb-0 mk-fz-14 mk-fw-400">
        {translate('footer.title.concern.address')}
      </p>
    </div>
    <div className="footer-info--copyright py-2">
      <h3 className="mb-0 mk-text-center mk-fs-i mk-fz-12 mk-text-secondary-black mk-fw-400">
        {translate('footer.title.copyright')}
      </h3>
    </div>
  </footer>
));
