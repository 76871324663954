import { memo } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ButtonCustom } from '~/components/form';
import { routesUser } from '~/configs';
import { STORAGE_KEY } from '~/constants';
import { parseCurrency, translate } from '~/helpers/utils';

export const Footer = memo(({ isLoading, total }) => {
  const { profileResponse } = useSelector((store) => store.user);
  const history = useHistory();

  const handleSaveProductToOrder = () => {
    const cartStateTmp = localStorage.getItem(STORAGE_KEY.CART_STATE_TMP)
      ? JSON.parse(localStorage.getItem(STORAGE_KEY.CART_STATE_TMP))
      : [];
    localStorage.setItem(STORAGE_KEY.CART_STATE, JSON.stringify(cartStateTmp));
  };

  const handlePurchase = () => {
    handleSaveProductToOrder();
    history.push(routesUser.home);
  };

  const handleOrder = () => {
    handleSaveProductToOrder();
    history.push(routesUser.cart);
  };

  return (
    <section className="product-type-page--footer">
      <div className="footer--inner flex-center-space">
        <div className="flex-grow-1">
          <p className="mb-0 mk-fz-12 mk-fw-400 mk-text-subtle-black">
            {translate('product-type-page.footer.title')}
          </p>
          <div className="mb-0 mk-fz-16 mk-fw-500 mk-text-secondary-brand-blue">
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              parseCurrency(total)
            )}
          </div>
        </div>
        <ButtonCustom
          disabled={isLoading}
          size="s"
          color="secondary-brand-blue"
          style={{ marginRight: '3px' }}
          onClick={handlePurchase}
        >
          {translate('button.purchase.title')}
        </ButtonCustom>
        {
          profileResponse?.data && (
            <ButtonCustom
              disabled={isLoading}
              size="s"
              color="red"
              onClick={handleOrder}
            >
              {translate('button.order.title')}
            </ButtonCustom>
          )
        }
      </div>
    </section>
  );
});
